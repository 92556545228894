import React, { useState, useEffect } from "react";
import {  useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getSingleCompanyData } from "../../ApiCalls/Company";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import background_image from "../../img/background_image.jpg";
import { JdBasicInfoItem, JdBasicInfoItemDetails, JdBasicInfoItemLink } from "../../Components/JobPosts/JobDescription";
import { etob } from "../../NumberConvert";
import SingleJobCard from "../../Components/JobPosts/JobCards";

function SingleCompanyProfile() {
  const [companyData, setCompanyData] = useState({});
  const [companyJobData, setCompanyJobData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const companyId = searchParams.get("id");
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const getJobData = async () => {
      try {
        const requestData = {
          user_id_data: userId,
          company_id_data: companyId,
        };
        const response = await getSingleCompanyData(requestData);
        setCompanyData(response.data[0]);
        setCompanyJobData(response.data[0]?.company_jobs?response.data[0]?.company_jobs:[]);
      } catch (error) {
        console.error(error);
      }
    };

    getJobData();
  }, []);

  const tj = (jobPost) => {
    const tempJobPost = {
      id: jobPost.job_id,
      title: jobPost.job_title,
      companyName: companyData.name,
      companyLogo: companyData.logo,
      companyProfileId: companyId.id,
      jobLocation: jobPost.job_locations,
      deadline: jobPost.application_deadline,
      salaryRange: jobPost.salary_range,
      isSave: localStorage.getItem("userId") ? jobPost.is_saved : null,
    };
    return tempJobPost;
  };
  const ds =
    "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";
  let tl = companyData?.name
    ? companyData?.name + " | Shomvob: Jobs & Trainings"
    : "Shomvob: Jobs & Trainings";
  let ds2 = companyData?.name
    ? "Find the job opportunities for " +
      companyData?.name +
      " with Shomvob: Jobs & Trainings"
    : ds;

  return (
    <React.Fragment>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/single_company_description"></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
      <ToastContainer />
      <div
        className=" scroll-container container-fluid"
        style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: "100% auto",
          backgroundRepeat: "repeat-y",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <div className="rounded" name="Job Details">
          <div className="jd_section">
            <div className="top">
              <img
                src={companyData.logo}
                alt="avatar"
                style={{
                  maxHeight: "97px",
                  objectFit: "cover",
                }}
              />
            </div>

            <div className="top">
              <h1 name="company_name">{companyData.name}</h1>
            </div>
            <div className="top">
              <h2>{companyData.address}</h2>
            </div>
          </div>

          <div className="jd_section mb-4">
            <div className="company_details">
              <div className="row">
                <JdBasicInfoItem
                  title={"লাইভ চাকরি"}
                  value={etob(companyData.job_count)}
                />
                <JdBasicInfoItem
                  title={"ইন্ডাস্ট্রি"}
                  value={companyData.industry}
                />
                <JdBasicInfoItem title={"লোকেশন"} value={companyData.address} />
                <JdBasicInfoItemLink
                  title={"ওয়েবসাইট"}
                  value={companyData.website_link}
                />
                <JdBasicInfoItemDetails
                  title={"কোম্পানির বিবরণ"}
                  value={companyData.company_profile}
                />
              </div>
            </div>
          </div>

          {companyJobData.length > 0  && (
            <div className="company_job_opening mt-5 mb-5">
              <div className="section_top">
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="section_title">কোম্পানির লাইভ চাকরি সমূহ</h1>
                  </div>
                </div>
              </div>

              <div className="row g-4">
                {companyJobData.map((jobPost, index) => (
                  <>
                    <SingleJobCard jobPost={tj(jobPost)} mark={1} />
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SingleCompanyProfile;
