import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import not_found from "./img/404_img.png";

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<main className="container p-5" >
			
					<div className="row d-flex justify-content-center align-items-center">
						<div className="col-12 d-flex justify-content-center align-items-center">
							<img src={not_found} alt="not_found_image" style={{
								
								maxHeight: "350px",
								
								
							}} />

						</div>
						<div className="col-12 pt-3 d-flex justify-content-center align-items-center">
							<h2 className="not_found_h2">
								Oops! This Page Could<br />Not Be
								Found
							</h2>

						</div>
						<div className="col-12 d-flex justify-content-center align-items-center">
							<p className="not_found_p">
								Sorry the page you are looking
								for does not exist,<br />have been
								removed, name changed or is
								temporarily unavailable.
							</p>

						</div>
						<div className="col-12 d-flex justify-content-center align-items-center">
							<div
								className="count_btn  my-3"
								style={{
									display: "flex",
									gap: "0.5rem",
								}}
							>
								<Button

									onClick={() => {
										navigate("/");
									}}
									variant="primary"
								>
									Go To Homepage
								</Button>
							</div>
						</div>
					</div>




			
		</main>
	);
};

export default NotFound;
