import React from "react";
import { etob } from "../../NumberConvert";
import { JdBasicInfoItem } from "../JobPosts/JobDescription";
import moment from "moment";
import { Button } from "react-bootstrap";
import {
  ModalSelect,
  NormalInput2,
  ModalDateInput2,
} from "../Public/InputField";
import dayjs from "dayjs";

const SingleEducation = ({ data, index }) => {
  return (
    <div className="p-3">
      <h2>শিক্ষাগত যোগ্যতা - {etob(index)}</h2>

      <div className="row">
        <JdBasicInfoItem
          title={"শিক্ষাগত যোগ্যতার ধরণ"}
          value={data?.education?.label}
        />
        <JdBasicInfoItem title={"বিষয়"} value={data?.subject?.value} />
        {data?.institute?.label && (
          <JdBasicInfoItem
            title={"প্রতিষ্ঠানের নাম"}
            value={data?.institute?.label}
          />
        )}

        <JdBasicInfoItem
          title={"পাস করার বছর"}
          value={
            data?.is_ongoing
              ? "Ongoing"
              : dayjs(data?.end_date?.value, "DD/MM/YYYY").format("MMMM, YYYY")
          }
        />
      </div>
    </div>
  );
};

const SingleEducationEdit = ({
  data,
  index,
  educationOptions,
  universityOptions,
  onEducationChange,
  onUniversityChange,
  onInstituteTextChange,
  onCheckBoxChange,
  onSubjectTextChange,
  onEndDateChange,
  onRemoveClick,
}) => {
  return (
    <div className="p-3">
      <div className="profile_section d-flex justify-content-between align-items-center mb-3">
        <h2>শিক্ষাগত যোগ্যতা - {etob(index + 1)}</h2>
        {index > 0 && (
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              onRemoveClick(index);
            }}
            style={{
              color: "#B50D0D",
            }}
          >
            রিমুভ করুন -
          </Button>
        )}
      </div>

      <div className="row">
        <ModalSelect
          label={"শিক্ষাগত যোগ্যতার ধরণ"}
          isLabel={true}
          data={data?.education || {}}
          options={educationOptions}
          ph={"শিক্ষাগত যোগ্যতা বাছাই করুন"}
          isWarning={data?.education?.is_warning}
          warningText={"একটি শিক্ষাগত যোগ্যতা বাছাই করতে হবে।"}
          onChange={(selectedOption) => {
            onEducationChange(selectedOption, index);
          }}
          onMenuOpen={() => {}}
        />

        {data?.education?.is_university_required ? (
          <ModalSelect
            label={"প্রতিষ্ঠানের নাম"}
            isLabel={true}
            data={data?.institute || {}}
            options={universityOptions}
            ph={"প্রতিষ্ঠান বাছাই করুন"}
            isWarning={data?.institute?.is_warning}
            warningText={"একটি প্রতিষ্ঠান বাছাই করতে হবে।"}
            onChange={(selectedOption) => {
              onUniversityChange(selectedOption, index);
            }}
            onMenuOpen={() => {}}
          />
        ) : (
          <NormalInput2
            label={"প্রতিষ্ঠানের নাম"}
            isLabel={true}
            isWarning={data?.institute?.is_warning}
            warningText={"প্রতিষ্ঠানের নাম পূরণ করতে হবে।"}
            ph={"আপনার প্রতিষ্ঠানের নাম লিখুন"}
            id={"institute_name"}
            name={"institute_name"}
            data={data?.institute?.label || ""}
            type={"text"}
            onChange={(e) => {
              onInstituteTextChange(e, index);
            }}
          />
        )}

        {/* <ModalCheckButton
          text={"আমি এখনও এখানে পড়াশুনা করছি"}
          isChecked={data?.is_ongoing}
          onCheckChange={(status) => {
            console.log(status);
            onCheckBoxChange(status, index);
          }}
        /> */}

        <ModalDateInput2
          label={"পাস করার বছর"}
          warningText={"পাস করার বছর প্রদান করতে হবে"}
          isWarning={data?.end_date?.is_warning}
          ph={"দিন/মাস/বছর"}
          isMandatory={!data?.is_ongoing}
          isDisable={data?.is_ongoing}
          date={data?.end_date?.value}
          onChange={(dt) => {
            onEndDateChange(dt, index);
          }}
          text={"আমি এখনও এখানে পড়াশুনা করছি"}
          isChecked={data?.is_ongoing}
          onCheckChange={(status) => {
            console.log(status);
            onCheckBoxChange(status, index);
          }}
        />

        <NormalInput2
          label={"বিষয়"}
          isLabel={true}
          isWarning={data?.subject?.is_warning}
          warningText={"বিষয়ের নাম পূরণ করতে হবে।"}
          ph={"আপনার বিষয়ের নাম লিখুন"}
          id={"institute_name"}
          name={"institute_name"}
          data={data?.subject?.value || ""}
          type={"text"}
          onChange={(e) => {
            onSubjectTextChange(e, index);
          }}
        />
      </div>
    </div>
  );
};

export { SingleEducation, SingleEducationEdit };
