import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import close from "../../img/feedback_modal_close.png";
import filledStar from "../../img/filled_star.png";
import outlineStar from "../../img/outline_star.png";
import { insertFeedback } from "../../ApiCalls/User";
import thankYouImg from "../../img/feedback_thank_you_img.png";

export const FeatureFeedbackModal = ({ isVisible, featureName, title, hide }) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
    if (selectedRating === 5) {
      setSubmitted(true);
      submitFeedback(5, "");
      document.querySelector(".rating-form").classList.add("hidden");
      setTimeout(() => {
        hide(false);
        
      }, 5000);
    }
  };
  const submitFeedback = async (tempRating, tempFeedback) => {
    const feedbackData = {
      user_id: localStorage.getItem("userId"),
      feature: featureName,
      feedback: tempFeedback,
      rating: tempRating,
    };
    await insertFeedback(feedbackData);
  };
  return (
    <Modal
      show={isVisible}
      size="md"
      centered
      onHide={() => {
        if (!submitted) {
          submitFeedback(rating, feedback);
        }
        hide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="feedback">
        {!submitted ? (
          <div className="rating-form">
            <div
              className="d-flex justify-content-end mb-2"
              onClick={() => {
                hide();
              }}
            >
              <img src={close} width="30" height="30" alt="Avatar"></img>
            </div>
            <div className="d-flex justify-content-center p1">
              <p>
                {title}
              </p>
            </div>
            <div className="d-flex justify-content-around mx-4 mb-3">
              {[1, 2, 3, 4, 5].map((star) => (
                <img
                  src={star <= rating ? filledStar : outlineStar}
                  width="36"
                  height="36"
                  alt="Avatar"
                  key={star}
                  onClick={() => handleStarClick(star)}
                  className="rating"
                />
              ))}
            </div>

            <div
              className={`feedback-textarea ${
                rating > 0 && rating < 5 ? "open" : ""
              }`}
            >
              <div className="d-flex justify-content-center">
                <p className="p2">আপনার মন্তব্য শেয়ার করুন</p>
              </div>
              <div className="d-flex justify-content-center">
                <textarea
                  className="form-control custom-scrollbar-textarea"
                  rows={5}
                  onChange={(e) => {
                    setFeedback(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex justify-content-center">
                <div className="apply-confirm-btn" onClick={() => {
                  setSubmitted(true);
                  submitFeedback(rating, feedback);
                   document
                     .querySelector(".rating-form")
                     .classList.add("hidden");
                    setTimeout(() => {
                     hide(false);
                   }, 5000);
                
                }}>
                  জমা দিন
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-center mt-3">
              <img src={thankYouImg} height="130" width="130" alt="Avatar" />
            </div>

            <p className="thank-you-title mt-3">
              আপনার মতামত প্রকাশ করার জন্যে ধন্যবাদ
            </p>

            <p className="thank-you-details mx-3">
              আপনার প্রতিক্রিয়া আমাদের টীম পর্যালোচনা করবে এবং আমাদের সাথে
              আপনার অভিজ্ঞতা উন্নত করার চেষ্টা করবে ।
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
