import React, { useEffect, useState } from "react";
import { SuccessStoryCard } from "../Components/SuccessStoryCard";

import  { AppBannerCarousel2 } from "../Components/AppBannerCarousel";
import {
  getUserSuccessStory,
  getSuccessfullJobApplications,
} from "../ApiCalls/SuccessStorys";
import background_image from "../img/background_image.jpg";
import { Helmet } from "react-helmet-async";

const SuccessStorys = () => {
  const [successStorys, setSuccessStorys] = useState([]);
  const [successfullJobs, setSuccessfullJobs] = useState([]);
  const userId = localStorage.getItem("userId");

  const getSuccessStory = async () => {
    const body = { user_id_data: localStorage.getItem("userId") };
    const data = await getUserSuccessStory(body);
    setSuccessStorys(data?.data);
  };


  const getSuccessfullJobs = async () => {
    const body = {};
    const data = await getSuccessfullJobApplications(body);
    // console.log(data);
    setSuccessfullJobs(data?.data);
  };

  useEffect(() => {
    getSuccessStory();
    getSuccessfullJobs();
  }, []);
  const tl = "Success Story | Shomvob: Jobs & Trainings";
  const ds2 =
    "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";

  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/cvmaker"></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
    <React.Fragment>
      <div
        className="container-fluid scroll-container"
        style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: "100% auto",
          backgroundRepeat: "repeat-y",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            marginBottom: "59px",
          }}
        >
          <div className="success-story-section">
            <h1>চাকরি পেয়েছে</h1>
            <div className="successfully-get-job-section">
              <AppBannerCarousel2
                data={successfullJobs}
                slidesToScroll={1}
                slidesToShow={4}
              />
            </div>
            <div className="successfully-get-job-section-1600">
              <AppBannerCarousel2
                data={successfullJobs}
                slidesToScroll={1}
                slidesToShow={3}
              />
            </div>

            <div className="successfully-get-job-section-1200">
              <AppBannerCarousel2
                data={successfullJobs}
                slidesToScroll={1}
                slidesToShow={2}
              />
            </div>

            <div className="successfully-get-job-section-992">
              <AppBannerCarousel2
                data={successfullJobs}
                slidesToScroll={1}
                slidesToShow={2}
              />
            </div>
            <div className="successfully-get-job-section-768">
              <AppBannerCarousel2
                data={successfullJobs}
                slidesToScroll={1}
                slidesToShow={1}
              />
            </div>
            <div className="successfully-get-job-section-480">
              <AppBannerCarousel2
                data={successfullJobs}
                slidesToScroll={1}
                slidesToShow={1}
              />
            </div>
          </div>

          <div className="success-story-section">
            <h1>আমাদের সাফল্যের গল্প</h1>
            <div className="row">
              {successStorys.map((item) => (
                <SuccessStoryCard key={item.id} story={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
      </>
  );
};

export default SuccessStorys;
