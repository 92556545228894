import { postRequest, getRequest } from "./MakeCall";

const getFreeTrainingBanners = async () => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/app_banner?is_visible2=eq.true&carousel_type=eq.training_banner&order=display_sorting_id.asc";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await getRequest(url, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const getYoutubeVideos = async () => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/training_videos?select=*&&is_visible=eq.true";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await getRequest(url, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const getBlogs = async () => {
    try {
      const url =
        process.env.REACT_APP_SUPABASE_URL +
        "/rest/v1/blogs?select=*&blog_type=neq.employers&is_visible=eq.true";
      const accessToken =
        localStorage.getItem("accessToken") &&
        "Bearer " + localStorage.getItem("accessToken");
      const response = await getRequest(url, accessToken);
  
      return response;
    } catch (error) {
      console.error(error);
    }
  };


export {
  getFreeTrainingBanners,
  getYoutubeVideos,
  getBlogs,
};
