import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { getSingleJobDescription } from "../ApiCalls/JobPosts";

const ApplicationTrackerModal = ({
  jobId,
  isModalShow = false,
  toast,
  setModalShow,
}) => {

  const [jobData, setJobData] = useState({});
  //getJobdata

  const getJobData = ()=>{
    const response = getSingleJobDescription(jobId);

    if(response.status === 200){
      setJobData(response.jobData);
    }

  }

  //statusStage
  //jobmatchingdata

  return (
    <Modal
      show={isModalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          mxbdvkjsbgiosgfbksbgfos
        </div>
      </Modal.Body>

	</Modal>
  )
};
export default ApplicationTrackerModal;