

const UserPersonalInfoCheck = (data, isCvMaker = false) => {
  let isValid = true;
  let updatedData = data;

  if (data.full_name.value === null || data.full_name.value <= 2) {
    updatedData = {
      ...updatedData,
      full_name: {
        ...updatedData?.full_name,
        is_warning: true,
      },
    };

    isValid = false;
  } else {
    updatedData = {
      ...updatedData,
      full_name: {
        ...updatedData?.full_name,
        is_warning: false,
      },
    };
  }

  if (data.date_of_birth.value === null || data.date_of_birth.value <= 2) {
    updatedData = {
      ...updatedData,
      date_of_birth: {
        ...updatedData?.date_of_birth,
        is_warning: true,
      },
    };

    isValid = false;
  } else {
    updatedData = {
      ...updatedData,
      date_of_birth: {
        ...updatedData?.date_of_birth,
        is_warning: false,
      },
    };
  }

  if (data.email.value === null || data.email.value <= 2) {
    updatedData = {
      ...updatedData,
      email: {
        ...updatedData?.email,
        is_warning: true,
      },
    };

    isValid = false;
  } else {
    updatedData = {
      ...updatedData,
      email: {
        ...updatedData?.email,
        is_warning: false,
      },
    };
  }
  if (!data.gender.value) {
    updatedData = {
      ...updatedData,
      gender: {
        ...updatedData?.gender,
        is_warning: true,
      },
    };

    isValid = false;
  } else {
    updatedData = {
      ...updatedData,
      gender: {
        ...updatedData?.gender,
        is_warning: false,
      },
    };
  }
  if (isCvMaker) {
    if (
      data.current_address.value === null ||
      data.current_address.value <= 2
    ) {
      updatedData = {
        ...updatedData,
        current_address: {
          ...updatedData?.current_address,
          is_warning: true,
        },
      };

      isValid = false;
    } else {
      updatedData = {
        ...updatedData,
        current_address: {
          ...updatedData?.current_address,
          is_warning: false,
        },
      };
    }
  }

  return { mark: isValid, data: updatedData };
};

// const UserJobFindInfoCheck = (data) => {
//   return { mark: isValid, data: updateData };
// };
const UserEducationInfoCheck = (data) => {
  let isValid = true;
  let updatedData = data.map((item) => {
    let tempItem = { ...item };

    if (!item?.education?.value || item?.education?.value <= 0) {
      isValid = false;
      tempItem.education = { ...tempItem.education, is_warning: true };
    } else {
      tempItem.education = { ...tempItem.education, is_warning: false };
    }

    if (!item?.institute?.label || item.institute.label.length < 2) {
      tempItem.institute = {
        ...tempItem.institute,
        is_warning: true,
      };
      isValid = false;
    } else {
      tempItem.institute = {
        ...tempItem.institute,
        is_warning: false,
      };
    }

    if (!item?.subject?.value || item?.subject?.value.length < 2) {
      tempItem.subject = {
        ...tempItem.subject,
        is_warning: true,
      };
      isValid = false;
    } else {
      tempItem.subject = {
        ...tempItem.subject,
        is_warning: false,
      };
    }

    if (
      !item?.is_ongoing &&
      (!item.end_date?.value || item?.end_date?.value.length < 2)
    ) {
      tempItem.end_date = {
        ...tempItem.end_date,
        is_warning: true,
      };
      isValid = false;
    } else {
      tempItem.end_date = {
        ...tempItem.end_date,
        is_warning: false,
      };
    }

    return tempItem;
  });
  return { mark: isValid, data: updatedData };
};

const UserPreExperienceInfoCheck = (data) => {
  let isValid = true;
  const updateData = data.map((item) => {
    let tempItem = { ...item };
    if (
      !item?.experience_name?.value ||
      item?.experience_name?.value.length < 2
    ) {
      isValid = false;
      tempItem.experience_name = {
        ...tempItem.experience_name,
        is_warning: true,
      };
    } else {
      tempItem.experience_name = {
        ...tempItem.experience_name,
        is_warning: false,
      };
    }

    if (!item?.company_name?.value || item?.company_name?.value.length < 2) {
      isValid = false;
      tempItem.company_name = { ...tempItem.company_name, is_warning: true };
    } else {
      tempItem.company_name = { ...tempItem.company_name, is_warning: false };
    }

    if (!item?.start_date?.value || item?.start_date?.value.length < 2) {
      isValid = false;
      tempItem.start_date = { ...tempItem.start_date, is_warning: true };
    } else {
      tempItem.start_date = { ...tempItem.start_date, is_warning: false };
    }

    if (
      !item?.is_ongoing &&
      (!item?.end_date?.value || item?.end_date?.value.length < 2)
    ) {
      isValid = false;
      tempItem.end_date = { ...tempItem.end_date, is_warning: true };
    } else {
      tempItem.end_date = { ...tempItem.end_date, is_warning: false };
    }

    if (
      !item?.job_responsibilities?.value ||
      item?.job_responsibilities?.value.length < 2
    ) {
      isValid = false;
      tempItem.job_responsibilities = {
        ...tempItem.job_responsibilities,
        is_warning: true,
      };
    } else {
      tempItem.job_responsibilities = {
        ...tempItem.job_responsibilities,
        is_warning: false,
      };
    }

    if (!item?.job_type?.value || item?.job_type?.value <= 0) {
      isValid = false;
      tempItem.job_type = { ...tempItem.job_type, is_warning: true };
    } else {
      tempItem.job_type = { ...tempItem.job_type, is_warning: false };
    }

    return tempItem;
  });
  return { mark: isValid, data: updateData };
};

const UserCourseInfoCheck = (data) => {
  let isValid = true;
  const updateData = data.map((item) => {
    let tempItem = { ...item };
    if (!item?.course?.value || item?.course?.value <= 0) {
      isValid = false;
      tempItem.course = { ...tempItem.course, is_warning: true };
    } else {
      tempItem.course = { ...tempItem.course, is_warning: false };
    }

    if (
      item?.course?.value &&
      item?.course?.value === 385 &&
      item?.other_course?.value.length < 2
    ) {
      isValid = false;
      tempItem.other_course = { ...tempItem.other_course, is_warning: true };
    } else {
      tempItem.other_course = { ...tempItem.other_course, is_warning: false };
    }

    if (!item?.organization?.value || item?.organization?.value.length < 2) {
      isValid = false;
      tempItem.organization = { ...tempItem.organization, is_warning: true };
    } else {
      tempItem.organization = { ...tempItem.organization, is_warning: false };
    }

    return tempItem;
  });
  return { mark: isValid, data: updateData };
};

const UserSkillInfoCheck = (data) => {
  //   return { mark: isValid, data: updateData };
};

const UserReferenceCheck = (data) => {
  let isValid = true;
  const updateData = data.map((item) => {
    let tempItem = { ...item };
    if (!item?.name?.value || item?.name?.value.length < 2) {
      isValid = false;
      tempItem.name = { ...tempItem.name, is_warning: true };
    } else {
      tempItem.name = { ...tempItem.name, is_warning: false };
    }

    if (!item?.designation?.value || item?.designation?.value.length < 2) {
      isValid = false;
      tempItem.designation = { ...tempItem.designation, is_warning: true };
    } else {
      tempItem.designation = { ...tempItem.designation, is_warning: false };
    }

    if (!item?.company?.value || item?.company?.value.length < 2) {
      isValid = false;
      tempItem.company = { ...tempItem.company, is_warning: true };
    } else {
      tempItem.company = { ...tempItem.company, is_warning: false };
    }

    if (!item?.phone?.value || item?.phone?.value.length < 11) {
      isValid = false;
      tempItem.phone = { ...tempItem.phone, is_warning: true };
    } else {
      tempItem.phone = { ...tempItem.phone, is_warning: false };
    }

    if (!item?.email?.value || item?.email?.value.length < 2) {
      isValid = false;
      tempItem.email = { ...tempItem.email, is_warning: true };
    } else {
      tempItem.email = { ...tempItem.email, is_warning: false };
    }

    return tempItem;
  });

  return { mark: isValid, data: updateData };
};

export {
  UserPersonalInfoCheck,
  UserEducationInfoCheck,
  UserPreExperienceInfoCheck,
  UserCourseInfoCheck,
  UserSkillInfoCheck,
  UserReferenceCheck,
};
