import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { SingleEducationEdit } from "../../Components/User/SingleEducation";
import dayjs from "dayjs";

const UserEducationEdit = ({
  data,
  setEducationInfo,
  educationOptions,
  universityOptions,
  updateEducationData,
}) => {
  const validation = () => {
    let isValid = true;
    let updatedData = data.map((item) => {
      let tempItem = { ...item };

      if (!item?.education?.value || item?.education?.value <= 0) {
        isValid = false;
        tempItem.education = { ...tempItem.education, is_warning: true };
      } else {
        tempItem.education = { ...tempItem.education, is_warning: false };
      }

      if (!item?.institute?.label || item.institute.label.length < 2) {
        tempItem.institute = {
          ...tempItem.institute,
          is_warning: true,
        };
        isValid = false;
      } else {
        tempItem.institute = {
          ...tempItem.institute,
          is_warning: false,
        };
      }

      if (!item?.subject?.value || item?.subject?.value.length < 2) {
        tempItem.subject = {
          ...tempItem.subject,
          is_warning: true,
        };
        isValid = false;
      } else {
        tempItem.subject = {
          ...tempItem.subject,
          is_warning: false,
        };
      }

      if (
        !item?.is_ongoing &&
        (!item.end_date?.value || item?.end_date?.value.length < 2)
      ) {
        tempItem.end_date = {
          ...tempItem.end_date,
          is_warning: true,
        };
        isValid = false;
      } else {
        tempItem.end_date = {
          ...tempItem.end_date,
          is_warning: false,
        };
      }

      return tempItem;
    });

    console.log(updatedData);

    setEducationInfo(updatedData);
    return isValid;
  };

  const uploadInfo = () => {
    if (validation()) {
      const dt = data.map((item) => {
        return {
          education_id: item?.education?.value,
          education_name: item?.education?.label,
          end_date: !item?.is_ongoing
            ? dayjs(item?.end_date?.value, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          institute_id: item?.education?.is_university_required
            ? item?.institute?.value
            : null,
          institute_name: item?.institute?.label,
          is_ongoing: item?.is_ongoing,
          subject: item?.subject?.value,
        };
      });
      // console.log(dt);
      updateEducationData(dt, data);
    } else {
      toast.error("সকল প্রয়োজনীয় তথ্য পুরুন করুন", {
        autoClose: 5000,
      });
    }
  };

  return (
    // <Modal
    //   show={isVisible}
    //   size="lg"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    // >
    //   <Modal.Header closeButton>
    //     <Modal.Title className="profile_section">

    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>

    <div className="jd_section">
      <div className="company_details profile_section">
        <h1 className="mb-4">শিক্ষাগত যোগ্যতা</h1>
        {data.map((singleItem, index) => (
          <SingleEducationEdit
            key={index}
            data={singleItem}
            index={index}
            educationOptions={educationOptions}
            universityOptions={universityOptions}
            onRemoveClick={(removeIndex) => {
              const tt = data.filter((it, i) => i !== removeIndex);
              setEducationInfo(tt);
            }}
            onEducationChange={(selectOption, index) => {
              const tt = [...data];
              tt[index].education = {
                value: selectOption.id,
                label: selectOption.education_en,
                is_warning: false,
                is_university_required: selectOption.is_university_required,
              };
              setEducationInfo(tt);
            }}
            onEndDateChange={(text, index) => {
              const tt = [...data];
              tt[index].end_date = {
                value: text,
                is_warning: false,
              };
              setEducationInfo(tt);
            }}
            onUniversityChange={(selectOption, index) => {
              const tt = [...data];
              tt[index].institute = {
                value: selectOption.id,
                label: selectOption.university_name_en,
                is_warning: false,
              };
              setEducationInfo(tt);
            }}
            onInstituteTextChange={(text, index) => {
              const tt = [...data];
              tt[index].institute = {
                value: 0,
                label: text,
                is_warning: false,
              };
              setEducationInfo(tt);
            }}
            onSubjectTextChange={(text, index) => {
              const tt = [...data];
              tt[index].subject = {
                value: text,
                is_warning: false,
              };
              setEducationInfo(tt);
            }}
            onCheckBoxChange={(mark, index) => {
              const tt = [...data];
              tt[index] = {
                ...tt[index],
                is_ongoing: mark,
              };
              setEducationInfo(tt);
            }}
          />
        ))}

        <div className="p-3">
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              setEducationInfo([
                ...data,
                {
                  education: {
                    is_warning: false,
                  },
                  institute: {
                    is_warning: false,
                  },
                  subject: { is_warning: false },
                  is_ongoing: false,
                  end_data: { is_warning: false },
                },
              ]);
            }}
            style={{
              marginTop: "10px",
              color: "#007bff",
              fontSize: "14px",
            }}
          >
            আরও যোগ করুন +
          </Button>
        </div>
      </div>
    </div>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button onClick={onHide}>Close</Button>
    //     <Button
    //       onClick={() => {
    //         uploadInfo();
    //       }}
    //     >
    //       Save
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
};

export { UserEducationEdit };
