import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  ModalDateInput,
  ModalSelect,
  NormalInput2,
} from "../../Components/Public/InputField";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";

const PersonalInfoEdit = ({
  data,
  setPersonalInfo,
  isVisible = false,
  onHide,
  updatePersonalInfoData,
}) => {
  
 
  const gendersOptions = [
    {
      label: "পুরুষ",
      value: "Male",
    },
    {
      label: "মহিলা",
      value: "Female",
    },
    {
      label: "অন্যান্য",
      value: "Other",
    },
  ];

  const validation = () => {
    let isValid = true;
    let updatedData = data;

    if (data.full_name.value === null || data.full_name.value <= 2) {
      updatedData = {
        ...updatedData,
        full_name: {
          ...updatedData?.full_name,
          is_warning: true,
        },
      };

      isValid = false;
    } else {
      updatedData = {
        ...updatedData,
        full_name: {
          ...updatedData?.full_name,
          is_warning: false,
        },
      };
    }

    if (
      data.date_of_birth.value === null ||
      data.date_of_birth.value <= 2
    ) {
      updatedData = {
        ...updatedData,
        date_of_birth: {
          ...updatedData?.date_of_birth,
          is_warning: true,
        },
      };

      isValid = false;
    } else {
      updatedData = {
        ...updatedData,
        date_of_birth: {
          ...updatedData?.date_of_birth,
          is_warning: false,
        },
      };
    }

    if (data.email.value === null || data.email.value <= 2) {
      updatedData = {
        ...updatedData,
        email: {
          ...updatedData?.email,
          is_warning: true,
        },
      };

      isValid = false;
    } else {
      updatedData = {
        ...updatedData,
        email: {
          ...updatedData?.email,
          is_warning: false,
        },
      };
    }
    if (!data.gender.value) {
      updatedData = {
        ...updatedData,
        gender: {
          ...updatedData?.gender,
          is_warning: true,
        },
      };

      isValid = false;
    } else {
      updatedData = {
        ...updatedData,
        gender: {
          ...updatedData?.gender,
          is_warning: false,
        },
      };
    }

    setPersonalInfo(updatedData);

    return isValid;
  };

  const uploadInfo = () => {
    if (validation()) {
      const dt = {
        full_name: data.full_name.value,
        date_of_birth: dayjs(data.date_of_birth.value, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        gender: data.gender.label,
        email: data.email.value,
      };
      updatePersonalInfoData(dt, data);
    } else {
      toast.error("সকল প্রয়োজনীয় তথ্য পুরুন করুন", {
        autoClose: 5000,
      }); 
    }
  };
  return (
    <div className="jd_section">
      <div className="company_details profile_section">
        <h1 className="mb-4">ব্যক্তিগত তথ্য</h1>
        <div className="row">
          <NormalInput2
            label={"পুরো নাম"}
            isLabel={true}
            isWarning={data?.full_name?.is_warning}
            warningText={"নামের ঘরটি পূরণ করতে হবে।"}
            ph={"আপনার নাম লিখুন"}
            id={"user_name"}
            name={"user_name"}
            data={data?.full_name?.value || ""}
            type={"text"}
            onChange={(e) => {
              setPersonalInfo({
                ...data,
                full_name: {
                  ...data?.full_name,
                  value: e,
                  is_warning: false,
                },
              });
            }}
          />
          <NormalInput2
            label={"মোবাইল নম্বর"}
            isLabel={true}
            isWarning={data?.phone?.is_warning}
            warningText={"মোবাইল নম্বর ঘরটি পূরণ করতে হবে।"}
            ph={"আপনার মোবাইল নম্বর লিখুন"}
            id={"phone_number"}
            name={"phone_number"}
            data={"+" + data?.phone?.value || ""}
            type={"text"}
            isDisable={true}
            onChange={(e) => {
              setPersonalInfo({
                ...data,
                phone: {
                  ...data?.phone,
                  value: e,
                  is_warning: false,
                },
              });
            }}
          />
          <ModalSelect
            label={"লিঙ্গ"}
            isLabel={true}
            data={data?.gender || {}}
            options={gendersOptions}
            ph={"লিঙ্গ বাছাই করুন।"}
            isWarning={data?.division?.is_warning}
            warningText={"একটি লিঙ্গ বাছাই করতে হবে।"}
            onChange={(selectedOption) => {
              setPersonalInfo({
                ...data,
                gender: {
                  value: selectedOption.value,
                  label: selectedOption.label,
                  is_warning: false,
                },
              });
            }}
            onMenuOpen={() => {}}
          />

          <ModalDateInput
            label={"জন্ম তারিখ (দিন/মাস/বছর)"}
            warningText={"জন্ম তারিখ প্রদান করতে হবে"}
            isWarning={data?.date_of_birth?.is_warning}
            ph={"দিন/মাস/বছর"}
            date={data?.date_of_birth?.value}
            onChange={(dt) => {
              setPersonalInfo({ 
                ...data,
                date_of_birth: {
                  ...data?.date_of_birth,
                  value: dt,
                  is_warning: false,
                },
              });
            }}
          />

          <NormalInput2
            label={"ই-মেইল"}
            isLabel={true}
            isWarning={data?.email?.is_warning}
            warningText={"ই-মেইল ঘরটি পূরণ করতে হবে।"}
            ph={"আপনার ই-মেইল লিখুন"}
            id={"email"}
            name={"email"}
            data={data?.email?.value || ""}
            type={"text"}
            onChange={(e) => {
              setPersonalInfo({
                ...data,
                email: {
                  ...data?.email,
                  value: e,
                  is_warning: false,
                },
              });
            }}
          />
          <NormalInput2
            label={"ঠিকানা"}
            isLabel={true}
            isWarning={data?.current_address?.is_warning}
            warningText={"ঠিকানার ঘরটি পূরণ করতে হবে।"}
            ph={"আপনার ঠিকানা লিখুন"}
            id={"address"}
            name={"address"}
            data={data?.current_address?.value || ""}
            type={"text"}
            onChange={(e) => {
              setPersonalInfo({
                ...data,
                current_address: {
                  ...data?.current_address,
                  value: e,
                  is_warning: false,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
   
  );
};

export { PersonalInfoEdit };
