import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { getSingleJobDescription } from "../ApiCalls/JobPosts";
import Lottie from "react-lottie";
import animationData from "../lotties/job_confirm_top_animation.json";
import alertImg from "../img/alert_red.png";
const SuccessfulApplicationModal = ({
  isModalShow = false,
  visitHome,
  visitAppliedJob,
}) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal show={isModalShow} size="md" centered>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <Lottie options={defaultOptions} height={100} width={100} />
        </div>
        <div className="d-flex justify-content-center">
          <p className="apply-confirm-title">
            অভিনন্দন {localStorage.getItem("user_name")}{" "}
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <p className="apply-confirm-p">
            আপনি সফলভাবে আপনার আবেদন জমা দিয়েছেন। শীঘ্রই আপনার সাথে যোগাযোগ করা
            হবে।
          </p>
        </div>

        <div className="d-flex justify-content-center">
          <div className="apply-confirm-alert">
            <div
              className="d-flex"
              style={{
                marginLeft: "12px",
                marginTop: "19px",
                marginBottom: "19px",
              }}
            >
              <img src={alertImg} width="26" height="26" alt="Avatar"></img>
              <p >
                <span className="span1">সতর্কতা:</span>
                <span> </span>
                <span className="span2">
                  এই চাকরির জন্য বিজ্ঞাপন দাতা প্রতিষ্ঠান আপনার কাছ থেকে কোন
                  অর্থ চাইলে অথবা কোন ধরনের ভুল বা বিভ্রান্তিকর তথ্য দিলে অতি
                  সত্ত্বর আমাদেরকে জানান।
                  <br />
                  <br />
                  চাকরি পাওয়ার ক্ষেত্রে, আগে বা পরে যেকোনো প্রকার আর্থিক লেনদেন
                  বা অনৈতিক কর্মকাণ্ড হতে বিরত থাকুন। অন্যথায়, এই সকল
                  কর্মকাণ্ডের দায়ভার কোনভাবেই সম্ভব কর্তৃপক্ষের উপর বর্তায় না।
                </span>
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <div className="apply-confirm-btn-2" onClick={visitAppliedJob}>
            আবেদনকৃত চাকরি দেখুন
          </div>
          <div className="apply-confirm-btn" onClick={visitHome}>
            হোমে ফিরে যান
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default SuccessfulApplicationModal;
