import React, { useState } from "react";
import web_icon from "../../img/web_icon.png";
import share_icon from "../../img/company_card_share_icon.png";
import { Link, useNavigate } from "react-router-dom";
import { etob } from "../../NumberConvert";
import CopyToClipboard from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";

const CompanyCard = ({ companyData }) => {
  const navigate = useNavigate();
  const [copyTooltip, setCopyTooltip] = useState("Copy the Share URL.");

  const handleMouseLeave = () => {
    setCopyTooltip("Copy the Share URL.");
  };

  return (
    <>
      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12">
        <div className="company_card" name="Single Company Card">
          <div className="d-flex justify-content-center">
            <img
              src={companyData.logo}
              alt="avatar"
              className="rounded-3"
              style={{
                maxHeight: "68px",
                objectFit: "cover",
                maxWidth: "100%",
              }}
            />
          </div>
          <div className="company-mid my-2">
            <div className="company-card-h ">{companyData.name}</div>
            <div className="company-card-p">
              {companyData.industry ? companyData.industry : "Other"}
            </div>
          </div>

          <div style={{ marginTop: "31px", marginBottom: "25px" }}>
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-3 font_light d-flex justify-content-center">
                {companyData.job_count + " Jobs"}
              </div>
              <div className="col-1">
                <div
                  className="vr font_light d-flex justify-content-center"
                  style={{ height: "100%" }}
                ></div>
              </div>
              <div className="col-3 d-flex justify-content-center">
                <img
                  alt="ttt"
                  style={{
                    maxHeight: "24px",
                    maxWidth: "24px",
                    cursor: "pointer",
                  }}
                  src={web_icon}
                  onClick={() => {
                    window.open(companyData.website_link, "_blank");
                  }}
                />
              </div>
              <div className="col-1">
                <div
                  className="vr font_light d-flex justify-content-center"
                  style={{ height: "100%" }}
                ></div>
              </div>
              <div className="col-3 d-flex justify-content-center">
                <div>
                  <CopyToClipboard
                    text={
                      "https://app.shomvob.co/single_company_description?id=" +
                      companyData.id
                    }
                    onCopy={() =>
                      setCopyTooltip(
                        "Share URL has been copied to your clipboard."
                      )
                    }
                  >
                    <Tooltip title={copyTooltip}>
                      <IconButton onMouseLeave={handleMouseLeave}>
                        <FontAwesomeIcon
                          icon={
                            <FontAwesomeIcon
                              icon={<FontAwesomeIcon icon={faShareNodes} />}
                            />
                          }
                        />
                      </IconButton>
                      <img
                        alt="ttt"
                        style={{
                          maxHeight: "24px",
                          maxWidth: "24px",
                          cursor: "pointer",
                        }}
                        onMouseLeave={handleMouseLeave}
                        src={share_icon}
                      />
                    </Tooltip>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Link
              to={`/single_company_description?id=${companyData.id}`}
              className="jc_details_btn col-12"
              style={{
                textDecoration: "none",
                textAlign: "center",
              }}
              onClick={() => {}}
              target="_blank"
            >
              বিস্তারিত দেখুন
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const GovtJobCard = ({ govtJob }) => {
  const navigate = useNavigate();
  const [copyTooltip, setCopyTooltip] = useState("Copy the Share URL.");

  const handleMouseLeave = () => {
    setCopyTooltip("Copy the Share URL.");
  };

  return (
    <>
      <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12">
        <div className="company_card" name="Single Company Card">
          <div className="d-flex justify-content-center">
            <img
              src={"https://alljobs.teletalk.com.bd/media/" + govtJob.logo}
              alt="avatar"
              className="rounded-3"
              style={{
                maxHeight: "68px",
                objectFit: "cover",
                maxWidth: "100%",
              }}
            />
          </div>
          <div className="company-mid">
            <div className="company-card-h my-2">{govtJob.job_title}</div>
            <div className="company-card-p">{govtJob.org_name}</div>
          </div>
          <div style={{ marginTop: "31px", marginBottom: "25px" }}>
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-3 font_light d-flex justify-content-center">
                {govtJob.vacancy
                  ? govtJob.vacancy + " vacancy"
                  : "Not Declared"}
              </div>
              <div className="col-1">
                <div
                  className="vr font_light d-flex justify-content-center"
                  style={{ height: "100%" }}
                ></div>
              </div>
              <div className="col-3 d-flex justify-content-center">
                <img
                  alt="ttt"
                  style={{
                    maxHeight: "24px",
                    maxWidth: "24px",
                    cursor: "pointer",
                  }}
                  src={web_icon}
                  onClick={() => {
                    window.open(govtJob.application_site_url, "_blank");
                  }}
                />
              </div>
              <div className="col-1">
                <div
                  className="vr font_light d-flex justify-content-center"
                  style={{ height: "100%" }}
                ></div>
              </div>
              <div className="col-3 d-flex justify-content-center">
                <CopyToClipboard
                  text={
                    "https://app.shomvob.co//govtJobs/single_govt_job?id=" +
                    govtJob.job_primary_id
                  }
                  onCopy={() =>
                    setCopyTooltip(
                      "Share URL has been copied to your clipboard."
                    )
                  }
                >
                  <Tooltip title={copyTooltip}>
                    <IconButton onMouseLeave={handleMouseLeave}>
                      {/* <FontAwesomeIcon
                        icon={<FontAwesomeIcon icon={faShareNodes} />}
                      /> */}
                      <img
                        alt="ttt"
                        style={{
                          maxHeight: "24px",
                          maxWidth: "24px",
                          cursor: "pointer",
                        }}
                        src={share_icon}
                        
                      />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
               
              </div>
            </div>
          </div>
          <div className="row">
            <Link
              to={`/govtJobs/single_govt_job?id=${govtJob.job_primary_id}`}
              className="jc_details_btn col-12"
              style={{
                textDecoration: "none",
                textAlign: "center",
              }}
              onClick={() => {}}
              target="_blank"
            >
              বিস্তারিত দেখুন
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export { CompanyCard, GovtJobCard };
