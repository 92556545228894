import axios from "axios";

let header = {
    "Content-Type":
        "application/json",
    apikey: process.env.REACT_APP_API_KEY,
    Authorization:
        "Bearer " +
        localStorage.getItem(
            "accessToken"
        ),
}


const getRequest = async (url, accessToken) => {
    header = {
        ...header,
        Authorization: accessToken
    };

    const response = await axios.get(url, {headers: header});
    return response;

}


const postRequest = async (url, body, accessToken) => {
    header = {
        ...header,
        Authorization: accessToken
    }
    
    const response = await axios.post(url, body, {headers: header});
    return response;
}

const patchRequest = async (url, body, accessToken) => {
    header = {
        ...header,
        Authorization: accessToken
    };
    const response = await axios.patch(url, body, {headers: header});
    return response;
}

const putRequest = async (url, body, accessToken) => {
    header = {
        ...header,
        Authorization: accessToken
    };
    const response = await axios.put(url, body, {headers: header});
    return response;
}


export {getRequest, postRequest, patchRequest, putRequest};