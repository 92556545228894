import { postRequest, getRequest } from "./MakeCall";

const getSavedJobs = async (requestData) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/custom_saved_jobs2";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const getAppliedJobs = async (requestData) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/custom_user_jobs";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};



const getSingleJobDescription = async (jobId) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/job_listing?id=eq." +
      jobId;
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await getRequest(url, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const getLiveJobPosts = async () => {
  const userId = localStorage.getItem("userId");
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/rpc/" +
      (userId ? "custom_job_listing" : "custom_job_listing_guest");
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const requestData = userId ? { user_id_data: userId } : {};
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const getHomePageBanners = async () => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/app_banner?is_visible=eq.true&carousel_type=eq.banner&order=display_sorting_id.asc";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await getRequest(url, accessToken);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getSingleJobDescriptions = async (requestData) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/rpc/"+(localStorage.getItem("userId")?"get_single_job_description":"get_single_job_description_guest");
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getSingleJobAppliedStatus = async (requestData) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/rpc/job_application_progress?order=stage";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getGovtJobs = async () => {
  const url =
    "https://asia-southeast1-shomvob-employer-web-cbbf3.cloudfunctions.net/employerAPIService/employer/api/v2/govt_jobs/get_govt_jobs";
  const response = await getRequest(url, process.env.REACT_APP_FIRBASE_KEY);
  return response;
}

const getSinbgleGovtJob = async (jobId) => {
  const url =
    "https://alljobs.teletalk.com.bd/api/v1/govt-jobs/public-details?id="+jobId;
  const response = await getRequest(url);
  return response;
}
export {
  getSavedJobs,
  getAppliedJobs,
  getSingleJobDescription,
  getLiveJobPosts,
  getHomePageBanners,
  getSingleJobDescriptions,
  getSingleJobAppliedStatus,
  getGovtJobs,
  getSinbgleGovtJob,
};
