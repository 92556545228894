import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SuccessStoryCard = ({ story }) => {
  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-2">
      <div className="CardTwo_Wrapper__DgV5H cursor-pointer">
        {/* <img src={story.image} alt="" width="100%" height="auto" /> */}
        <div className="d-flex px-4 mt-4">
          <LazyLoadImage
            className="avatar-img rounded-5 shadow"
            src={story.image}
            alt="avatar"
            loading="lazy"
            style={{
              width: "159px",
              height: "159px",
              objectFit: "cover",
            }}
          />
          <h2 className="CardTwo_Title__MmDvL ps-3">{story.title_display}</h2>
        </div>

        <div className="CardTwo_Content__ywwwN">
          <p className="CardTwo_Desc__Pm042">{story.story_details}</p>
          <p>
            <span className="CardTwo_Link__NEjYk">
              বিস্তারিত দেখুন
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.999531 0.93927C0.609531 1.32927 0.609531 1.95927 0.999531 2.34927L4.87953 6.22927L0.999531 10.1093C0.609531 10.4993 0.609531 11.1293 0.999531 11.5193C1.38953 11.9093 2.01953 11.9093 2.40953 11.5193L6.99953 6.92927C7.38953 6.53927 7.38953 5.90927 6.99953 5.51927L2.40953 0.92927C2.02953 0.54927 1.38953 0.54927 0.999531 0.93927Z"></path>
              </svg>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

const SuccessfullyGetJobCard = ({ story }) => {
  return (
    <div className="rounded-4 mx-3 successfully-get-job-card">
      <div className="row">
        <div className="d-flex justify-content-center align-items-center">
          <LazyLoadImage
            className="avatar-img rounded-4"
            src={story.profile_photo}
            alt="avatar"
            loading="lazy"
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
            }}
          />
        </div>

        <div className="mt-4 successfully-get-job-card-mid">
          <div className="d-flex justify-content-center successfully-get-job-card-h">
            <p>{story.name}</p>
          </div>
          <div className="d-flex justify-content-center successfully-get-job-card-p">
            <p>{story.job_title}</p>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <LazyLoadImage
            src={story.company_logo}
            alt="avatar"
            loading="lazy"
            style={{
              maxWidth: "180px",
              height: "65px",
              // objectFit: "cover",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { SuccessStoryCard, SuccessfullyGetJobCard };
