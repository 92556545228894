import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const YoutubeVideoCard = ({ item }) => {
  return (
    <Link
      to={item.youtube_link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ padding: "10px" }}
    >
      <LazyLoadImage
        src={item.video_image}
        alt={item.name}
        className="rounded-4"
        style={{
          height: "238px",
          maxWidth: "635px",
        }}
      />
    </Link>
  );
};

const YoutubeVideoCard2 = ({ item }) => {
  return (
    
      <Link
        to={item.youtube_link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: "10px" }}
      >
        <LazyLoadImage
          src={item.video_image}
          alt={item.name}
          className="rounded-3"
          style={{
            height: "150px",
          }}
        />
      </Link> 
    
  );
};
const YoutubeVideoSection = ({ data }) => {
  return (
    <div className="px-5">
      <div className="row">
        {" "}
        <div className="col-6 justify-content-start">
          <h2 className="trainings_h2">ইউটিউব ভিডিও</h2>
        </div>
        <div
          className="col-6"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Link
            to={"/youtube_videos"}
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <h2 className="trainings_h22">
              {"সব দেখুন"}
              <FontAwesomeIcon
                icon={faAnglesRight}
                style={{ marginLeft: "10px" }}
              />
            </h2>
          </Link>
        </div>
      </div>
      <div className="scroll-container">
        {data.map((item) => (
          <YoutubeVideoCard item={item} />
        ))}
      </div>
    </div>
  );
};

export { YoutubeVideoSection, YoutubeVideoCard2 };
