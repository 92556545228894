import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ic_save from "../../img/ic_save.png";
import ic_unsave from "../../img/ic_unsave.png";
import { handleSaveData, handleSaveData2 } from "../../HandleSave";
import app from "../../firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import { etob } from "../../NumberConvert";

const SingleJobCard = ({ jobPost, mark, onClick }) => {
  const analytics = getAnalytics(app);
  const [saveStatus, setSaveStatus] = useState(false);

  const chnageSaveStatus = async () => {
    setSaveStatus(jobPost.isSave === "saved" ? false : true);
    const flag =
      (await jobPost.isSave) === null
        ? handleSaveData2(jobPost.id)
        : handleSaveData(jobPost.id, jobPost.isSave);
    jobPost.isSave =
      jobPost.isSave === "saved" ? "removed_from_saved" : "saved";
  };

  useEffect(() => {
    setSaveStatus(jobPost.isSave === "saved" ? true : false);
  }, []);

  return (
    <>
      <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12">
        <div className="job_cart" key={jobPost.id} name="Single Job Card">
          <Row>
            <Col xs={8}>
              <Row className="align-items-center">
                <Col>
                  <div className="jc_logo float-start">
                    <img src={jobPost.companyLogo} alt="shomvob_jobs" />
                  </div>
                </Col>
              </Row>
            </Col>
            {mark === 1 && (
              <Col xs={4}>
                {localStorage.getItem("userId") && (
                  <Row
                    style={{
                      height: "20%",
                    }}
                    className="align-items-center"
                  >
                    <Col>
                      <div className="float-end" onClick={chnageSaveStatus}>
                        <img
                          src={saveStatus ? ic_save : ic_unsave}
                          style={{
                            height: "25px",
                            objectFit: "cover",
                          }}
                          alt="shomvob_jobs"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={12}>
              <div className="jc_job_title text-truncate">
                <Link
                  to={
                    mark === 3
                      ? "/single_application_tracker?id=" + jobPost.id
                      : "/single_job_description?id=" + jobPost.id
                  }
                  style={{
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    logEvent(analytics, "job_details_view", {
                      platfrom: "web",
                      USER_ID: localStorage.getItem("userId"),
                      JOB_ID: jobPost.id,
                      ACTIVITY: "Job Title clicked",
                    });
                  }}
                  target="_blank"
                  name="Job Title"
                >
                  {jobPost.title}
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="jc_company_name">
              <div className=" text-truncate">
                <Link
                  to={`/single_company_description?id=${jobPost.companyProfileId}`}
                  style={{
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    logEvent(analytics, "company_profile_visit", {
                      platfrom: "web",
                      USER_ID: localStorage.getItem("userId"),
                      COMPANY_ID: jobPost.companyProfileId,
                      ACTIVITY: "Company Name clicked on job post",
                    });
                  }}
                  target="_blank"
                  name="Company Name"
                >
                  {jobPost.companyName}
                </Link>
              </div>
              <div className="text-truncate">
                <span>{jobPost.salaryRange}</span>
              </div>
              <div className="text-truncate">
                <span className="text-truncate" name="Application Deadline">
                  {new Date(jobPost.deadline).toLocaleDateString("en-GB")}
                </span>
              </div>
              <div className="text-truncate">
                <span className="text-truncate" name="Job  Location">
                  {jobPost.jobLocation}
                </span>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "14px" }}>
            {mark === 3 ? (
              <Link
                to={"/single_application_tracker?id=" + jobPost.id}
                className="jc_details_btn col-12"
                style={{
                  textDecoration: "none",
                  textAlign: "center",
                }}
                onClick={() => {
                  logEvent(analytics, "job_details_view", {
                    platfrom: "web",
                    USER_ID: localStorage.getItem("userId"),
                    JOB_ID: jobPost.id,
                    ACTIVITY: "Button clicked",
                  });
                }}
                target="_blank"
              >
                বিস্তারিত দেখুন
              </Link>
            ) : (
              <Link
                to={`/single_job_description?id=${jobPost.id}`}
                className="jc_details_btn col-12"
                style={{
                  textDecoration: "none",
                  textAlign: "center",
                }}
                onClick={() => {
                  logEvent(analytics, "job_details_view", {
                    platfrom: "web",
                    USER_ID: localStorage.getItem("userId"),
                    JOB_ID: jobPost.id,
                    ACTIVITY: "Button clicked",
                  });
                }}
                target="_blank"
              >
                বিস্তারিত দেখুন
              </Link>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

const SingleAppliedJobTopCard = ({ item, isSelected, index, onClick }) => {
  return (
    <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12">
      <div
        className={
          isSelected ? "applied_job_top_card_sc" : "applied_job_top_card"
        }
        key={item.id}
        name="Single Job Card"
        onClick={() => {
          onClick(index);
        }}
      >
        <p>
          {item.title}
          <br />
          <span>{etob(item.value)}</span>
        </p>
      </div>
    </div>
  );
};

export default SingleJobCard;
export { SingleAppliedJobTopCard };
