import {
  ModalDateInput,
  ModalSelect,
  ModalSelect2,
  NormalInput2,
} from "../../Components/Public/InputField";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";

const UserSkillInfoEdit = ({ data, setSkillInfo, skillOptions }) => {
  return (
    <div className="jd_section">
      <div className="company_details profile_section">
        <h1 className="mb-4">
          অন্যান্য দক্ষতা{" "}
          <p style={{ fontSize: "18px" }}>সর্বাধিক ৫টি দক্ষতা বাছাই করা যাবে</p>
        </h1>
        <ModalSelect2
          label={"দক্ষতা"}
          isLabel={false}
          multiSelect={true}
          data={data?.value || {}}
          options={skillOptions}
          ph={"দক্ষতা বাছাই করুন।"}
          isWarning={data?.is_warning}
          warningText={"সর্বাধিক ৫টি দক্ষতা বাছাই করা যাবে"}
          onChange={(selectedOptions) => {
            if (selectedOptions.length <= 5) {
                setSkillInfo({
                    ...data,
                    value: selectedOptions,
                  });
            } else {
              toast.error("সর্বাধিক ৫টি দক্ষতা নির্বাচন করতে পারবেন।", {
                autoClose: 5000,
              });
            }
            
          }}
          onMenuOpen={() => {}}
        />
      </div>
    </div>
  );
};

export { UserSkillInfoEdit };
