import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import React from "react";
import { SingleExperienceEdit } from "../../Components/User/SingleExperience";
import dayjs from "dayjs";

const UserPreExperienceEdit = ({
  data,
  setPreExperienceInfo,
  jobTypeOptions,
  updatePreExperienceData,
}) => {
  const validation = () => {
    let isValid = true;
    const updateData = data.map((item) => {
      let tempItem = { ...item };
      if (
        !item?.experience_name?.value ||
        item?.experience_name?.value.length < 2
      ) {
        isValid = false;
        tempItem.experience_name = {
          ...tempItem.experience_name,
          is_warning: true, 
        };
      } else {
        tempItem.experience_name = {
          ...tempItem.experience_name,
          is_warning: false,
        };
      }

      if (!item?.company_name?.value || item?.company_name?.value.length < 2) {
        isValid = false;
        tempItem.company_name = { ...tempItem.company_name, is_warning: true };
      } else {
        tempItem.company_name = { ...tempItem.company_name, is_warning: false };
      }

      if (!item?.start_date?.value || item?.start_date?.value.length < 2) {
        isValid = false;
        tempItem.start_date = { ...tempItem.start_date, is_warning: true };
      } else {
        tempItem.start_date = { ...tempItem.start_date, is_warning: false };
      }

      if (
        !item?.is_ongoing &&
        (!item?.end_date?.value || item?.end_date?.value.length < 2)
      ) {
        isValid = false;
        tempItem.end_date = { ...tempItem.end_date, is_warning: true };
      } else {
        tempItem.end_date = { ...tempItem.end_date, is_warning: false };
      }

      if (
        !item?.job_responsibilities?.value ||
        item?.job_responsibilities?.value.length < 2
      ) {
        isValid = false;
        tempItem.job_responsibilities = {
          ...tempItem.job_responsibilities,
          is_warning: true,
        };
      } else {
        tempItem.job_responsibilities = {
          ...tempItem.job_responsibilities,
          is_warning: false,
        };
      }

      if (!item?.job_type?.value || item?.job_type?.value <= 0) {
        isValid = false;
        tempItem.job_type = { ...tempItem.job_type, is_warning: true };
      } else {
        tempItem.job_type = { ...tempItem.job_type, is_warning: false };
      }

      return tempItem;
    });
    // console.log(updateData);
    setPreExperienceInfo(updateData);
    return isValid;
  };

  const uploadInfo = () => {
    if (validation()) {
      // console.log(data);
      const dt = data.map((item) => {
        return {
          job_type_id: item?.job_type?.value,
          job_type_text: item?.job_type?.label,
          company_name: item?.company_name?.value,
          end_date: !item?.is_ongoing
            ? dayjs(item?.end_date?.value, "DD/MM/YYYY").format("YYYY-MM-DD")
            : null,
          experience_name: item?.experience_name?.value,
          is_ongoing: item?.is_ongoing,
          job_responsibilities: item?.job_responsibilities?.value,
          start_date: dayjs(item?.start_date?.value, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
        };
      });
      //   console.log(dt);
      updatePreExperienceData(dt, data);
    } else {
      toast.error("সকল প্রয়োজনীয় তথ্য পুরুন করুন", {
        autoClose: 5000,
      });
    }
  };

  return (
    // <Modal
    //   show={isVisible}
    //   size="lg"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    // >
    //   <Modal.Header closeButton>
    //     <Modal.Title className="profile_section">
    //       <h1>আপনার পূর্ববর্তী অভিজ্ঞতা</h1>
    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    <div className="jd_section">
      <div className="company_details profile_section">
        <h1 className="mb-4">আপনার পূর্ববর্তী অভিজ্ঞতা</h1>
        {data.map((singleItem, index) => (
          <SingleExperienceEdit
            key={index}
            data={singleItem}
            index={index}
            jobTypeOptions={jobTypeOptions}
            onRemoveClick={(removeIndex) => {
              const tt = data.filter((it, i) => i !== removeIndex);
              setPreExperienceInfo(tt);
            }}
            onJobTypeChange={(selectedOption, index) => {
              console.log(selectedOption);
              const tt = [...data];
              tt[index].job_type = {
                value: selectedOption.value,
                label: selectedOption.label,
                is_warning: false,
              };
              setPreExperienceInfo(tt);
            }}
            onExperienceNameChange={(text, index) => {
              const tt = [...data];
              tt[index].experience_name = {
                value: text,
                is_warning: false,
              };
              setPreExperienceInfo(tt);
            }}
            onCompanyNameChange={(text, index) => {
              const tt = [...data];
              tt[index].company_name = {
                value: text,
                is_warning: false,
              };
              setPreExperienceInfo(tt);
            }}
            onCheckChange={(mark, index) => {
              const tt = [...data];
              tt[index].is_ongoing = mark;
              setPreExperienceInfo(tt);
            }}
            onStartDateChange={(text, index) => {
              const tt = [...data];
              tt[index].start_date = {
                value: text,
                is_warning: false,
              };
              setPreExperienceInfo(tt);
            }}
            onEndDateChange={(text, index) => {
              const tt = [...data];
              tt[index].end_date = {
                value: text,
                is_warning: false,
              };
              setPreExperienceInfo(tt);
            }}
            onJobResponsibilities={(text, index) => {
              const tt = [...data];
              tt[index].job_responsibilities = {
                value: text,
                is_warning: false,
              };
              setPreExperienceInfo(tt);
            }}
          />
        ))}

        <div className="p-3">
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              setPreExperienceInfo([
                ...data,
                {
                  experience_name: {
                    value: "",
                    is_warning: false,
                  },
                  company_name: {
                    value: "",
                    is_warning: false,
                  },
                  start_date: { value: "", is_warning: false },
                  end_data: { value: "", is_warning: false },
                  is_ongoing: false,
                  job_responsibilities: {
                    value: "",
                    is_warning: false,
                  },
                  job_type: {
                    value: 0,
                    label: "",
                    is_warning: false,
                  },
                },
              ]);
            }}
            style={{
              marginTop: "10px",
              color: "#007bff",
              fontSize: "14px",
            }}
          >
            আরও যোগ করুন +
          </Button>
        </div>
      </div>
    </div>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button onClick={onHide}>Close</Button>
    //     <Button
    //       onClick={() => {
    //         uploadInfo();
    //       }}
    //     >
    //       Save
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
};

export { UserPreExperienceEdit };
