import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

const CVCreateStatusModal = ({
  isVisible = false,
  onHide,
  redirect,
  openCv,
}) => {
  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="profile_section">
          <h1>ফ্রি সিভি</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="profile_section">
          <h2>আপনার সিভি সফল ভাবে তৈরি করা হয়েছে।</h2>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={openCv}>সিভি দেখুন</Button>
        <Button onClick={onHide}>সিভি আপডেট করুন</Button>
        <Button onClick={redirect}>হোম এ ফিরে যান</Button>
      </Modal.Footer>
    </Modal>
  );
};

export { CVCreateStatusModal };
