import { postRequest, getRequest } from "./MakeCall";

const getUserSuccessStory = async (requestData) => {
	try {
		const url =
			process.env.REACT_APP_SUPABASE_URL +
			"/rest/v1/rpc/get_success_story";
		const accessToken =
			localStorage.getItem("accessToken") &&
			"Bearer " + localStorage.getItem("accessToken");
		const response = await postRequest(
			url,
			requestData,
			accessToken
		);

		return response;
	} catch (error) {
		console.error(error);
	}
};

const getSuccessfullJobApplications = async (requestData) => {
	try {
		const url =
			process.env.REACT_APP_SUPABASE_URL +
			"/rest/v1/rpc/successful_job_application";
		const accessToken =
			localStorage.getItem("accessToken") &&
			"Bearer " + localStorage.getItem("accessToken");
		const response = await postRequest(
			url,
			requestData,
			accessToken
		);

		return response;
	} catch (error) {
		console.error(error);
	}
};

export { getUserSuccessStory, getSuccessfullJobApplications };
