import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import config from "./config.json";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Redirect = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [data, setData] = useState({});

	useEffect(() => {
		const searchParams = new URLSearchParams(
			location.hash.substring(1)
		);
		const accessToken = searchParams.get("access_token");

		console.log(accessToken);

		if (accessToken) {
			const decodedToken = jwtDecode(accessToken);
			console.log(decodedToken);

			setData({
				accessToken: accessToken,
				userId: decodedToken.sub,
				phone: decodedToken.phone,
				
			});
		}
	}, [location]);

	useEffect(() => {
		console.log(data);
		if (data) {
			checkProfile();
		}
	}, [data]);

	const checkProfile = async () => {
		try {
			const response = await axios.get(
				config.serverEndPoint +
					"user_profile?user_id=eq." +
					data.userId,

				{
					headers: {
						"Content-Type":
							"application/json",
						apikey: config.apiKey,
						Authorization:
							"Bearer " +
							data.accessToken,
					},
				}
			);

			if (
				Array.isArray(response.data) &&
				response.data.length > 0
			) {
				if (
					response.data[0].username &&
					response.data[0].date_of_birth &&
					response.data[0].gender &&
					response.data[0].full_name &&
					response.data[0].education>0 &&
					response.data[0].work_experience>0 &&
					response.data[0].division>0 &&
					response.data[0].district>0 &&
					response.data[0].job_type_list.length>0
				) {
					localStorage.setItem("accessToken", data.accessToken);
					localStorage.setItem("userId", data.userId);
					window.location = "/";
				} else {
					redirect();
				}
			} else {
				insertProfile();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const redirect = () =>{
		navigate(
			"/CreateProfile",
			{
				state: {
					accessToken: data.accessToken,
					"userId": data.userId,
				},
			}
		);
	}

	const insertProfile = async () => {
		const dt = {
			
			user_id: data.userId,
			username: data.phone,
			is_otp_verified: true,
		};

		try {
			const response = await axios.post(
				config.serverEndPoint + "user_profile",

				dt,

				{
					headers: {
						"Content-Type":
							"application/json",
						apikey: config.apiKey,
						Authorization:
							"Bearer " +
							data.accessToken,
					},
				}
			);

			

			if (response.status === 201) {
				redirect();
			}
		} catch (error) {
			console.error(error);
		}
	};

	return null;
};

export default Redirect;
