import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalSelect } from "../../Components/Public/InputField";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

const JobFindInfoEditModal = ({
  item,
  isVisible = false,
  onHide,
  divisionOptions,
  districtOptions,
  experienceOptions,
  jobTypeOptions,
  educationOptions,
  universityOptions,
  updateJobFindData,
}) => {
  const [tempData, setTempData] = useState({});
  useEffect(() => {
    setTempData(item);
  }, [item]);

  const validation = () => {
    let isValid = true;
    let updatedData = tempData;

    if (!tempData?.division?.value) {
      isValid = false;
      updatedData = {
        ...updatedData,
        division: {
          ...updatedData?.division,
          is_warning: true,
        },
      };
    } else {
      updatedData = {
        ...updatedData,
        division: {
          ...updatedData?.division,
          is_warning: false,
        },
      };
    }

    if (!tempData?.district?.value) {
      isValid = false;
      updatedData = {
        ...updatedData,
        district: {
          ...updatedData?.district,
          is_warning: true,
        },
      };
    } else {
      updatedData = {
        ...updatedData,
        district: {
          ...updatedData?.district,
          is_warning: false,
        },
      };
    }

    if (!tempData?.work_experience?.value) {
      isValid = false;
      updatedData = {
        ...updatedData,
        work_experience: {
          ...updatedData?.work_experience,
          is_warning: true,
        },
      };
    } else {
      updatedData = {
        ...updatedData,
        work_experience: {
          ...updatedData?.work_experience,
          is_warning: false,
        },
      };
    }

    if (!tempData?.job_type?.items || tempData?.job_type?.items.length===0) {
      isValid = false;
      updatedData = {
        ...updatedData,
        job_type: {
          ...updatedData?.job_type,
          is_warning: true,
        },
      };
    } else {
      updatedData = {
        ...updatedData,
        job_type: {
          ...updatedData?.job_type,
          is_warning: false,
        },
      };
    }
    if (!tempData?.education?.value) {
      isValid = false;
      
      updatedData = {
        ...updatedData,
        
        education: {
          ...updatedData?.education,
          is_warning: true,
        },
      };
    } else {
      
      updatedData = {
        ...updatedData,
        education: {
          ...updatedData?.education,
          is_warning: false,
        },
      };
    }
    if (
      tempData?.education?.is_university_required &&
      !tempData?.university?.value
    ) {
      isValid = false;
      updatedData = {
        ...updatedData,
        university: {
          ...updatedData?.university,
          is_warning: true,
        },
      };
    } else {
      updatedData = {
        ...updatedData,
        university: {
          ...updatedData?.university,
          is_warning: false,
        },
      };
    }
    setTempData(updatedData);   
    return isValid;
  };


  const uploadInfo = () => {
    if (validation()) {
      const dt = {
        
        education: tempData?.education?.value,
        division: tempData?.division?.value,
        district: tempData?.district?.value,
        work_experience: tempData?.work_experience?.value,
        job_type_list: tempData?.job_type?.items.map((singleItem) => singleItem.value),
        university_id: tempData?.education?.is_university_required
          ? tempData?.university.value
          : null,
      };

      updateJobFindData(dt, tempData);

      
    } else {
      toast.error("সকল প্রয়োজনীয় তথ্য পুরুন করুন", {
        autoClose: 5000,
      });
    }
  };
  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title className="profile_section">
          <h1>চাকরি খোঁজার তথ্য</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="company_details">
          <div className="row">
            <ModalSelect
              label={"বিভাগ"}
              isLabel={true}
              data={tempData?.division || {}}
              options={divisionOptions}
              ph={"বিভাগ বাছাই করুন।"}
              isWarning={tempData?.division?.is_warning}
              warningText={"একটি বিভাগ বাছাই করতে হবে।"}
              onChange={(selectedOption) => {
                setTempData({
                  ...tempData,
                  division: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    is_warning: false,
                  },
                });
              }}
              onMenuOpen={() => {}}
            />
            <ModalSelect
              label={"এলাকা"}
              isLabel={true}
              data={tempData?.district || {}}
              options={districtOptions}
              ph={"এলাকা বাছাই করুন।"}
              isWarning={tempData?.district?.is_warning}
              warningText={"একটি এলাকা বাছাই করতে হবে।"}
              onChange={(selectedOption) => {
                setTempData({
                  ...tempData,
                  district: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    is_warning: false,
                  },
                });
              }}
              onMenuOpen={() => {}}
            />
            <ModalSelect
              label={"কাজের অভিজ্ঞতা"}
              isLabel={true}
              data={tempData?.work_experience || {}}
              options={experienceOptions}
              ph={"কাজের অভিজ্ঞতা বাছাই করুন।"}
              isWarning={tempData?.work_experience?.is_warning}
              warningText={"একটি কাজের অভিজ্ঞতা বাছাই করতে হবে।"}
              onChange={(selectedOption) => {
                // setExperiences(selectedOption);
                // setExperienceWarning(false);
                setTempData({
                  ...tempData,
                  work_experience: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    is_warning: false,
                  },
                });
              }}
              onMenuOpen={() => {}}
            />
            <ModalSelect
              label={"সর্বোচ্চ শিক্ষাগত যোগ্যতা"}
              isLabel={true}
              data={tempData?.education || {}}
              options={educationOptions}
              ph={"সর্বোচ্চ শিক্ষাগত যোগ্যতা বাছাই করুন।"}
              isWarning={tempData?.education?.is_warning}
              warningText={"একটি সর্বোচ্চ শিক্ষাগত যোগ্যতা বাছাই করতে হবে।"}
              onChange={(selectedOption) => {
                setTempData({
                  ...tempData,
                  education: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    is_university_required:
                      selectedOption.is_university_required,
                    is_warning: false,
                  },
                });
              }}
              onMenuOpen={() => {}}
            />
            {tempData?.education?.is_university_required && (
              <ModalSelect
                label={"শিক্ষা প্রতিষ্ঠান"}
                isLabel={true}
                data={tempData?.university || {}}
                options={universityOptions}
                ph={"শিক্ষা প্রতিষ্ঠান বাছাই করুন।"}
                isWarning={tempData?.university?.is_warning}
                warningText={"শিক্ষা প্রতিষ্ঠান বাছাই করতে হবে।"}
                onChange={(selectedOption) => {
                  setTempData({
                    ...tempData,
                    university: {
                      value: selectedOption.value,
                      label: selectedOption.label,
                      is_warning: false,
                    },
                  });
                }}
                onMenuOpen={() => {}}
              />
            )}

            <ModalSelect
              label={"কাজের ধরন"}
              isLabel={true}
              data={tempData?.job_type?.items || {}}
              options={jobTypeOptions}
              ph={"কাজের ধরন বাছাই করুন।"}
              isWarning={tempData?.job_type?.is_warning}
              warningText={"একটি কাজের ধরন বাছাই করতে হবে।"}
              onChange={(selectedOptions) => {
                if (selectedOptions.length <= 5) {
                  setTempData({
                    ...tempData,
                    job_type: {
                      items: selectedOptions.map((singleItem) => ({
                        value: singleItem.value,
                        label: singleItem.label,
                      })),
                      is_warning: false,
                    },
                  });
                } else {
                  toast.error("সর্বাধিক ৫টি দক্ষতা নির্বাচন করতে পারবেন।", {
                    autoClose: 5000,
                  });
                }
              }}
              multiSelect={true}
              onMenuOpen={() => {}}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button onClick={()=>{uploadInfo();}}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export { JobFindInfoEditModal };
