import React from "react";
import { Link } from "react-router-dom";
const JdBasicInfoItem = ({ title, value }) => {
  return (
    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12">
      <div className="basic">
        <p className="font_light">{title}</p>
      </div>

      <p style={{ fontStyle: "medium" }}>{value}</p>
    </div>
  );
};
const JdBasicInfoItemLink = ({ title, value }) => {
  return (
    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 basic">
      <p className="font_light">{title}</p>
      <p
        className="p_link"
        style={{ fontStyle: "medium" }}
        onClick={() => {
          window.open(value);
        }}
      >
        {value}
      </p>
    </div>
  );
};
const JdBasicInfoItemDetails = ({ title, value }) => {
  return (
    <div className="col-12">
      <div className="basic">
        <p className="font_light">{title}</p>
      </div>

      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        ></div>
      </p>
    </div>
  );
};

export { JdBasicInfoItem, JdBasicInfoItemLink, JdBasicInfoItemDetails };
