import { Component } from "react";

class SignOut extends Component {
  componentDidMount() {
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expireTime");
    window.location = "/";
  }
  render() {
    return null;
  }
}

export default SignOut;
