import "./App.css";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router";

function Lms() {
	const [authEndPoint] = useState(
		"https://backend-api.shomvob.co/api/v2"
	);

	const [phone, setPhone] = useState("");
	const [token, setToken] = useState("");
	

	const { id } = useParams();

	useEffect(() => {

		console.log(id);
		const getOtp = async () => {
			try {
				const response = await axios.post(
					authEndPoint + "/otp/cred/lms",
					{
						user_id: id,
					},
					{
						headers: {
							"Content-Type":
								"application/json",
							Authorization:
								"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlNob212b2JUZWNoQVBJVXNlciIsImlhdCI6MTY1OTg5NTcwOH0.IOdKen62ye0N9WljM_cj3Xffmjs3dXUqoJRZ_1ezd4Q",
						},
					}
				);

				if (response.status === 200) {
				
					setPhone(response.data.data.user);
					setToken(response.data.data.token);

					console.log(response.data.data.user);
					console.log(response.data.data.token);
				}
			} catch (error) {
				console.error(error);
			}
		};
		getOtp();
	}, []);

	return (
		<>
			

			<form
				className="login-form justify-content-center d-flex flex-column align-items-center"
				style={{
					marginBottom: "30px",
					height: "100vh",
				}}
				action="https://lms.shomvob.co/login/index.php"
				method="post"
				id="login"
			>
				<div
					className="login-form-username form-group"
					style={{ display: "none" }}
				>
					<label
						htmlFor="username"
						className="sr-only"
					>
						Username
					</label>
					<input
						type="text"
						name="username"
						id="username"
						className="form-control form-control-lg"
						value={phone}
						placeholder="Username"
						autoComplete="username"
					/>
				</div>
				<div
					className="login-form-password form-group"
					style={{ display: "none" }}
				>
					<label
						htmlFor="password"
						className="sr-only"
					>
						Password
					</label>
					<input
						type="password"
						name="password"
						id="password"
						value={token}
						className="form-control form-control-lg"
						placeholder="Password"
						autoComplete="current-password"
					/>
				</div>
				<div className="login-form-submit form-group">
					<button
						className="btn btn-primary btn-lg"
						type="submit"
						id="loginbtn"
					>
						Continue
					</button>
				</div>
			</form>
		</>
	);
}

export default Lms;
