import { createClient } from "@supabase/supabase-js";

function SupabaseTest() {
  const supabaseUrl = "https://iuazegsorvopdfkveycu.supabase.co";
  const supabaseKey =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJvbGUiOiJhbm9uIiwiaWF0IjoxNjQyNDkxMTc4LCJleHAiOjE5NTgwNjcxNzh9.Oz-apWdllp2W8JlB4oGG0mF5QJnrN4vDOzk6BkJlSH4";

  const supabase = createClient(supabaseUrl, supabaseKey);

  async function updateImage(path, img) {
    const buffer = await img.arrayBuffer();
    const { data, error } = await supabase.storage
      .from("shomvob-bucket")
      .updateObject(path, buffer);

    if (error) {
      console.log(error.message);
    } else {
      console.log(data);
    }
  }
}

export default SupabaseTest;
