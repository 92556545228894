import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import app from "../firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import { etob } from "../NumberConvert";
import { Helmet } from "react-helmet-async";
import { getSingleJobDescriptions } from "../ApiCalls/JobPosts";
import SideBar from "../Components/SideBar";
import background_image from "../img/background_image.jpg";
import { JdBasicInfoItem } from "../Components/JobPosts/JobDescription";
import { Backdrop, CircularProgress } from "@mui/material";


function JobDescription() {
  const analytics = getAnalytics(app);
  const [jobData, setJobData] = useState({});
  const [benefits, setBenefits] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const ds =
    "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";
  
  const handleJobApply = () => {
    if (localStorage.getItem("userId")) {
      if (jobData.is_applied) {
        toast.error("আপনি পূর্বেই এই চাকরিতে আবেদন করেছেন।", {
          autoClose: 5000,
        });
      } else if (
        new Date(jobData.application_deadline) < new Date() ||
        jobData.status !== "active"
      ) {
        toast.error("এই চাকরিটি আর আবেদন গ্রহন করছে না", {
          autoClose: 5000,
        });
      } else if (
        (jobData.gender === "পুরুষ" &&
          jobData.gender_specific_type === "male") ||
        (jobData.gender === "মহিলা" &&
          jobData.gender_specific_type === "female") ||
        jobData.gender_specific_type === "both"
      ) {
        logEvent(analytics, "job_apply", {
          FROM: "job description",
          platfrom: "web",
          USER_ID: localStorage.getItem("userId"),
          JOB_ID: id,
          ACTIVITY: "apply button clicked",
        });
        navigate("/JobApplication1", {
          state: { jobData: jobData },
        });
      } else {
        toast.error("এই চাকরিটি আপনার জন্য প্রযোজ্য নয়।", {
          autoClose: 5000,
        });
      }
    } else {
      navigate("/SignUp", {
        state: { from: "/single_job_description?id=" + id },
      });
    }
  };

  const getJobData = async () => {
    const userId = localStorage.getItem("userId");
    const requestData = {
      job_id_data: id,
      ...(userId && {
        user_id_data: userId,
      }),
    };
    const response = await getSingleJobDescriptions(requestData);
    if (response.status === 200) {
      setJobData(response.data[0]);
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
    getJobData();
  }, []);

  useEffect(() => {
    if (
      (jobData?.benefits?.length > 0 &&
        jobData?.benefits[0] !== null &&
        jobData?.benefits[0] !== "null") ||
      jobData?.salary_review?.length > 0 ||
      jobData?.lunch_facilities?.length > 0 ||
      jobData?.festival_bonus > 0
    ) {
      let bbb = "<ul>";
      if (
        jobData?.benefits?.length > 0 &&
        jobData?.benefits[0] !== null &&
        jobData?.benefits[0] !== "null"
      ) {
        bbb =
          bbb +
          jobData?.benefits
            .map((item) => {
              return "<li>" + item + "</li>";
            })
            .join(" ");
      }
      if (jobData?.festival_bonus > 0) {
        bbb = bbb + " <li>উৎসব বোনাস: " + jobData.festival_bonus + "</li>";
      }
      if (jobData?.lunch_facilities?.length > 0) {
        bbb =
          bbb + " <li>খাবারের সুবিধা: " + jobData?.lunch_facilities + "</li>";
      }
      if (jobData?.salary_review?.length > 0) {
        bbb = bbb + " <li>বেতন রিভিউ: " + jobData?.salary_review + "</li>";
      }
      bbb = bbb + "</ul>";
      setBenefits(bbb);
    }
  }, [jobData]);

  let tl = jobData?.job_title
    ? jobData?.job_title + " | " + jobData?.company_name
    : "Shomvob: Jobs & Trainings";
  let ds2 = jobData?.job_title
    ? "Apply for " +
      jobData?.job_title +
      " at " +
      jobData?.company_name +
      " with Shomvob: Jobs & Trainings"
    : ds;
  let previewImageUrl =
    "https://iuazegsorvopdfkveycu.supabase.co/storage/v1/object/public/shomvob-public/jobcard/"+id+".png";
  let pageUrl =
    "https://app.shomvob.co/single_job_description?id="+id;
  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href={pageUrl}></link>

        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        <meta property="og:image" content={previewImageUrl} />
        <meta property="og:url" content={pageUrl} data-rh="true" />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="Details" />
        <meta name="twitter:url" content={pageUrl} />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        <meta property="twitter:image" content={previewImageUrl} />

        {/*End Twitter tags*/}
      </Helmet>
      <React.Fragment>
        <ToastContainer />

        <div
          className=" scroll-container container-fluid"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          <div className="rounded" name="Job Details">
            <div className="jd_section">
              <div className="top">
                <img
                  src={jobData.company_logo}
                  alt="avatar"
                  style={{
                    maxHeight: "97px",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="top">
                <h1>{jobData.job_title}</h1>
              </div>
              <div className="top">
                <Link
                  to={`/single_company_description?id=${jobData.company_profile_id}`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                  }}
                  name="Company Name"
                >
                  {jobData.company_name}
                </Link>
              </div>
            </div>

            <div className="jd_section">
              <div className="company_details">
                <div className="row">
                  <JdBasicInfoItem
                    title={"বেতন"}
                    value={
                      jobData.salary_range &&
                      (jobData.salary_range.includes("TK. ") || jobData.salary_range.includes("Tk. "))
                        ? etob(jobData.salary_range).replace("TK. ", "").replace('Tk. ',"") +
                          " টাকা"
                        : jobData.salary_range
                    }
                  />
                  <JdBasicInfoItem
                    title={"পদ সংখ্যা"}
                    value={etob(jobData.total_recruits) + " টি"}
                  />
                  <JdBasicInfoItem
                    title={"লোকেশন"}
                    value={jobData.job_locations}
                  />
                  <JdBasicInfoItem
                    title={"আবেদনের শেষ তারিখ"}
                    value={etob(
                      new Date(jobData.application_deadline).toLocaleDateString(
                        "en-GB"
                      )
                    )}
                  />
                  <JdBasicInfoItem
                    title={"ন্যূনতম শিক্ষাগত যোগ্যতা"}
                    value={jobData.education_name}
                  />
                  <JdBasicInfoItem
                    title={"অভিজ্ঞতা"}
                    value={jobData.work_experience_name}
                  />
                  {jobData?.employment_status && (
                    <JdBasicInfoItem
                      title={"চাকরির ধরন"}
                      value={jobData.employment_status}
                    />
                  )}
                  {jobData?.work_hours && (
                    <JdBasicInfoItem
                      title={"কাজের সময়"}
                      value={etob(jobData.work_hours) + " ঘন্টা"}
                    />
                  )}
                  {jobData?.job_shift && (
                    <JdBasicInfoItem
                      title={"কাজের শিফট"}
                      value={jobData.job_shift}
                    />
                  )}
                  {benefits?.length > 0 && (
                    <div className="col-12">
                      <div className="basic">
                        <p className="font_light">কোম্পানীর সুযোগ সুবিধাদি</p>
                      </div>

                      <p style={{ fontStyle: "medium" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: benefits,
                          }}
                        ></div>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="jd_section mb-5">
              <h1 className="wr">কাজের দায়িত্ব</h1>
              <p className="px-3">
                <div
                  style={{ overflow: "hidden", whiteSpace: "normal" }}
                  dangerouslySetInnerHTML={{
                    __html: jobData.job_responsibilities,
                  }}
                ></div>
              </p>
              <hr />
              <div
                className="mt-5 d-flex justify-content-center"
                onClick={handleJobApply}
              >
                <div className="apply_btn">
                  {jobData.is_applied ? "আবেদন করা হয়েছে" : "আবেদন করুন"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    </>
  );
}

export default JobDescription;
