const english_digits = "0123456789";
const bangla_digits = "০১২৩৪৫৬৭৮৯";

const englishToBanglaMapping = new Map([...english_digits].map((digit, index) => [digit, bangla_digits[index]]));
const banglaToEnglishMapping = new Map([...bangla_digits].map((digit, index) => [digit, english_digits[index]]));


export const etob = (number) => {
    return String(number).split('').map(digit => englishToBanglaMapping.get(digit) || digit).join('');
};

export const btoe = (number) => {
    return String(number).split('').map(digit => banglaToEnglishMapping.get(digit) || digit).join('');
};