import { postRequest } from "./MakeCall";

const getActiveCompanys = async () => {
  const userId = localStorage.getItem("userId");
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/rpc/" +
      (userId ? "get_active_companys" : "get_active_companys_guest");
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const requestData = userId ? { user_id_data: userId } : {};
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const getSingleCompanyData = async (requestData) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/rpc/get_single_company_information2";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");

    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

export { getActiveCompanys, getSingleCompanyData };
