import dayjs from "dayjs";

const processUserPersonalInfotoUpdated = (data) => {
  const dt = {
    full_name: data?.full_name?.value,
    date_of_birth: dayjs(data.date_of_birth.value, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
    gender: data?.gender?.label,
    email: data?.email?.value,
    current_address: data?.current_address?.value,
  };

  return dt;
};

const processUserEducationInfotoUpdated = (data) => {
  const dt = data.map((item) => {
    return {
      education_id: item?.education?.value,
      education_name: item?.education?.label,
      end_date: !item?.is_ongoing
        ? dayjs(item?.end_date?.value, "DD/MM/YYYY").format("YYYY-MM-DD")
        : null,
      institute_id: item?.education?.is_university_required
        ? item?.institute?.value
        : null,
      institute_name: item?.institute?.label,
      is_ongoing: item?.is_ongoing,
      subject: item?.subject?.value,
    };
  });
  return dt;
};

const processUserExperenceInfotoUpdated = (data) => {
  const dt = data.map((item) => {
    return {
      job_type_id: item?.job_type?.value,
      job_type_text: item?.job_type?.label,
      company_name: item?.company_name?.value,
      end_date: !item?.is_ongoing
        ? dayjs(item?.end_date?.value, "DD/MM/YYYY").format("YYYY-MM-DD")
        : null,
      experience_name: item?.experience_name?.value,
      is_ongoing: item?.is_ongoing,
      job_responsibilities: item?.job_responsibilities?.value,
      start_date: dayjs(item?.start_date?.value, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
    };
  });
  return dt;
};

const processUserCourseInfotoUpdated = (data) => {
  const dt = data.map((item) => {
    return {
      course_id: item?.course?.value,
      course_name: item?.course?.label,
      organization: item?.organization?.value,
      other_course:
        item?.course?.value === 385 ? item?.other_course?.value : null,
    };
  });
  return dt;
};

const processUserReferenceInfotoUpdated = (data) => {
  const dt = data.map((item) => {
    return {
      reference_company: item?.company?.value,
      reference_designation: item?.designation?.value,
      reference_email: item?.email?.value,
      reference_name: item?.name?.value,
      reference_phone: item?.phone?.value,
    };
  });
  return dt;
};

export {
  processUserPersonalInfotoUpdated,
  processUserEducationInfotoUpdated,
  processUserExperenceInfotoUpdated,
  processUserCourseInfotoUpdated,
  processUserReferenceInfotoUpdated,
};
