import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyDwdzruI4y4tibkZLm-186Xh7avzx9qfyM",
  authDomain: "shomvob-web.firebaseapp.com",
  projectId: "shomvob-web",
  storageBucket: "shomvob-web.appspot.com",
  messagingSenderId: "900037768500",
  appId: "1:900037768500:web:1c811d5002b6a65f6ea161",
  measurementId: "G-32MQ79XK7V"
};

const app = initializeApp(firebaseConfig);

export default app;