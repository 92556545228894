export const createRoundedImage = async (blob) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set canvas dimensions equal to the image dimensions
      canvas.width = 400;
      canvas.height = 400;
      // Draw image onto canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Create rounded corners
      const radius = Math.min(canvas.width, canvas.height) / 2;
      ctx.globalCompositeOperation = "destination-in";
      ctx.beginPath();
      ctx.arc(
        canvas.width / 2,
        canvas.height / 2,
        radius,
        0,
        Math.PI * 2,
        true 
      );
      ctx.closePath();
      ctx.fill();
      const roundedDataURL = canvas.toDataURL("image/png");

      resolve(roundedDataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = URL.createObjectURL(blob);
  });
};
