import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { SingleReferenceEdit } from "../../Components/User/SingleReference";

const UserReferenceModal = ({
  data,
  isVisible = false,
  onHide,
  updateReferenceData,
}) => {
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    setTempData(data);
  }, []);

  const validation = () => {
    let isValid = true;

    const updateData = tempData.map((item) => {
      let tempItem = { ...item };
      if (!item?.name?.value || item?.name?.value.length < 2) {
        isValid = false;
        tempItem.name = { ...tempItem.name, is_warning: true };
      } else {
        tempItem.name = { ...tempItem.name, is_warning: false };
      }

      if (!item?.designation?.value || item?.designation?.value.length < 2) {
        isValid = false;
        tempItem.designation = { ...tempItem.designation, is_warning: true };
      } else {
        tempItem.designation = { ...tempItem.designation, is_warning: false };
      }

      if (!item?.company?.value || item?.company?.value.length < 2) {
        isValid = false;
        tempItem.company = { ...tempItem.company, is_warning: true };
      } else {
        tempItem.company = { ...tempItem.company, is_warning: false };
      }

      if (!item?.phone?.value || item?.phone?.value.length < 11) {
        isValid = false;
        tempItem.phone = { ...tempItem.phone, is_warning: true };
      } else {
        tempItem.phone = { ...tempItem.phone, is_warning: false };
      }

      if (!item?.email?.value || item?.email?.value.length < 2) {
        isValid = false;
        tempItem.email = { ...tempItem.email, is_warning: true };
      } else {
        tempItem.email = { ...tempItem.email, is_warning: false };
      }

      return tempItem;
    });
    setTempData(updateData);
    return isValid;
  };

  const uploadInfo = () => {
    if (validation()) {
      const dt = tempData.map((item) => {
        return {
          reference_company: item?.company?.value,
          reference_designation: item?.designation?.value,
          reference_email: item?.email?.value,
          reference_name: item?.name?.value,
          reference_phone: item?.phone?.value,
        };
      });

      updateReferenceData(dt, tempData);
    } else {
      toast.error("সকল প্রয়োজনীয় তথ্য পুরুন করুন", {
        autoClose: 5000,
      });
    }
  };

  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title className="profile_section">
          <h1>রেফারেন্স</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="company_details">
          {tempData.map((singleItem, index) => (
            <SingleReferenceEdit
              key={index}
              data={singleItem}
              index={index}
              onRemoveClick={(removeIndex) => {
                const tt = tempData.filter((it, i) => i !== removeIndex);
                setTempData(tt);
              }}
              onNameChange={(text, index) => {
                const tt = [...tempData];
                tt[index].name = {
                  value: text,
                  is_warning: false,
                };
                setTempData(tt);
              }}
              onDesignationChange={(text, index) => {
                const tt = [...tempData];
                tt[index].designation = {
                  value: text,
                  is_warning: false,
                };
                setTempData(tt);
              }}
              onCompanyChange={(text, index) => {
                const tt = [...tempData];
                tt[index].company = {
                  value: text,
                  is_warning: false,
                };
                setTempData(tt);
              }}
              onPhoneChange={(text, index) => {
                const tt = [...tempData];
                tt[index].phone = {
                  value: text,
                  is_warning: false,
                };
                setTempData(tt);
              }}
              onEmailChange={(text, index) => {
                const tt = [...tempData];
                tt[index].email = {
                  value: text,
                  is_warning: false,
                };
                setTempData(tt);
              }}
            />
          ))}

          <div className="p-3">
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                setTempData([
                  ...tempData,
                  {
                    name: { value: "", is_warning: false },
                    designation: { value: "", is_warning: false },
                    company: { value: "", is_warning: false },
                    phone: { value: "", is_warning: false },
                    email: { value: "", is_warning: false },
                  },
                ]);
              }}
              style={{
                marginTop: "10px",
                color: "#007bff",
                fontSize: "14px",
              }}
            >
              আরও যোগ করুন +
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button
          onClick={() => {
            uploadInfo();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { UserReferenceModal };
