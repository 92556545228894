import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { InputGroup, Form } from "react-bootstrap";
import Select from "react-select";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const SearchInput = ({
  data,
  onSearchTextChange,
  ph,
  id,
  name,

  type,
}) => {
  return (
    <div className="col">
      <InputGroup className="job_filter2" style={{minHeight: "47px"}}>
        <InputGroup.Text id="basic-addon1" style={{ background: "#FFF" }}>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className=" input_field_icon"
          />
        </InputGroup.Text>
        <Form.Control
          type={type}
          name={name}
          id={id}
          className="noError"
          placeholder={ph}
          value={data || ""}
          onChange={(e) => {
            onSearchTextChange(e.target.value);
          }}
        />
      </InputGroup>
    </div>
  );
};

const FilterSelect = ({
  isLabel = true,
  label,
  options,
  data,
  onChange,
  ph,
  id,
  name,
  multiSelect = false,
  isDisable = false,
  isVisible = true,
  isWarning = false,
  warningText,
  onMenuOpen,
}) => {
  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
      {isVisible ? (
        <div>
          {isLabel && (
            <p className="input_field_p">
              {label}
              <span
                style={{
                  color: "red",
                }}
              >
                {" "}
                *
              </span>
            </p>
          )}

          <Select
            className="job_filter2"
            styles={{ control: (base) => ({ ...base, minHeight: "47px" }) }}
            value={
              multiSelect ? data : Object.keys(data).length > 0 ? data : []
            }
            id={id}
            name={name}
            isMulti={multiSelect}
            isDisabled={isDisable}
            onChange={(selectedOptions) => {
              onChange(selectedOptions);
            }}
            onMenuOpen={() => {
              onMenuOpen();
            }}
            options={options}
            placeholder={ph}
          />
          {isWarning && <small className="text-danger">* {warningText}</small>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export { FilterSelect, SearchInput };
