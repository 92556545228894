import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import app from "../../firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import { etob } from "../../NumberConvert";
import { Helmet } from "react-helmet-async";
import { getSinbgleGovtJob } from "../../ApiCalls/JobPosts";
import background_image from "../../img/background_image.jpg";
import { JdBasicInfoItem, JdBasicInfoItemDetails } from "../../Components/JobPosts/JobDescription";

function SingleGovtJob() {
  const analytics = getAnalytics(app);
  const [jobData, setJobData] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const ds =
    "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";

  

  const getJobData = async () => {
    const response = await getSinbgleGovtJob(id);
    if (response.status === 200) {
        console.log(response.data.details);
      setJobData(response.data.details);
    }
  };

  useEffect(() => {
    getJobData();
  }, []);

  let tl = jobData?.job_title
    ? jobData?.job_title + " | " + jobData?.job_utilities_govtorganization?.name
    : "Shomvob: Jobs & Trainings";
  let ds2 = jobData?.job_title
    ? "Apply for " +
      jobData?.job_title +
      " at " +
      jobData?.job_utilities_govtorganization?.name +
      " with Shomvob: Jobs & Trainings"
    : ds;

  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/single_job_description?id="></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
      <React.Fragment>
        <ToastContainer />

        <div
          className=" scroll-container container-fluid"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          <div className="rounded" name="Job Details">
            <div className="jd_section">
              <div className="top">
                <img
                  src={
                    "https://alljobs.teletalk.com.bd/media/" +
                    jobData?.job_utilities_govtorganization?.logo
                  }
                  alt="avatar"
                  style={{
                    maxHeight: "97px",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="top">
                <h1>{jobData?.job_title}</h1>
              </div>
              <div className="top">
                {jobData?.job_utilities_govtorganization?.name}
              </div>
            </div>

            <div className="jd_section">
              <div className="company_details">
                <div className="row">
                  <JdBasicInfoItem
                    title={"Advertisement No"}
                    value={jobData.advertisement_no}
                  />
                  <JdBasicInfoItem
                    title={"Vacancy"}
                    value={
                      jobData.vacancy
                        ? jobData.vacancy + " vacancy"
                        : "Not Declared"
                    }
                  />
                  <JdBasicInfoItem
                    title={"Application Start Date"}
                    value={new Date(
                      jobData.published_date
                    ).toLocaleDateString("en-GB")}
                  />
                  <JdBasicInfoItem
                    title={"Application Deadline"}
                    value={new Date(jobData.deadline_date).toLocaleDateString(
                      "en-GB"
                    )}
                  />
                  <JdBasicInfoItemDetails
                    title={"Organization Information"}
                    value={jobData?.job_utilities_govtorganization?.details}
                  />
                </div>
              </div>
            </div>
            <div className="jd_section mb-5">
              <iframe
                src={
                  "https://alljobs.teletalk.com.bd/media/" +
                  jobData.advertisement_file
                }
                title="PDF Preview"
                style={{
                  width: "100%",
                  height: "800px",
                }}
              />
              <hr />
              <div
                className="mt-5 d-flex justify-content-center"
                onClick={()=>{window.open(jobData.application_site);}}
              >
                <div className="apply_btn">
                  { "আবেদন করুন"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}

export default SingleGovtJob;
