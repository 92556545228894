import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { SingleCourseEdit } from "../../Components/User/SingleCourse";

const UserCourseModal = ({
  data,
  isVisible = false,
  onHide,
  courseOptions,
  updateCourseData,
}) => {
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    console.log(data);
    setTempData(data);
  }, []);

  const validation = () => {
    let isValid = true;
    const updateData = tempData.map((item) => {
      let tempItem = { ...item };
      if (!item?.course?.value || item?.course?.value <= 0) {
        isValid = false;
        tempItem.course = { ...tempItem.course, is_warning: true };
      } else {
        tempItem.course = { ...tempItem.course, is_warning: false };
      }

      if (
        item?.course?.value &&
        item?.course?.value === 385 &&
        item?.other_course?.value.length < 2
      ) {
        isValid = false;
        tempItem.other_course = { ...tempItem.other_course, is_warning: true };
      } else {
        tempItem.other_course = { ...tempItem.other_course, is_warning: false };
      }

      if (!item?.organization?.value || item?.organization?.value.length < 2) {
        isValid = false;
        tempItem.organization = { ...tempItem.organization, is_warning: true };
      } else {
        tempItem.organization = { ...tempItem.organization, is_warning: false };
      }

      return tempItem;
    });
    setTempData(updateData);
    return isValid;
  };

  const uploadInfo = () => {
    if (validation()) {
      const dt = tempData.map((item) => {
        return {
          course_id: item?.course?.value,
          course_name: item?.course?.label,
          organization: item?.organization?.value,
          other_course: item?.course?.value===385?item?.other_course?.value:null,
        };
      });

        updateCourseData(dt, tempData);
    } else {
      toast.error("সকল প্রয়োজনীয় তথ্য পুরুন করুন", {
        autoClose: 5000,
      });
    }
  };

  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title className="profile_section">
          <h1>আপনার কোর্স</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="company_details">
          {tempData.map((singleItem, index) => (
            <SingleCourseEdit
              key={index}
              data={singleItem}
              index={index}
              courseOptions={courseOptions}
              onRemoveClick={(removeIndex) => {
                const tt = tempData.filter((it, i) => i !== removeIndex);
                setTempData(tt);
              }}
              onCourseChange={(selectedOption, index) => {
                const tt = [...tempData];
                tt[index].course = {
                  value: selectedOption.id,
                  label: selectedOption.label,
                  is_warning: false,
                };
                setTempData(tt);
              }}
              onOtherCourseChange={(text, index) => {
                const tt = [...tempData];
                tt[index].other_course = {
                  value: text,
                  is_warning: false,
                };
                setTempData(tt);
              }}
              onOrganizationChange={(text, index) => {
                const tt = [...tempData];
                tt[index].organization = {
                  value: text,
                  is_warning: false,
                };
                setTempData(tt);
              }}
            />
          ))}

          <div className="p-3">
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                setTempData([
                  ...tempData,
                  {
                    course: {
                      value: 0,
                      label: "",
                      is_warning: false,
                    },
                    other_course: {
                      value: "",
                      is_warning: false,
                    },
                    organization: {
                      value: "",
                      is_warning: false,
                    },
                  },
                ]);
              }}
              style={{
                marginTop: "10px",
                color: "#007bff",
                fontSize: "14px",
              }}
            >
              আরও যোগ করুন +
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button
          onClick={() => {
            uploadInfo();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { UserCourseModal };
