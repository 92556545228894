import React, { useEffect, useState } from "react";
import { getGovtJobs } from "../../ApiCalls/JobPosts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import background_image from "../../img/background_image.jpg";
import {  GovtJobCard } from "../../Components/Company/CompanyCard";
import { Helmet } from "react-helmet-async";
import { Backdrop, CircularProgress } from "@mui/material";


const AllGovtJobs = () => {
    // const [companyData, setCompanyData] = useState([]);
    const [govtJobData, setGovtJobData] = useState([]);
    const [open, setOpen] = useState(false);
    const getGovtJobData = async () => {
        const response = await getGovtJobs();
        if (response.status === 200) {
            setGovtJobData(response.data);
        }
      setOpen(false);
    }
  
  useEffect(() => {
    setOpen(true);
        getGovtJobData();
    }, []);
   const tl = "Govt. Jobs | Shomvob: Jobs & Trainings";
   const ds2 =
     "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";

  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/cvmaker"></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
      <React.Fragment>
        <ToastContainer />
        <div
          className=" scroll-container container-fluid"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          {/* <div
            name="headline"
            className="container-fluid d-flex justify-content-center my-5"
          >
            <img
              src={headline}
              style={{
                objectFit: "cover",
                maxHeight: "67px",
                maxWidth: "100%",
              }}
              alt="headline"
            />
          </div>

          <hr className="my-5"></hr> */}
          <div className="container-fluid">
            <div className="company_job_opening mt-3">
              <div className="all_company_header mb-5">
                <h1>সকল সরকারি চাকরি</h1>
              </div>

              <div className="row g-4">
                {govtJobData.map((singleItem, index) => (
                  <GovtJobCard govtJob={singleItem} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    </>
  );
};

export default AllGovtJobs;;