import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { Form, Card, Col, Row, Button } from "react-bootstrap";
import welcomeImg from "../../img/welcome.png";
import otpImg from "../../img/otp.png";
import OtpInput from "react18-input-otp";
import config from "../../config.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import app from "../../firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import background_image from "../../img/background_image.jpg";
import { GroupInput, GroupInput2 } from "../../Components/Public/InputField";

function SignUp() {
  const analytics = getAnalytics(app);

  const [phone, setPhone] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [otp, setOtp] = useState("");

  const [isOtpClicked, setOtpClicked] = useState(false);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const [countdown, setCountdown] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const redirectToNext = (data) => {
    const accessToken = data.session.access_token;
    const refreshToken = data.session.refresh_token;
    const expireIn = data.session.expires_in;

    localStorage.setItem("refreshToken", refreshToken);
    const currentTime = Date.now();
    localStorage.setItem("expireTime", currentTime + expireIn * 1000);

    window.location =
      "/redirect?embed=true#access_token=" +
      accessToken +
      "&is_new=" +
      data.is_new;
  };

  const handleSubmit = (event) => {
   

    const otpMatch = async () => {
      try {
        const response = await axios.post(
          config.firebaseEndpoint + "/otp/validate",
          {
            phone: "88" + phone,
            otp: otp,
          },
          {
            headers: {
              "User-Agent": "Dalvik/2.1.0",
              Authorization: config.firebaseToken,
            },
          }
        );

        if (response.status === 200) {
          logEvent(analytics, "successfully_otp_matched", {
            platfrom: "web",
            phone: "88" + phone,
          });

          try {
            redirectToNext(response.data);
          } catch (er) {
            console.error(er);
          }
        } else {
          logEvent(analytics, "otp_matche_failed", {
            platfrom: "web",
            phone: "88" + phone,
          });
          toast.error("সঠিক ও টি পি টি প্রদান করুন।", {
            autoClose: 5000,
          });
        }
      } catch (error) {
        logEvent(analytics, "otp_matche_failed", {
          platfrom: "web",
          phone: "88" + phone,
        });
        toast.error("সঠিক ও টি পি টি প্রদান করুন।", {
          autoClose: 5000,
        });

        if (error.response) {
          console.error(error.response.status);
        }
      }
    };

    otpMatch();
  };
  let interval;
  const sendOTP = () => {
    setIsDisabled(true);
    const getOtp = async () => {
      try {
        const response = await axios.post(
          config.firebaseEndpoint + "/otp/phone?is_retry=0",
          {
            phone: "88" + phone,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: config.firebaseToken,
            },
          }
        );

        if (response.status === 200) {
          logEvent(analytics, "successfully_otp_send", {
            platfrom: "web",
            phone: "88" + phone,
          });
          toast.success("ও টি পি পাঠানো সফল হয়েছে।", {
            autoClose: 5000,
          });
          const dc = response.data.countdown;
          setOtpClicked(true);

			setCountdown(dc);
			

			interval = setInterval(() => {
			  console.log(countdown);
            setCountdown((prevCountdown) => {
              if (prevCountdown <= 1) {
                clearInterval(interval);
                setIsDisabled(false);
              }
              return prevCountdown - 1;
            });
          }, 1000);
        } else {
          setIsDisabled(false);
          logEvent(analytics, "otp_send_failed", {
            platfrom: "web",
            phone: "88" + phone,
          });
          toast.error(
            "কিছু সমস্যার কারণে ও টি পি পাঠানো ব্যার্থ হয়েছে। কিছুক্ষন পরে আবার চেষ্টা করুন।",
            {
              autoClose: 5000,
            }
          );
        }
      } catch (error) {
        logEvent(analytics, "otp_send_failed", {
          platfrom: "web",
          phone: "88" + phone,
        });
        setIsDisabled(false);
        toast.error(
          "কিছু সমস্যার কারণে ও টি পি পাঠানো ব্যার্থ হয়েছে। কিছুক্ষন পরে আবার চেষ্টা করুন।",
          {
            autoClose: 5000,
          }
        );
        console.error(error);
      }
    };
    getOtp();
  };

  const isNextDisabled = !(otp.length === 4 && isChecked);

  useEffect(() => {
    setIsDisabled(!(phone.length === 11 && countdown === 0));
  }, [phone, countdown]);

  return (
    <React.Fragment>
      <ToastContainer />
      <div
        className=" scroll-container container-fluid d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: "100% auto",
          backgroundRepeat: "repeat-y",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <div className="signup-card rounded-5 col-xxl-5 col-xl-5 col-lg-6 col-md-12 col-sm-12">
          <div>
            <Link classNameName="navbar-brand" to="/">
              <img
                classNameName="light-mode-item navbar-brand-item "
                src={welcomeImg}
                alt="logo"
                style={{
                  height: "24px",
                  objectFit: "cover",
                }}
              />
            </Link>
            <div className="signup-card-title mt-2">
              <h2>সাইন ইন / রেজিস্ট্রেশন করুন</h2>
            </div>
            <div className="row align-items-end">
              <GroupInput2
                startWith={"+88"}
                label={"আপনার মোবাইল নম্বরটি লিখুন"}
                sizes={{ xxl: 8, xl: 8, lg: 8, md: 8, sm: 8 }}
                ph={"01XXXXXXXXX"}
                data={phone}
                onChange={(e) => {
                  setPhone(e);
                  setCountdown(0);
                }}
              />
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-5 mb-3">
                <div
                  className={
                    isDisabled ? "send-otp-btn-disable" : "send-otp-btn"
                  }
                  onClick={!isDisabled && sendOTP}
                >
                  {countdown > 0 ? `${countdown} sec` : "ও টি পি পাঠান"}
                </div>
              </div>
            </div>
          </div>

          {isOtpClicked && (
            <>
              <hr className="my-4" />

              <div>
                <Link classNameName="navbar-brand" to="/">
                  <img
                    classNameName="light-mode-item navbar-brand-item "
                    src={otpImg}
                    alt="logo"
                    style={{
                      height: "35px",
                      objectFit: "cover",
                    }}
                  />
                </Link>
                <div className="signup-card-title mt-2">
                  <h2>
                    আপনার মোবাইল নম্বরটি যাচাই করতে চার সংখার ও টি পি লিখুন
                  </h2>
                </div>
                <Row>
                  <div className="d-flex justify-content-center my-4">
                    <OtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={4}
                      separator={<span></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        width: "40px",
                        height: "40px",
                        textAlign: "center",
                        fontSize: "16px",
                        margin: "0 1.5rem",
                        border: "1px solid #5F5F5F",
                        borderRadius: "4px",
                      }}
                      inputContainerStyle={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "1rem 0",
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="terms_and_condition"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        for="terms_and_condition"
                      >
                        আপনি কি আমাদের{" "}
                        <a
                          href="https://shomvob.co/privacy-policy/"
                          className="term-service"
                        >
                          নিয়ম ও শর্তসমূহ
                        </a>{" "}
                        পড়েছেন?
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div
                      className={`${
                        isNextDisabled ? "send-otp-btn-disable" : "send-otp-btn"
                      } mt-4 col-xxl-5 col-xl-5 col-lg-6 col-md-12 col-sm-12`}
                      typ
                      onClick={() => {
                        if (otp.length < 4) {
                          toast.error("ও টি পি সঠিকভাবে প্রদান করুন", {
                            autoClose: 5000,
                          });
                        } else if (!isChecked) {
                          toast.error("নিয়ম ও শর্তসমূহ পড়েছেন, চিহ্নিত করুন", {
                            autoClose: 5000,
                          });
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      এরপর
                    </div>
                  </div>
                </Row>
              </div>
            </>
          )}
        </div>

        {/* <div className="px-lg-4 col-lg-5 col-sm-8 col-md-8 my-5">
          <div className="card">
            <div className="p-lg-5 card-body">
              <Link classNameName="navbar-brand" to="/">
                <img
                  classNameName="light-mode-item navbar-brand-item "
                  src={welcomeImg}
                  alt="logo"
                  style={{
                    height: "24px",
                    objectFit: "cover",
                  }}
                />
              </Link>
              <h2
                classNameName="mb-1 fw-bold"
                style={{
                  marginTop: "12px",
                  marginBottom: "20px",
                  color: "#6A6A6A",
                  fontFamily: "Noto Sans Bengali",
                  fontSize: "16px",
                }}
              >
                সাইন ইন / রেজিস্ট্রেশন করুন
              </h2>

              <Col xs={12} className="my-3">
                <Card
                  className=" border-0 "
                  style={{
                    width: "100%",
                  }}
                >
                  <Card.Body>
                    <Row>
                      <Form.Group className="mb-1" as={Col} xs={12}>
                        <Form.Label>আপনার মোবাইল নম্বরটি লিখুন</Form.Label>
                      </Form.Group>
                      <Form.Group
                        className="cv_maker_form_group"
                        as={Col}
                        xs={2}
                        sm={2}
                      >
                        <Form.Control
                          type="text"
                          value={"+88"}
                          style={{
                            maxWidth: "100%",
                            minWidth: "55px",
                            background: "#F2F9FF",
                            textAlign: "center",
                            paddingLeft: "10",
                          }}
                          disabled="true"
                        />
                      </Form.Group>
                      <Form.Group
                        className="cv_maker_form_group"
                        as={Col}
                        xs={6}
                      >
                        <Form.Control
                          type="text"
                          value={phone}
                          placeholder="01XXXXXXXXX"
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setCountdown(0);
                          }}
                          style={{
                            maxWidth: "250px",
                            background: "#F2F9FF",
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        className="cv_maker_form_group"
                        as={Col}
                        xs={6}
                        sm={4}
                      >
                        <Button
                          style={{
                            height: "100%",
                            whiteSpace: "nowrap",
                          }}
                          disabled={isDisabled}
                          onClick={sendOTP}
                          id="send_otp"
                        >
                          {countdown > 0 ? `${countdown} sec` : "ও টি পি পাঠান"}
                        </Button>
                      </Form.Group>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {isOtpClicked && (
                <>
                  <hr className="my-4" />

                  <Link classNameName="navbar-brand mt-5" to="/">
                    <img
                      classNameName="light-mode-item navbar-brand-item "
                      src={otpImg}
                      alt="logo"
                      style={{
                        height: "35px",
                        objectFit: "cover",
                      }}
                    />
                  </Link>
                  <h2
                    classNameName="mb-1 fw-bold"
                    style={{
                      marginTop: "12px",
                      marginBottom: "20px",
                      color: "#6A6A6A",
                      fontFamily: "Noto Sans Bengali",
                      fontSize: "16px",
                    }}
                  >
                    আপনার মোবাইল নম্বরটি যাচাই করতে চার সংখার ও টি পি লিখুন
                  </h2>

                  <Col xs={12} className="my-3">
                    <Card
                      className=" border-0 "
                      style={{
                        width: "100%",
                      }}
                    >
                      <Card.Body>
                        <Row>
                          <form onSubmit={handleSubmit}>
                            <div
                              className="rowjustify-content-center"
                              style={{
                                marginBottom: "30px",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              <OtpInput
                                value={otp}
                                onChange={handleOtpChange}
                                numInputs={4}
                                separator={<span></span>}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  width: "40px",
                                  height: "40px",
                                  textAlign: "center",
                                  fontSize: "16px",
                                  margin: "0 1.5rem",
                                  border: "1px solid #5F5F5F",
                                  borderRadius: "4px",
                                }}
                                inputContainerStyle={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "1rem 0",
                                }}
                              />
                            </div>

                            <div className="col-12">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="terms_and_condition"
                                  checked={isChecked}
                                  onChange={(e) =>
                                    setIsChecked(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  for="terms_and_condition"
                                >
                                  আপনি কি আমাদের{" "}
                                  <a
                                    href="https://shomvob.co/privacy-policy/"
                                    className="term-service"
                                  >
                                    নিয়ম ও শর্তসমূহ
                                  </a>{" "}
                                  পড়েছেন?
                                </label>
                              </div>
                            </div>
                            <div className="d-grid">
                              <Button
                                className="fw-bold"
                                style={{
                                  marginTop: "30px",
                                }}
                                disabled={isNextDisabled}
                                type="submit"
                              >
                                এরপর
                              </Button>
                            </div>
                          </form>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}

export default SignUp;
