import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  DateInput2,
  ModalDateInput,
  ModalSelect,
  NormalInput2,
} from "../../Components/Public/InputField";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";

const PersonalInfoEditModal = ({
  data,
  isVisible = false,
  onHide,
  updatePersonalInfoData,
}) => {
  const [tempData, setTempData] = useState({});
  const gendersOptions = [
    {
      label: "পুরুষ",
      value: "Male",
    },
    {
      label: "মহিলা",
      value: "Female",
    },
    {
      label: "অন্যান্য",
      value: "Other",
    },
  ];
  useEffect(() => {
    setTempData(data);
  }, []);

  const validation = () => {
    let isValid = true;
    let updatedData = tempData;

    if (tempData.full_name.value === null || tempData.full_name.value <= 2) {
      updatedData = {
        ...updatedData,
        full_name: {
          ...updatedData?.full_name,
          is_warning: true,
        },
      };

      isValid = false;
    } else {
      updatedData = {
        ...updatedData,
        full_name: {
          ...updatedData?.full_name,
          is_warning: false,
        },
      };
    }

    if (
      tempData.date_of_birth.value === null ||
      tempData.date_of_birth.value <= 2
    ) {
      updatedData = {
        ...updatedData,
        date_of_birth: {
          ...updatedData?.date_of_birth,
          is_warning: true,
        },
      };

      isValid = false;
    } else {
      updatedData = {
        ...updatedData,
        date_of_birth: {
          ...updatedData?.date_of_birth,
          is_warning: false,
        },
      };
    }

    if (tempData.email.value === null || tempData.email.value <= 2) {
      updatedData = {
        ...updatedData,
        email: {
          ...updatedData?.email,
          is_warning: true,
        },
      };

      isValid = false;
    } else {
      updatedData = {
        ...updatedData,
        email: {
          ...updatedData?.email,
          is_warning: false,
        },
      };
    }
    if (!tempData.gender.value) {
      updatedData = {
        ...updatedData,
        gender: {
          ...updatedData?.gender,
          is_warning: true,
        },
      };

      isValid = false;
    } else {
      updatedData = {
        ...updatedData,
        gender: {
          ...updatedData?.gender,
          is_warning: false,
        },
      };
    }

    setTempData(updatedData);

    return isValid;
  };

  const uploadInfo = () => {
    if (validation()) {
      const dt = {
        full_name: tempData.full_name.value,
        date_of_birth: dayjs(tempData.date_of_birth.value, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        gender: tempData.gender.label,
        email: tempData.email.value,
        current_address: tempData.current_address.value,
      };
        updatePersonalInfoData(dt, tempData);
    } else {
      toast.error("সকল প্রয়োজনীয় তথ্য পুরুন করুন", {
        autoClose: 5000,
      });
    }
  };
  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title className="profile_section">
          <h1>ব্যাক্তিগত তথ্য</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="company_details">
          <div className="row">
            <NormalInput2
              label={"পুরো নাম"}
              isLabel={true}
              isWarning={tempData?.full_name?.is_warning}
              warningText={"নামের ঘরটি পূরণ করতে হবে।"}
              ph={"আপনার নাম লিখুন"}
              id={"user_name"}
              name={"user_name"}
              data={tempData?.full_name?.value || ""}
              type={"text"}
              onChange={(e) => {
                setTempData({
                  ...tempData,
                  full_name: {
                    ...tempData?.full_name,
                    value: e,
                    is_warning: false,
                  },
                });
              }}
            />
            <NormalInput2
              label={"মোবাইল নম্বর"}
              isLabel={true}
              isWarning={tempData?.phone?.is_warning}
              warningText={"মোবাইল নম্বর ঘরটি পূরণ করতে হবে।"}
              ph={"আপনার মোবাইল নম্বর লিখুন"}
              id={"phone_number"}
              name={"phone_number"}
              data={"+" + tempData?.phone?.value || ""}
              type={"text"}
              isDisable={true}
              onChange={(e) => {
                setTempData({
                  ...tempData,
                  phone: {
                    ...tempData?.phone,
                    value: e,
                    is_warning: false,
                  },
                });
              }}
            />
            <ModalSelect
              label={"লিঙ্গ"}
              isLabel={true}
              data={tempData?.gender || {}}
              options={gendersOptions}
              ph={"লিঙ্গ বাছাই করুন।"}
              isWarning={tempData?.division?.is_warning}
              warningText={"একটি লিঙ্গ বাছাই করতে হবে।"}
              onChange={(selectedOption) => {
                setTempData({
                  ...tempData,
                  gender: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    is_warning: false,
                  },
                });
              }}
              onMenuOpen={() => {}}
            />

            <ModalDateInput
              label={"জন্ম তারিখ (দিন/মাস/বছর)"}
              warningText={"জন্ম তারিখ প্রদান করতে হবে"}
              isWarning={tempData?.date_of_birth?.is_warning}
              ph={"দিন/মাস/বছর"}
              date={tempData?.date_of_birth?.value}
              onChange={(dt) => {
                setTempData({
                  ...tempData,
                  date_of_birth: {
                    ...tempData?.date_of_birth,
                    value: dt,
                    is_warning: false,
                  },
                });
              }}
            />

            <NormalInput2
              label={"ই-মেইল"}
              isLabel={true}
              isWarning={tempData?.email?.is_warning}
              warningText={"ই-মেইল ঘরটি পূরণ করতে হবে।"}
              ph={"আপনার ই-মেইল লিখুন"}
              id={"email"}
              name={"email"}
              data={tempData?.email?.value || ""}
              type={"text"}
              onChange={(e) => {
                setTempData({
                  ...tempData,
                  email: {
                    ...tempData?.email,
                    value: e,
                    is_warning: false,
                  },
                });
              }}
            />
            <NormalInput2
              label={"ঠিকানা"}
              isLabel={true}
              isWarning={tempData?.current_address?.is_warning}
              warningText={"ঠিকানার ঘরটি পূরণ করতে হবে।"}
              ph={"আপনার ঠিকানা লিখুন"}
              id={"address"}
              name={"address"}
              data={tempData?.current_address?.value || ""}
              type={"text"}
              onChange={(e) => {
                setTempData({
                  ...tempData,
                  current_address: {
                    ...tempData?.current_address,
                    value: e,
                    is_warning: false,
                  },
                });
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button
          onClick={() => {
            uploadInfo();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { PersonalInfoEditModal };
