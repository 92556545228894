import { postRequest, getRequest } from "./MakeCall"

const getPublicData = async (requestData) => {
    try {
        const url = process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/public_data_list_with_topic_name";
        const accessToken = localStorage.getItem("accessToken") && "Bearer " + localStorage.getItem("accessToken");
        const response = await postRequest(url, requestData, accessToken);

        return response;
    } catch (error) {
        console.error(error);
    }
};

const getJobStatus = async () => {
    try {
        const url = process.env.REACT_APP_SUPABASE_URL + "/rest/v1/job_statues";
        const accessToken = "Bearer " +
            localStorage.getItem(
                "accessToken"
            );
        const response = await getRequest(url, accessToken);

        return response;
    } catch (error) {
        console.error(error);
    }
};

const getSideBarItems = async () => {
    try {
        const url = process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/get_feature_cart_items";
        const accessToken = localStorage.getItem("accessToken") && "Bearer " + localStorage.getItem("accessToken");
        const response = await getRequest(url, accessToken);

        return response;
    } catch (error) {
        console.error(error);
    }
};


export { getPublicData, getJobStatus, getSideBarItems };