import React from "react";
import { etob } from "../../NumberConvert";
import { JdBasicInfoItem } from "../JobPosts/JobDescription";
import { ModalSelect, NormalInput2 } from "../Public/InputField";
import { Button } from "react-bootstrap";

const SingleCourse = ({ data, index }) => {
  return (
    <div className="p-3">
      <h2>কোর্স - {etob(index)}</h2>

      <div className="row">
        <JdBasicInfoItem
          title={"কোর্সের নাম"}
          value={
            data?.course?.label === "Other"
              ? data?.other_course?.value
              : data?.course?.label
          }
        />
        <JdBasicInfoItem
          title={"প্রতিষ্ঠান"}
          value={data?.organization?.value}
        />
      </div>
    </div>
  );
};

const SingleCourseEdit = ({
  data,
  index,
  courseOptions,
  onCourseChange,
  onOtherCourseChange,
  onOrganizationChange,
  onRemoveClick,
}) => {
  return (
    <div className="p-3">
      <div className="profile_section d-flex justify-content-between align-items-center mb-3">
        <h2>কোর্স - {etob(index + 1)}</h2>
        {index > 0 && (
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              onRemoveClick(index);
            }}
            style={{
              color: "#B50D0D",
            }}
          >
            রিমুভ করুন -
          </Button>
        )}
      </div>

      <div className="row">
        <ModalSelect
          label={"কোর্সের নাম"}
          isLabel={true}
          data={data?.course || {}}
          options={courseOptions}
          ph={"কোর্সের নাম বাছাই করুন"}
          isWarning={data?.course?.is_warning}
          warningText={"একটি কোর্সের নাম বাছাই করতে হবে।"}
          onChange={(selectedOption) => {
            onCourseChange(selectedOption, index);
          }}
          onMenuOpen={() => {}}
        />

        {data?.course?.label === "Other" && (
          <NormalInput2
            label={"অন্য কোর্সে নাম"}
            isLabel={true}
            isWarning={data?.other_course?.is_warning}
            warningText={"অন্য কোর্সে নাম পূরণ করতে হবে।"}
            ph={"অন্য কোর্সে নাম নাম লিখুন"}
            id={"other_course_name"}
            name={"other_course_name"}
            data={data?.other_course?.value || ""}
            type={"text"}
            onChange={(e) => {
              onOtherCourseChange(e, index);
            }}
          />
        )}

        <NormalInput2
          label={"প্রতিষ্ঠান"}
          isLabel={true}
          isWarning={data?.organization?.is_warning}
          warningText={"প্রতিষ্ঠানের নাম পূরণ করতে হবে।"}
          ph={"আপনার প্রতিষ্ঠানের নাম লিখুন"}
          id={"institute_name"}
          name={"institute_name"}
          data={data?.organization?.value || ""}
          type={"text"}
          onChange={(e) => {
            onOrganizationChange(e, index);
          }}
        />
      </div>
    </div>
  );
};

export { SingleCourse, SingleCourseEdit };
