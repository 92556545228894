import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import background_image from "../../img/background_image.jpg";
import headline from "../../img/company_profiles_headline.png";
import { getActiveCompanys } from "../../ApiCalls/Company";
import { CompanyCard } from "../../Components/Company/CompanyCard";
import {
  FilterSelect,
  SearchInput,
} from "../../Components/Company/FilterInputs";
import { Helmet } from "react-helmet-async";

const AllCompany = () => {
  const [companyData, setCompanyData] = useState([]);
  const [backUpCompanyData, setBackUpCompanyData] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [filteredIndustry, setFilteredIndustry] = useState([]);
  const [searchText, setSearchText] = useState("");

  const extractIndustryOptions = (data) => {
    const tempSetData = new Set(data.map((item) => item.industry));
    setIndustryOptions(
      [...tempSetData].map((item) => ({
        value: item,
        label: item,
      }))
    );
  };
  const getCommpanyData = async () => {
    const response = await getActiveCompanys();
    if (response?.status === 200) {
      setCompanyData(response.data);
      setBackUpCompanyData(response.data);
      extractIndustryOptions(response.data);
    }
  };
  useEffect(() => {
    getCommpanyData();
  }, []);

  useEffect(() => {
    let tempCompanyData = backUpCompanyData;

    if (searchText.length > 0) {
        tempCompanyData = tempCompanyData.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.industry && item.industry.toLowerCase().includes(searchText.toLowerCase()))
      );
    }

    if (filteredIndustry.length > 0) {
      const industryFilterValues = filteredIndustry.map((item) => {
        return item.value;
      });

      tempCompanyData = tempCompanyData.filter((item) => {
        return industryFilterValues.includes(item.industry);
      });
    }
    setCompanyData(tempCompanyData);
  }, [searchText, filteredIndustry, backUpCompanyData]);
   const tl = "Shomvob: Jobs & Trainings | Company Profiles";
   const ds2 =
     "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";

  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/cvmaker"></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
      <React.Fragment>
        <ToastContainer />
        <div
          className=" scroll-container container-fluid"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          <div
            name="headline"
            className="container-fluid d-flex justify-content-center my-5"
          >
            <img
              src={headline}
              style={{
                objectFit: "cover",
                maxHeight: "67px",
                maxWidth: "100%",
              }}
              alt="headline"
            />
          </div>
          <div className="container-fluid">
            <div className="company_filter_section  row">
              <SearchInput
                type={"text"}
                data={searchText}
                onSearchTextChange={(e) => {
                  setSearchText(e);
                }}
                id={"search"}
                name={"search_field"}
                ph={"কোম্পানি ফিল্টার করুন"}
              />
              <div
                className="divider-container col"
                style={{ maxWidth: ".5px", height: "47px" }}
              >
                <div
                  style={{
                    background: "#D6DDEB",
                    width: "1px",
                    height: "47px",
                  }}
                ></div>
              </div>

              <FilterSelect
                options={industryOptions}
                ph={"ইন্ডাস্ট্রি দ্বারা কোম্পানি ফিল্টার করুন"}
                data={filteredIndustry || {}}
                name={"industry"}
                isLabel={false}
                onMenuOpen={() => {}}
                onChange={(selectedOptions) => {
                  setFilteredIndustry(selectedOptions);
                }}
                multiSelect={true}
              />
            </div>
          </div>

          <hr className="my-5"></hr>
          <div className="container-fluid">
            <div className="company_job_opening mt-3">
              <div className="all_company_header mb-5">
                <h1>সকল কোম্পানি</h1>
                <p>
                  আপনার প্রোফাইল এবং সাম্প্রতিক কার্যকলাপের উপর ভিত্তি করে
                  কোম্পানিসমুহ দেখানো হয়েছে
                </p>
              </div>

              <div className="row g-4">
                {companyData.map((singleCompany, index) => (
                  <CompanyCard companyData={singleCompany} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default AllCompany;
