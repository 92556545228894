import React, { useState, useEffect } from "react";
import background_image from "../img/background_image.jpg";
import { getAppliedJobs } from "../ApiCalls/JobPosts";
import SingleJobCard, {
  SingleAppliedJobTopCard,
} from "../Components/JobPosts/JobCards";

import ApplicationTrackerModal from "../Modals/ApplicationTracker";
import SideBar from "../Components/SideBar";
import { etob } from "../NumberConvert";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { FeatureFeedbackModal } from "../Modals/User/Feedback";

const AppliedJobs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [topCards, setTopcards] = useState([
    {
      id: 1,
      title: "আবেদনকৃত চাকরি",
      toolTip: "আপনার আবেদন করা চাকরিগুলো দেখুন ",
      value: 0,
    },
    {
      id: 2,
      title: "শর্টলিস্টেড হয়েছে",
      toolTip: "আপনি যেসব চাকরিতে শর্টলিস্টেড হয়েছেন ",
      value: 0,
    },
    {
      id: 3,
      title: "ইন্টারভিউতে ডাক পেয়েছে",
      toolTip: "আপনি যেসব চাকরির জন্য ইন্টারভিউতে ডাক পেয়েছেন",
      value: 0,
    },
    {
      id: 4,
      title: "চাকরি নিশ্চিত হয়েছে",
      toolTip: "",
      value: 0,
    },
  ]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [appliedJobData, setAppliedJobData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [isFeedback, setIsFeedback] = useState(false);
  

  const processAppliedJobData = (data) => {
    const reversedData = data.reverse();
    const tempTopCards = [...topCards];
    const shortlistedJobsCount = reversedData.filter(
      (job) => job.status === "3" || job.status === "7"
    ).length;
    tempTopCards[1].value = shortlistedJobsCount;

    const interviewCalledJobsCount = reversedData.filter(
      (job) => job.status === "4"
    ).length;
    tempTopCards[2].value = interviewCalledJobsCount;

    const confirmedJobsCount = reversedData.filter(
      (job) => job.status === "5"
    ).length;

    tempTopCards[3].value = confirmedJobsCount;
    tempTopCards[0].value = reversedData.length;

    setTopcards(tempTopCards);
    setAppliedJobData(reversedData);
    setDisplayData(reversedData);
  };

  const getAppliedJobData = async () => {
    const body = {
      user_id_data: localStorage.getItem("userId"),
    };
    const response = await getAppliedJobs(body);
    if (response.status === 200) {
      processAppliedJobData(response.data);
    }
  };

  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
    let newData;
    switch (index) {
      case 0:
        newData = appliedJobData;
        break;
      case 1:
        newData = appliedJobData.filter(
          (job) => job.status === "3" || job.status === "7"
        );
        break;
      case 2:
        newData = appliedJobData.filter((job) => job.status === "4");
        break;
      case 3:
        newData = appliedJobData.filter((job) => job.status === "5");
        break;
      default:
        newData = [];
        break;
    }
    setDisplayData(newData);
  };

  const tj = (jobPost) => {
    const tempJobPost = {
      id: jobPost.id,
      title: jobPost.job_title,
      companyName: jobPost.company_name,
      companyLogo: jobPost.company_logo,
      companyProfileId: jobPost.company_profile_id,
      jobLocation: jobPost.job_locations,
      deadline: jobPost.application_deadline,
      salaryRange: jobPost.salary_range,
      isSave: localStorage.getItem("userId") ? jobPost.saved_job_status : null,
    };

    return tempJobPost;
  };

  useEffect(() => {
    setIsFeedback(location?.state?.isJobApplyFeedback ?? false);
    getAppliedJobData();
    
  }, []);
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    navigate("/application_tracker", {
      state: {
        ...location?.state,
        isJobApplyFeedback: false,
      },
    });
  };
 const tl = "Applied Jobs | Shomvob: Jobs & Trainings";
 const ds2 =
   "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";

  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/cvmaker"></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
      <React.Fragment>
        <div
          className="saved_jobs container-fluid scroll-container"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",

            overflowX: "hidden",
          }}
        >
          <div>
            <div className="pt-5">
              <div className="row px-5">
                {topCards.map((card, index) => (
                  <SingleAppliedJobTopCard
                    item={card}
                    isSelected={selectedCardIndex === index}
                    index={index}
                    onClick={(si) => {
                      handleCardClick(si);
                    }}
                  />
                ))}
              </div>
              <hr />
              <div className="px-5">
                <h1>
                  {topCards[selectedCardIndex].title} -{" "}
                  {etob(topCards[selectedCardIndex].value)}
                </h1>
              </div>

              <hr />
            </div>

            <div className="mt-4 mx-5">
              <div className="row g-4">
                {displayData.map((jobPost, index) => (
                  <>
                    <SingleJobCard
                      jobPost={tj(jobPost)}
                      mark={3}
                      onClick={() => {}}
                    />
                  </>
                ))}
              </div>
            </div>
          </div>

          <ApplicationTrackerModal isModalShow={false} />
        </div>
        <FeatureFeedbackModal
          isVisible={isFeedback}
          featureName={"job_apply_web"}
          title={"সম্ভব অ্যাপের মাধ্যমে চাকরিতে আবেদন করার অভিজ্ঞতাকে আপনি কীভাবে মূল্যায়ন করবেন?"}
          hide={()=>{setIsFeedback(false)}}
        />
      </React.Fragment>
    </>
  );
};

export default AppliedJobs;
