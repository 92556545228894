import React, { useState, useEffect } from "react";
import { Form, Card, Col, Row, Container, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {useNavigate } from "react-router-dom";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import config from "./config.json";
import app from "./firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";

function ProfileCreate1() {
  const analytics = getAnalytics(app);
  const navigate = useNavigate();
  const [photo, setPhoto] = useState(null);
  const [name, setName] = useState("");
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("");
  const [age, setAge] = useState(null);

  const userId = localStorage.getItem("userId");

  const [districts, setDistricts] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [educations, setEducations] = useState([]);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [universitys, setUniversitys] = useState([]);

  const [selectedDivisionId, setSelectedDivisionId] = useState(0);
  const [selectedAreaId, setSelectedAreaId] = useState(0);
  const [selectedEducationId, setSelectedEducationId] = useState(0);
  const [selectedUniversityId, setSelectedUniversityId] = useState(0);
  const [selectedExpId, setSelectedExpId] = useState(0);
  const [selectedJobTypeId, setSelectedJobTypeId] = useState(0);

  const [isUniversityMandatory, setIsUniversityMandatory] = useState(false);

  const [showAgeWarning, setShowAgeWarning] = useState(false);
  const [showNameWarning, setShowNameWarning] = useState(false);
  const [showEducationWarning, setShowEducationWarning] = useState(false);
  const [showUniversityWarning, setShowUniversityWarning] = useState(false);
  const [showDivisionWarning, setShowDivisionWarning] = useState(false);
  const [showAreaWarning, setShowAreaWarning] = useState(false);
  const [showWorkExpWarning, setShowWorkExpWarning] = useState(false);
  const [showWorkTypeWarning, setShowWorkTypeWarning] = useState(false);

  const getSelectedOptionId = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    const selectedId = selectedOption.getAttribute("id");
    return parseInt(selectedId);
  };

  useEffect(() => {
    if (localStorage.getItem("publicTableData")) {
      const publicStringData = localStorage.getItem("publicTableData");
      const publicData = JSON.parse(publicStringData);
      setDistricts(publicData.district);
      setDivisions(publicData.division);
      setEducations(publicData.education);
      setWorkExperiences(publicData.work_experience);
      setJobTypes(publicData.job_type);
      setUniversitys(publicData.university_list);
    }
  }, []);

  const tooltip = (
    <Tooltip id="tooltip-disabled">Please fill out all required fields</Tooltip>
  );

  const calculateAge = (birthday) => {
    const today = new Date();
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const isNextDisabled =
    name &&
    dob &&
    age >= 15 &&
    gender &&
    selectedDivisionId &&
    selectedExpId &&
    selectedAreaId &&
    selectedEducationId &&
    selectedJobTypeId &&
    ((isUniversityMandatory && selectedUniversityId) || !isUniversityMandatory);

  const handleChangePhoto = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleEducationChange = (event) => {
    const id = getSelectedOptionId(event);
    setSelectedEducationId(id);
    setShowEducationWarning(!id);
    const selectedEducation = educations.find((edu) => edu.id === id);
    setIsUniversityMandatory(selectedEducation.is_university_required);
  };

  const handleUniversityChange = (event) => {
    setSelectedUniversityId(getSelectedOptionId(event));
    setShowUniversityWarning(!selectedUniversityId);
  };

  const handleDivisionChange = (event) => {
    const id = getSelectedOptionId(event);
    setSelectedDivisionId(id);
    setShowDivisionWarning(!id);
  };

  const handleAreaChange = (event) => {
    const id = getSelectedOptionId(event);
    setSelectedAreaId(id);
    setShowAreaWarning(!id);
  };

  const handleWorkExpChange = (event) => {
    const id = getSelectedOptionId(event);
    setSelectedExpId(id);
    setShowWorkExpWarning(!id);
  };

  const handleJobTypeChange = (event) => {
    const id = getSelectedOptionId(event);
    setSelectedJobTypeId(id);
    setShowWorkTypeWarning(!id);
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleNameBlur = () => {
    if (name === "") {
      setShowNameWarning(true);
    } else {
      setShowNameWarning(false);
    }
  };
  const handleAgeBlur = () => {
    if (dob === "") {
      setShowAgeWarning(true);
    } else {
      setShowAgeWarning(false);
    }
  };

  const handleEducationBlur = () => {
    if (selectedEducationId > 0) {
      setShowNameWarning(false);
    } else {
      setShowNameWarning(true);
    }
  };
  const handleUniversityBlur = () => {
    if (selectedUniversityId > 0) {
      setShowNameWarning(false);
    } else {
      setShowNameWarning(true);
    }
  };

  const handleDivisionBlur = () => {
    if (selectedDivisionId > 0) {
      setShowNameWarning(false);
    } else {
      setShowNameWarning(true);
    }
  };
  const handleAreaBlur = () => {
    if (selectedAreaId > 0) {
      setShowNameWarning(false);
    } else {
      setShowNameWarning(true);
    }
  };

  const handleChangeDob = (date) => {
    setDob(date);
    const age1 = calculateAge(date);
    setShowAgeWarning(age1 < 15);
    setAge(age1);
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const getEducationName = (eduId) => {
    const selectedEducation = educations.find((edu) => edu.id === eduId);
    return selectedEducation ? selectedEducation.education : "";
  };

  const updateUserInfo = async () => {
    try {
      await axios.post({
        method: "patch",
        url:
          "https://iuazegsorvopdfkveycu.supabase.co/rest/v1/user_profile?user_id=eq." +
          userId,
        data: {
          full_name: name,
          gender: gender,
          date_of_birth: dob,
          education: selectedEducationId,
          university_id: selectedUniversityId,
          district: selectedAreaId,
          division: selectedDivisionId,
          job_type_list: [selectedJobTypeId],
          work_experience: selectedExpId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          apikey: config.apiKey,
        },
      });
      logEvent(analytics, "profile_in_signup_next",{
				platfrom: "web",
				USER_ID: userId,
				ACTIVITY: "new user info given",
			});

      navigate("/");
    } catch (er) {
      console.error(er);
    }
  };

  return (
    <Container
      style={{
        backgroundColor: "#FAFBFF",
        display: "flex",
        height: "100vh",
        overflow: "scroll initial",
      }}
    >
      <Row className="justify-content-md-center">
        <Col xs={12} className="my-3">
          <Card
            style={{ width: "100%" }}
            className="d-flex justify-content-center"
          >
            <Card.Body>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Photo</Form.Label>
                  <Form.Control type="file" onChange={handleChangePhoto} />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} className="my-3">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Row>
                <Form.Group as={Col} xs={4} controlId="formBasicEmail">
                  <Form.Label>
                    Name<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={handleChangeName}
                    onBlur={handleNameBlur}
                  />
                  {showNameWarning && (
                    <small className="text-danger">*নামের ঘরটি পূরণ করুন</small>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs={4} controlId="formBasicPassword">
                  <Form.Label className="d-block">
                    Date of Birth<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={dob}
                    onChange={handleChangeDob}
                    onBlur={handleAgeBlur}
                  />
                  {showAgeWarning && (
                    <small className="text-danger">*নূন্যতম বয়স হয়নি</small>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs={4} controlId="formBasicPassword">
                  <Form.Label>
                    Gender<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div className="d-flex">
                    <Button
                      style={{ marginRight: "20px" }}
                      className="form-control"
                      variant={
                        gender === "male"
                          ? "outline-primary"
                          : "outline-secondary"
                      }
                      onClick={handleGenderChange}
                      value="male"
                    >
                      Male
                    </Button>
                    <Button
                      style={{ marginRight: "20px" }}
                      className="form-control mr-10"
                      variant={
                        gender === "female"
                          ? "outline-primary"
                          : "outline-secondary"
                      }
                      onClick={handleGenderChange}
                      value="female"
                    >
                      Female
                    </Button>
                    <Button
                      className="form-control"
                      variant={
                        gender === "other"
                          ? "outline-primary"
                          : "outline-secondary"
                      }
                      onClick={handleGenderChange}
                      value="other"
                    >
                      Other
                    </Button>
                  </div>
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} className="my-3">
          <Card style={{ width: "100%" }}>
            <Card.Header as="h5">Education Description</Card.Header>
            <Card.Body>
              <Row>
                <Form.Group as={Col} xs={6} controlId="formHighestEducation">
                  <Form.Label>
                    Highest Education<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={getEducationName(selectedEducationId)}
                    onChange={handleEducationChange}
                    onBlur={handleEducationBlur}
                  >
                    <option key={0} id={0}>
                      Select
                    </option>
                    {educations.map(
                      (edu) =>
                        edu.is_visible_user_profile && (
                          <option key={edu.id} id={edu.id}>
                            {edu.education}
                          </option>
                        )
                    )}
                  </Form.Control>
                  {showEducationWarning && (
                    <small className="text-danger">
                      *একটি শিক্ষাগত যোগ্যতা নির্বাচন করতে হবে"
                    </small>
                  )}
                </Form.Group>
                {isUniversityMandatory && (
                  <React.Fragment>
                    <Form.Group as={Col} xs={6} controlId="formUniversity">
                      <Form.Label>
                        University<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={handleUniversityChange}
                        onBlur={handleEducationBlur}
                      >
                        <option key={0} id={0}>
                          Select
                        </option>
                        {universitys.map(
                          (university) =>
                            university.is_visible && (
                              <option key={university.id} id={university.id}>
                                {university.university_name_bn}
                              </option>
                            )
                        )}
                      </Form.Control>
                      {showUniversityWarning && (
                        <small className="text-danger">
                          *একটি প্রতিষ্ঠান নির্বাচন করতে হবে
                        </small>
                      )}
                    </Form.Group>
                  </React.Fragment>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} className="my-3">
          <Card style={{ width: "100%" }}>
            <Card.Header as="h5">Division & Area</Card.Header>
            <Card.Body>
              <Row>
                <Form.Group as={Col} xs={6} controlId="formDivision">
                  <Form.Label>
                    Division<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control as="select" onChange={handleDivisionChange}>
                    <option key={0} id={0}>
                      Select
                    </option>
                    {divisions.map(
                      (div) =>
                        div.is_visible && (
                          <option key={div.division_id} id={div.division_id}>
                            {div.division}
                          </option>
                        )
                    )}
                  </Form.Control>
                  {showDivisionWarning && (
                    <small className="text-danger">
                      *একটি বিভাগ নির্বাচন করতে হবে
                    </small>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId="formArea">
                  <Form.Label>
                    Area<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleAreaChange}
                    disabled={!selectedDivisionId}
                  >
                    <option key={0} id={0}>
                      Select
                    </option>
                    {districts.map(
                      (dis) =>
                        dis.is_visible &&
                        dis.division_id === selectedDivisionId && (
                          <option key={dis.district_id} id={dis.district_id}>
                            {dis.district}
                          </option>
                        )
                    )}
                  </Form.Control>
                  {showAreaWarning && (
                    <small className="text-danger">
                      *একটি এলাকা নির্বাচন করতে হবে
                    </small>
                  )}
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} className="my-3">
          <Card style={{ width: "100%" }}>
            <Card.Header as="h5">Work Experience</Card.Header>
            <Card.Body>
              <Row>
                <Form.Group as={Col} xs={6} controlId="formWorkExperience">
                  <Form.Label>
                    Work Experience<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control as="select" onChange={handleWorkExpChange}>
                    <option key={0} id={0}>
                      Select
                    </option>
                    {workExperiences.map(
                      (workExp) =>
                        workExp.is_visible_user_profile && (
                          <option key={workExp.id} id={workExp.id}>
                            {workExp.work_experience}
                          </option>
                        )
                    )}
                  </Form.Control>
                  {showWorkExpWarning && (
                    <small className="text-danger">
                      *একটি অভিজ্ঞতা নির্বাচন করতে হবে
                    </small>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId="formWorkType">
                  <Form.Label>
                    Work Type<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control as="select" onChange={handleJobTypeChange}>
                    <option key={0} id={0}>
                      Select
                    </option>
                    {jobTypes.map(
                      (jobType) =>
                        jobType.is_visible && (
                          <React.Fragment>
                            <option key={jobType.id} id={jobType.id}>
                              {jobType.job_type}
                            </option>
                          </React.Fragment>
                        )
                    )}
                  </Form.Control>
                  {showWorkTypeWarning && (
                    <small className="text-danger">
                      *একটি কাজের ধরন নির্বাচন করতে হবে
                    </small>
                  )}
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <div
          xs={12}
          className="my-3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {isNextDisabled ? (
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}
            >
              <span className="d-inline-block">
                <Button
                  className="btn sign_up_next btn-login text-uppercase fw-bold"
                  disabled={!isNextDisabled}
                  style={{ marginTop: "30px" }}
                  type="submit"
                >
                  এরপর
                </Button>
              </span>
            </OverlayTrigger>
          ) : (
            <Button
              className="btn sign_up_next btn-login text-uppercase fw-bold"
              disabled={!isNextDisabled}
              style={{ marginTop: "30px" }}
              onClick={updateUserInfo}
              type="submit"
            >
              এরপর
            </Button>
          )}
        </div>
      </Row>
    </Container>
  );
}

export default ProfileCreate1;
