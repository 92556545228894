import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { ModalSelect2 } from "../../Components/Public/InputField";

const SkillInfoEditModal = ({
  data,
  isVisible = false,
  onHide,
  updateSkillInfo,
  skillOptions,
}) => {
  const [tempData, setTempData] = useState({});

    useEffect(() => {
      const modifiedSkillData = data.map((item) => {
        return {
          value: item?.skill_id,
          label: item?.skill_name,
        };
      });

      setTempData({ value: modifiedSkillData, is_warning: false });
  }, []);

  const uploadInfo = () => {
      const dt = tempData?.value.map((item) => {
        return {
          skill_id: item?.value,
          skill_name: item?.label,
        };
      });;
      updateSkillInfo(tempData.value, dt);
    
  };
  return (
    <Modal
      show={isVisible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Body>
        <div className="company_details profile_section">
          <h1 className="mb-4">
            অন্যান্য দক্ষতা{" "}
            <p style={{ fontSize: "18px" }}>
              সর্বাধিক ৫টি দক্ষতা বাছাই করা যাবে
            </p>
          </h1>
          <ModalSelect2
            label={"দক্ষতা"}
            isLabel={false}
            multiSelect={true}
            data={tempData?.value || {}}
            options={skillOptions}
            ph={"দক্ষতা বাছাই করুন।"}
            isWarning={tempData?.is_warning}
            warningText={"সর্বাধিক ৫টি দক্ষতা বাছাই করা যাবে"}
            onChange={(selectedOptions) => {
              if (selectedOptions.length <= 5) {
                setTempData({
                  ...tempData,
                  value: selectedOptions,
                });
              } else {
                toast.error("সর্বাধিক ৫টি দক্ষতা নির্বাচন করতে পারবেন।", {
                  autoClose: 5000,
                });
              }
            }}
            onMenuOpen={() => {}}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button
          onClick={() => {
            uploadInfo();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { SkillInfoEditModal };
