import React, { useState, useEffect } from "react";
import background_image from "../img/background_image.jpg";
import app from "../firebaseConfig";
import { getAnalytics } from "firebase/analytics";
import { etob } from "../NumberConvert";
import {  useLocation, Link } from "react-router-dom";
import {
  getSingleJobAppliedStatus,
  getSingleJobDescriptions,
} from "../ApiCalls/JobPosts";
import { Backdrop, CircularProgress, Step } from "@mui/material";
import { JdBasicInfoItem } from "../Components/JobPosts/JobDescription";
import { Steps } from "antd";
import moment from "moment";
import { Helmet } from "react-helmet-async";

const ApplicationTracker = () => {
  const analytics = getAnalytics(app);
  const [jobData, setJobData] = useState({});
  const [appliedStatus, setAppliedStatus] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isRejected, setIsRejected] = useState(false);
  const [isInterViewCalled, setInterviewCalled] = useState(false);
  const [interviewData, setInterviewData] = useState({});
  const [isConfirmedCalled, setConfirmedCalled] = useState(false);
  const [confirmedData, setConfirmedData] = useState({});
  

  const getJobData = async () => {
    const userId = localStorage.getItem("userId");
    const requestData = {
      job_id_data: id,
      ...(userId && {
        user_id_data: userId,
      }),
    };
    const response = await getSingleJobDescriptions(requestData);
    if (response.status === 200) {
      setJobData(response.data[0]);
    }
    setOpen(false);
  };

  const getAppliedStatus = async () => {
    const requestData = {
      job_id: id,
    };
    const response = await getSingleJobAppliedStatus(requestData);
    if (response.status === 200) {
      setAppliedStatus(response.data);
      response.data.forEach((item, i) => {
        if (item.status_applicable_for_user) {
          setCurrentStep(i);
          if (item.status_id === 4) {
            setInterviewCalled(true);
            setInterviewData(item);
          }
          if (item.status_id === 5) {
            setConfirmedCalled(true);
            setConfirmedData(item);
          }
        }
        if (item.status_id === 8) {
          setIsRejected(true);
        }
      });
    }
    setOpen(false);
  };

  useEffect(() => {

    setOpen(true);
    getJobData();
    getAppliedStatus();
  }, []);
   const tl = "Application Tracker | Shomvob: Jobs & Trainings";
   const ds2 =
     "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";

  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/cvmaker"></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
    <React.Fragment>
      <div
        className=" scroll-container container-fluid"
        style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: "100% auto",
          backgroundRepeat: "repeat-y",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <div className="rounded" name="Job Details">
          <div className="jd_section">
            <div className="top">
              <img
                src={jobData.company_logo}
                alt="avatar"
                style={{
                  maxHeight: "97px",
                  objectFit: "cover",
                }}
              />
            </div>

            <div className="top">
              <h1>{jobData.job_title}</h1>
            </div>
            <div className="top">
              <Link
                to={`/single_company_description?id=${jobData.company_profile_id}`}
                target="_blank"
                style={{
                  textDecoration: "none",
                }}
                name="Company Name"
              >
                {jobData.company_name}
              </Link>
            </div>
          </div>
          <div className="jd_section">
            <div className="company_details">
              <div className="col-12">
                <Steps
                  current={currentStep}
                  status={isRejected ? "error" : "process"}
                >
                  {appliedStatus.map((item) => (
                    <Step
                      key={item.status_id}
                      title={item.display_status}
                      description={
                        item.status_reached_at
                          ? moment(item.status_reached_at).format("DD/MM/YYYY")
                          : ""
                      }
                    />
                  ))}
                </Steps>
              </div>
            </div>
          </div>
          {isConfirmedCalled && (
            <div className="jd_section">
              <div className="company_details">
                <h1 className="wr">চাকরি নিশ্চিত বিবরণ</h1>
                <div className="row">
                  {confirmedData.meet_date_for_status && (
                    <JdBasicInfoItem
                      title={"তারিখ ও সময়"}
                      value={moment(confirmedData.meet_date_for_status).format(
                        "DD/MM/YYYY"
                      )}
                    />
                  )}

                  {confirmedData.contact_person_for_status && (
                    <JdBasicInfoItem
                      title={"নিয়োগকর্তার সাথে যোগাযোগ করুন"}
                      value={confirmedData.contact_person_for_status}
                    />
                  )}
                  {confirmedData.location_for_status && (
                    <JdBasicInfoItem
                      title={"লোকেশন"}
                      value={confirmedData.location_for_status}
                    />
                  )}
                  {confirmedData.required_docs_for_status && (
                    <JdBasicInfoItem
                      title={"ডকুমেন্ট(সাথে থাকতে হবে) "}
                      value={confirmedData.required_docs_for_status}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {isInterViewCalled && (
            <div className="jd_section">
              <div className="company_details">
                <h1 className="wr">ইন্টারভিউ বিবরন</h1>
                <div className="row">
                  {interviewData.meet_date_for_status && (
                    <JdBasicInfoItem
                      title={"তারিখ ও সময়"}
                      value={moment(interviewData.meet_date_for_status).format(
                        "DD/MM/YYYY"
                      )}
                    />
                  )}
                  {interviewData.contact_person_for_status && (
                    <JdBasicInfoItem
                      title={"নিয়োগকর্তার সাথে যোগাযোগ করুন"}
                      value={interviewData.contact_person_for_status}
                    />
                  )}

                  {interviewData.location_for_status && (
                    <JdBasicInfoItem
                      title={"লোকেশন"}
                      value={interviewData.location_for_status}
                    />
                  )}

                  {interviewData.required_docs_for_status && (
                    <JdBasicInfoItem
                      title={"ডকুমেন্ট(সাথে থাকতে হবে) "}
                      value={interviewData.required_docs_for_status}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="jd_section">
            <div className="company_details">
              <div className="row">
                <JdBasicInfoItem
                  title={"বেতন"}
                  value={
                    etob(jobData.salary_range).replace("TK. ", "") + " টাকা"
                  }
                />
                <JdBasicInfoItem
                  title={"পদ সংখ্যা"}
                  value={etob(jobData.total_recruits) + " টি"}
                />
                <JdBasicInfoItem
                  title={"লোকেশন"}
                  value={jobData.job_locations}
                />
                <JdBasicInfoItem
                  title={"আবেদনের শেষ তারিখ"}
                  value={etob(
                    new Date(jobData.application_deadline).toLocaleDateString(
                      "en-GB"
                    )
                  )}
                />
                <JdBasicInfoItem
                  title={"ন্যূনতম শিক্ষাগত যোগ্যতা"}
                  value={jobData.education_name}
                />
                <JdBasicInfoItem
                  title={"অভিজ্ঞতা"}
                  value={jobData.work_experience_name}
                />
              </div>
            </div>
          </div>
          <div className="jd_section mb-5">
            <h1 className="wr">কাজের দায়িত্ব</h1>
            <p className="px-3">
              <div
                style={{ overflow: "hidden", whiteSpace: "normal" }}
                dangerouslySetInnerHTML={{
                  __html: jobData.job_description,
                }}
              ></div>
            </p>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      </React.Fragment>
      </>
  );
};

export default ApplicationTracker;
