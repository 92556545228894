import { getRequest, patchRequest, postRequest } from "./MakeCall";

const getUserProfileData = async () => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/user_profile?user_id=eq." +
      localStorage.getItem("userId");
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await getRequest(url, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const getUserProfileDataAll = async () => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/get_user_data_for_cv2";
    const requestData = {
      user_id_data: localStorage.getItem("userId"),
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateUserProfileData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/user_profile?user_id=eq." +
      localStorage.getItem("userId");

    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await patchRequest(url, dataToUpdate, accessToken);

    console.log(response);
    console.log(dataToUpdate);

    return response;
  } catch (error) {
    console.error(error);
  }
};
const updateUserEducationData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_education";
    const requestData = {
      user_id_data: localStorage.userId,
      education_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};
const updateUserReferenceData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_reference";
    const requestData = {
      user_id_data: localStorage.userId,
      reference_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateUserExperienceData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/rpc/update_user_experience";
    const requestData = {
      user_id_data: localStorage.userId,
      experience_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateUserCourseData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_courses";
    const requestData = {
      user_id_data: localStorage.userId,
      course_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateUserSkillData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_skill";
    const requestData = {
      user_id_data: localStorage.userId,
      skill_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};
const isFeedbackReq = async (requestBody) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/is_feedback_require";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestBody, accessToken);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const insertFeedback = async (requestBody) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/insert_user_feedback";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestBody, accessToken);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const genarateCv = async () => {
  try {
    const url =
      process.env.REACT_APP_FIRBASE_END_POINT + "/cv/generator";
    const requestData = {
      user_id: localStorage.userId,
      user_access_token: localStorage.accessToken,
    };
    const accessToken = process.env.REACT_APP_FIRBASE_KEY;
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};



export {
  getUserProfileData,
  getUserProfileDataAll,
  updateUserProfileData,
  updateUserExperienceData,
  updateUserReferenceData,
  updateUserCourseData,
  updateUserEducationData,
  updateUserSkillData,
  genarateCv,
  isFeedbackReq,
  insertFeedback,
};
