import React, { useState, useEffect } from "react";
import background_image from "../img/background_image.jpg";
import { getSavedJobs } from "../ApiCalls/JobPosts";
import SingleJobCard from "../Components/JobPosts/JobCards";
import SideBar from "../Components/SideBar";
import { Helmet } from "react-helmet-async";
const SavedJobs = () => {
  const [savedJobsData, setSavedJobsData] = useState([]);

  const getSavedJobsData = async () => {
    const body = {
      user_id_data: localStorage.getItem("userId"),
    };
    const response = await getSavedJobs(body);
    if (response.status === 200) {
      setSavedJobsData(response.data);
    }
  };

  useEffect(() => {
    getSavedJobsData();
  }, []);

  const tj = (jobPost) => {
    const tempJobPost = {
      id: jobPost.id,
      title: jobPost.job_title,
      companyName: jobPost.company_name,
      companyLogo: jobPost.company_logo,
      companyProfileId: jobPost.company_profile_id,
      jobLocation: jobPost.job_locations,
      deadline: jobPost.application_deadline,
      salaryRange: jobPost.salary_range,
      isSave: localStorage.getItem("userId") ? jobPost.saved_job_status : null,
    };

    return tempJobPost;
  };
 const tl = "Saved jobs | Shomvob: Jobs & Trainings";
 const ds2 =
   "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";

  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/cvmaker"></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
    <React.Fragment>
      {/* <div
        className="scroll-container"
        style={{
          backgroundColor: "#FAFBFF",
          display: "flex",
          height: "100vh",
        }}
      > */}
        {/* <SideBar /> */}
        <div
          className="saved_jobs container-fluid scroll-container"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",
          }}
        >
          <div className="px-5 pt-5">
            <h1>সেইভ করা চাকরি</h1>
          </div>
          <div className="mt-4 mx-5">
            <div className="row g-4">
              {savedJobsData.map((jobPost, index) => (
                <>
                  <SingleJobCard jobPost={tj(jobPost)} mark={2} />
                </>
              ))}
            </div>
          </div>
        </div>
      {/* </div> */}
      </React.Fragment>
      </>
  );
};

export default SavedJobs;
