import React, { useEffect, useState } from "react";
import background_image from "../img/background_image.jpg";
import { getYoutubeVideos } from "../ApiCalls/Trainings";
import { YoutubeVideoCard2 } from "../Components/Trainings/YoutubeVideo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const YoutubeVideos = () => {
  const [youtubeVideosData, setYoutubeVideosData] = useState([]);
  const getYoutubeVideosData = async () => {
    const response = await getYoutubeVideos();
    if (response.status === 200) {
      setYoutubeVideosData(response?.data);
    }
  };
  useEffect(() => {
    getYoutubeVideosData();
  }, []);
  return (
    <React.Fragment>
        <ToastContainer />
        <div
          className=" scroll-container container-fluid"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
      <div className="px-5 pt-5">
        <div className="row">
          {" "}
          <div className="col-12 justify-content-start">
            <h2 className="trainings_h2">ইউটিউব ভিডিও</h2>
          </div>
        </div>
        <div className="row">
            
            
          {youtubeVideosData.map((item) => (
            <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <YoutubeVideoCard2 item={item}/>
            </div>
          
            
          ))}
        </div>
      </div>
    </div>
    
  </React.Fragment>
  );
};

export default YoutubeVideos;
