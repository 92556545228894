import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsFileEarmarkPdf } from "react-icons/bs";
import axios from "axios";
import config from "../config.json";
import { createClient } from "@supabase/supabase-js";
import app from "../firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import { etob } from "../NumberConvert";
import SideBar from "../Components/SideBar";
import background_image from "../img/background_image.jpg";
import {
  NormalInput2,
  PictureInput,
  SearchSelect,
} from "../Components/Public/InputField";
import SuccessfulApplicationModal from "../Modals/JobApplication";
import { Backdrop, CircularProgress } from "@mui/material";
import { isFeedbackReq } from "../ApiCalls/User";

function JobApplication1() {
  const analytics = getAnalytics(app);
  const location = useLocation();
  const navigate = useNavigate();
  const [jobData, setJobData] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [publicDatas, setPublicDatas] = useState({});
  const [qna, setQna] = useState([]);
  const [open, setOpen] = useState(false);

  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [division, setDivision] = useState({});
  const [district, setDistrict] = useState({});
  const [experience, setExperiences] = useState({});
  const [jobTypes, setJobTypes] = useState([]);
  const [education, setEducation] = useState({});
  const [university, setUniversity] = useState({});
  const [quAns, setQuAns] = useState([]);

  const [jobTypeWarning, setJobTypeWarning] = useState(false);
  const [divisionWarning, setDivisionWarning] = useState(false);
  const [districtWarning, setDistrictWarning] = useState(false);
  const [experienceWarning, setExperienceWarning] = useState(false);
  const [educationWarning, setEducationWarning] = useState(false);
  const [universityWarning, setUniversityWarning] = useState(false);
  const [showNameWarning, setShowNameWarning] = useState(false);
  const [qnaWarning, setQnaWarning] = useState(false);
  const [cvWarning, setCvWarning] = useState(false);

  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [universityOptions, setUniversityOptions] = useState([]);
  const [uploadedCV, setUploadedCV] = useState(null);
  const [cvMakerCV, setCvMakerCV] = useState(null);
  const [isUni, setIsUni] = useState(false);
  const [successfullyAppliedModalShow, setSuccessfullyAppliedModalShow] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);

  const [cvWarningTxt, setCvWarningTxt] = useState(
    "*যেকোনো একটিসিভি নির্বাচন করতে হবে।"
  );

  const supabase = createClient(config.supabaseUrl, config.apiKey);

  useEffect(() => {
    if (divisionOptions.length > 0 && profileData) {
      const div = divisionOptions.find((item) => {
        return item.value === profileData.division;
      });

      setDivision(div);
    }
  }, [profileData, divisionOptions]);

  const handleImageChange = (fileData) => {
    if (fileData && fileData[0]) {
      let img = fileData[0];
      image
        ? imagUpdate(
            localStorage.getItem("userId") + "/profile/profile_image.jpg",
            img,
            1
          )
        : imagUpload(
            localStorage.getItem("userId") + "/profile/profile_image.jpg",
            img,
            1
          );

      setImage(URL.createObjectURL(img));
    }
  };

  useEffect(() => {
    if (publicDatas.district && division) {
      const dis = publicDatas.district
        .filter((item) => {
          return (
            division.value === item.division_id && item.is_visible === true
          );
        })
        .map((item) => ({
          value: item.district_id,
          label: item.district,
          division_id: item.division_id,
        }));
      setDistrictOptions(dis);
    }
  }, [division, publicDatas]);
  useEffect(() => {
    if (profileData.district && districtOptions.length > 0) {
      const dis = districtOptions.find((item) => {
        return item.value === profileData.district;
      });
      setDistrict(dis);
    }
  }, [districtOptions, profileData]);

  useEffect(() => {
    if (profileData.job_type_list && jobTypeOptions.length > 0) {
      const jt = jobTypeOptions.filter((item) => {
        return profileData.job_type_list.includes(item.value);
      });
      setJobTypes(jt);
    }
  }, [jobTypeOptions, profileData]);

  useEffect(() => {
    if (profileData.work_experience && experienceOptions.length > 0) {
      const exp = experienceOptions.find((item) => {
        return item.value === profileData.work_experience;
      });
      setExperiences(exp);
    }
  }, [experienceOptions, profileData]);

  useEffect(() => {
    if (profileData.education && educationOptions.length > 0) {
      const edu = educationOptions.find((item) => {
        return item.value === profileData.education;
      });

      setEducation(edu);
    }
  }, [educationOptions, profileData]);

  useEffect(() => {
    if (!education.is_university_required) {
      setUniversity({});
    }
    setIsUni(education.is_university_required);
  }, [education]);

  useEffect(() => {
    if (profileData.university_id && universityOptions.length > 0) {
      const uni = universityOptions.find((item) => {
        return item.value === profileData.university_id;
      });
      setUniversity(uni);
    }
  }, [universityOptions, profileData]);

  const extractProfileData = (data) => {
    setName(data.full_name);
    setPhone(data.username);
    setImage(data.profile_photo);
    try {
      data.cv_data.forEach((item) => {
        if (item.id === 1) {
          setUploadedCV(item.url);
        } else if (item.id === 2) {
          setCvMakerCV(item.url);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getQna = async () => {
      try {
        const response = await axios.get(
          config.serverEndPoint +
            "job_list_extra_ques?job_listing_id=eq." +
            jobData.id,

          {
            headers: {
              "Content-Type": "application/json",
              apikey: config.apiKey,
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        );

        setQna(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (jobData?.id) {
      getQna();
    }
    
  }, [jobData]);

  useEffect(() => {
    if (localStorage.getItem("publicTableData")) {
      const publicStringData = localStorage.getItem("publicTableData");
      const publicData = JSON.parse(publicStringData);
      setPublicDatas(publicData);

      const divOp = publicData.division
        .filter((item) => {
          return item.is_visible;
        })
        .map((item) => ({
          value: item.division_id,
          label: item.division,
        }));

      setDivisionOptions(divOp);

      const jjtt = publicData.job_type
        .filter((item) => {
          return item.is_visible;
        })
        .map((item) => ({
          value: item.id,
          label: item.job_type,
        }));

      setJobTypeOptions(jjtt);

      const we = publicData.work_experience
        .filter((item) => {
          return item.is_visible_user_profile;
        })
        .map((item) => ({
          value: item.id,
          label: item.work_experience,
        }));

      setExperienceOptions(we);

      const edu = publicData.education
        .filter((item) => {
          return item.is_visible_user_profile;
        })
        .map((item) => ({
          value: item.id,
          label: item.education,
          is_university_required: item.is_university_required,
        }));

      setEducationOptions(edu);

      const up = publicData.university_list
        .filter((item) => {
          return item.is_visible;
        })
        .map((item) => ({
          value: item.id,
          label: item.university_name_bn,
        }));
      setUniversityOptions(up);
    }
    setJobData(location.state?.jobData);

    const getProfileData = async () => {
      try {
        const response = await axios.get(
          config.serverEndPoint +
            "user_profile?user_id=eq." +
            localStorage.getItem("userId"),

          {
            headers: {
              "Content-Type": "application/json",
              apikey: config.apiKey,
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
          }
        );

        setProfileData(response.data[0]);
        extractProfileData(response.data[0]);
      } catch (error) {
        console.error(error);
      }
    };
    getProfileData();
  }, []);

  const imagUpload = async (path, data1) => {
    const token = localStorage.getItem("accessToken");
    const { data, error } = await supabase.storage
      .from("shomvob-bucket")
      .upload(path, data1, {
        cacheControl: "1",
        upsert: false,
        headers: { Authorization: `Bearer ${token}` },
      });

    if (error) {
      console.log(error.message);
    } else {
      console.log(data);
    }
  };
  const imagUpdate = async (path, data1, mark) => {
    const token = localStorage.getItem("accessToken");
    const { data, error } = await supabase.storage
      .from("shomvob-bucket")
      .update(path, data1, {
        cacheControl: "1",
        upsert: false,
        headers: { Authorization: `Bearer ${token}` },
      });

    if (error) {
      console.log(error.message);
    } else {
      console.log(data);

      if (data.path) {
        if (mark === 1) {
        } else {
          setUploadedCV(config.storageUrl + data.path);
          toast.success("সিভি আপলোড সম্পূর্ণ হয়েছে।", {
            autoClose: 5000,
          });
          if (profileData.cv_data) {
          }
        }
      }
    }
  };

  const updateProfileData = async (dt, mark) => {
    try {
      const response = await axios.patch(
        config.serverEndPoint +
          "user_profile?user_id=eq." +
          localStorage.getItem("userId"),
        dt,
        {
          headers: {
            "Content-Type": "application/json",
            apikey: config.apiKey,
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      if (response.status === 204) {
        if (mark) {
          insertUserJob();
        }
      } else {
        setOpen(false);
        toast.error("Something went wrong, please try again later", {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const insertUserJob = async () => {
    try {
      const response = await axios.post(
        config.serverEndPoint + "user_job",
        {
          user_id: localStorage.getItem("userId"),
          job_listing: jobData.id,
          status: 1,
          ...(jobData.is_cv_mandatory && {
            selected_cv_url:
              selectedOption === "upload" ? uploadedCV : cvMakerCV,
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            apikey: config.apiKey,
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 201 || response.status === 204) {
        if (qna.length > 0) {
          insertQNA();
        } else {
          successMsg();
        }
      } else {
        setOpen(false);
        toast.error("Something went wrong, please try again later", {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkIsFeedbackReq = async () => {
    const body = {
      user_id_data: localStorage.getItem("userId"),
      feature_name: "job_apply_web",
    };
    const response = await isFeedbackReq(body);
    if (response.status === 200) {
       setIsFeedback(response.data.is_feedback_require);
    }
    return  false;
  }
  

  const successMsg = async () => {
    await checkIsFeedbackReq();
    setOpen(false);
    setSuccessfullyAppliedModalShow(true);
    toast.success("আপনার আবেদনটি সফল হয়েছে।", {
      autoClose: 5000,
    });
    logEvent(analytics, "job_apply", {
      FROM: "jobApplication1",
      platfrom: "web",
      USER_ID: localStorage.getItem("userId"),
      JOB_ID: jobData.id,
      ACTIVITY: "confirm button clicked",
    });
  };

  const insertQNA = async () => {
    const ans = qna.map((item, index) => ({
      user_id: localStorage.getItem("userId"),
      answer: quAns[index],
      job_list_extra_ques_id: item.id,
    }));

    try {
      const response = await axios.post(
        config.serverEndPoint + "job_list_extra_ques_ans",
        ans,
        {
          headers: {
            "Content-Type": "application/json",
            apikey: config.apiKey,
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 201 || response.status === 204) {
        successMsg();
      } else {
        setOpen(false);
        toast.error("Something went wrong, please try again later", {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePdfChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let pd = event.target.files[0];
      uploadedCV
        ? imagUpdate(localStorage.getItem("userId") + "/cv/cv.pdf", pd, 2)
        : imagUpload(localStorage.getItem("userId") + "/cv/cv.pdf", pd, 2);
    }
  };

  const makeCv = () => {
    navigate("/CVMaker", {
      state: { from: "/JobApplication1", jobData: jobData },
    });
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  function handlePreviewPdf(url) {
    try {
      window.open(url.replace("sign", "public"), "_blank");
    } catch (err) {
      console.log(err);
    }
  }
  const validation = () => {
    let isValid = true;
    if (image === "") {
      isValid = false;
    }
    if (name === "") {
      isValid = false;
      setShowNameWarning(true);
    } else {
      setShowNameWarning(false);
    }

    if (Object.keys(division).length === 0) {
      isValid = false;
      setDivisionWarning(true);
    } else {
      setDivisionWarning(false);
    }

    if (Object.keys(district).length === 0) {
      isValid = false;
      setDistrictWarning(true);
    } else {
      setDistrictWarning(false);
    }

    if (Object.keys(experience).length === 0) {
      isValid = false;
      setExperienceWarning(true);
    } else {
      setExperienceWarning(false);
    }

    if (jobTypes.length === 0) {
      isValid = false;
      setJobTypeWarning(true);
    } else {
      setJobTypeWarning(false);
    }
    if (Object.keys(education).length === 0) {
      isValid = false;
      setEducationWarning(true);
    } else {
      setEducationWarning(false);
    }
    if (
      education.is_university_required &&
      Object.keys(university).length === 0
    ) {
      isValid = false;
      setUniversityWarning(true);
    } else {
      setUniversityWarning(false);
    }
    if (quAns.filter((answer) => answer).length < qna.length) {
      isValid = false;
      setQnaWarning(true);
    } else {
      setQnaWarning(false);
    }

    if (jobData.is_cv_mandatory && selectedOption === "") {
      isValid = false;
      setCvWarning(true);
    } else if (
      jobData.is_cv_mandatory &&
      selectedOption === "create" &&
      !cvMakerCV
    ) {
      setCvWarningTxt("*আপনার কোন তৈরী করা সিভি নেই।");

      isValid = false;
      setCvWarning(true);
    } else if (
      jobData.is_cv_mandatory &&
      selectedOption === "upload" &&
      !uploadedCV
    ) {
      setCvWarningTxt("*আপনার কোন আপলোড করা সিভি নেই।");

      isValid = false;
      setCvWarning(true);
    } else {
      setCvWarning(false);
    }

    return isValid;
  };

  const uploadInfo = () => {
    setOpen(true);
    if (validation()) {
      const dt = {
        full_name: name,
        education: education.value,
        division: division.value,
        district: district.value,
        work_experience: experience.value,
        job_type_list: jobTypes.map((item) => item.value),
        university_id: education.is_university_required
          ? university.value
          : null,
      };

      updateProfileData(dt, true);
    } else {
      setOpen(false);
      toast.error("সকল প্রয়োজনীয় তথ্য পুরুন করুন", {
        autoClose: 5000,
      });
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div
        className="scroll-container"
        style={{
          backgroundColor: "#FAFBFF",
          display: "flex",
          height: "100vh",
        }}
      >
        {/* <SideBar /> */}
        <div
          className=" scroll-container container-fluid"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          <div className="rounded" name="Job Details">
            <div className="jd_section">
              <div className="top">
                <img
                  src={jobData.company_logo}
                  alt="avatar"
                  style={{
                    maxHeight: "97px",
                    objectFit: "cover",
                  }}
                />
              </div>

              <div className="top">
                <h1>{jobData.job_title}</h1>
              </div>
              <div className="top">
                <Link
                  to={`/single_company_description?id=${jobData.company_profile_id}`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                  }}
                  name="Company Name"
                >
                  {jobData.company_name}
                </Link>
              </div>
            </div>

            <div className="jd_section">
              <div className="company_details">
                <h1 className="wr mb-4">আপনার তথ্য যাচাই করুন</h1>
                <div className="row">
                  <NormalInput2
                    label={"পুরো নাম"}
                    isLabel={true}
                    isWarning={false}
                    warningText={"নামের ঘরটি পূরণ করতে হবে।"}
                    ph={"আপনার নাম লিখুন"}
                    id={"user_name"}
                    name={"user_name"}
                    data={name || ""}
                    type={"text"}
                    onChange={(e) => {
                      setName(e);
                    }}
                  />
                  <NormalInput2
                    label={"মোবাইল নম্বর"}
                    isLabel={true}
                    isWarning={false}
                    warningText={"মোবাইল নম্বর পূরণ করতে হবে।"}
                    ph={"আপনার মোবাইল নম্বর লিখুন"}
                    id={"user_contact"}
                    name={"user_contact"}
                    data={"+" + phone || ""}
                    type={"text"}
                    isDisable={true}
                    onChange={(e) => {
                      setPhone(e);
                    }}
                  />
                  <SearchSelect
                    label={"বিভাগ"}
                    isLabel={true}
                    data={division || {}}
                    options={divisionOptions}
                    ph={"বিভাগ বাছাই করুন।"}
                    isWarning={divisionWarning}
                    warningText={"একটি বিভাগ বাছাই করতে হবে।"}
                    onChange={(selectedOption) => {
                      setDivision(selectedOption);
                      setDivisionWarning(false);
                    }}
                    onMenuOpen={() => {}}
                  />
                  <SearchSelect
                    label={"এলাকা"}
                    isLabel={true}
                    data={district || {}}
                    options={districtOptions}
                    ph={"এলাকা বাছাই করুন।"}
                    isWarning={districtWarning}
                    warningText={"একটি এলাকা বাছাই করতে হবে।"}
                    onChange={(selectedOption) => {
                      setDistrict(selectedOption);
                      setDistrictWarning(false);
                    }}
                    onMenuOpen={() => {}}
                  />
                  <SearchSelect
                    label={"কাজের অভিজ্ঞতা"}
                    isLabel={true}
                    data={experience || {}}
                    options={experienceOptions}
                    ph={"কাজের অভিজ্ঞতা বাছাই করুন।"}
                    isWarning={experienceWarning}
                    warningText={"একটি কাজের অভিজ্ঞতা বাছাই করতে হবে।"}
                    onChange={(selectedOption) => {
                      setExperiences(selectedOption);
                      setExperienceWarning(false);
                    }}
                    onMenuOpen={() => {}}
                  />
                  <SearchSelect
                    label={"কাজের ধরন"}
                    isLabel={true}
                    data={jobTypes || {}}
                    options={jobTypeOptions}
                    ph={"কাজের ধরন বাছাই করুন।"}
                    isWarning={jobTypeWarning}
                    warningText={"একটি কাজের ধরন বাছাই করতে হবে।"}
                    onChange={(selectedOptions) => {
                      if (selectedOptions.length <= 5) {
                        setJobTypes(selectedOptions);
                        setJobTypeWarning(false);
                      } else {
                        toast.error(
                          "সর্বাধিক ৫টি দক্ষতা নির্বাচন করতে পারবেন।",
                          {
                            autoClose: 5000,
                          }
                        );
                      }
                    }}
                    multiSelect={true}
                    onMenuOpen={() => {}}
                  />
                  <SearchSelect
                    label={"সর্বোচ্চ শিক্ষাগত যোগ্যতা"}
                    isLabel={true}
                    data={education || {}}
                    options={educationOptions}
                    ph={"সর্বোচ্চ শিক্ষাগত যোগ্যতা বাছাই করুন।"}
                    isWarning={educationWarning}
                    warningText={
                      "একটি সর্বোচ্চ শিক্ষাগত যোগ্যতা বাছাই করতে হবে।"
                    }
                    onChange={(selectedOption) => {
                      setEducation(selectedOption);
                      setEducationWarning(false);
                    }}
                    onMenuOpen={() => {}}
                  />

                  {isUni && (
                    <SearchSelect
                      label={"শিক্ষা প্রতিষ্ঠান"}
                      isLabel={true}
                      data={university || {}}
                      options={universityOptions}
                      ph={"শিক্ষা প্রতিষ্ঠান বাছাই করুন।"}
                      isWarning={universityWarning}
                      warningText={"শিক্ষা প্রতিষ্ঠান বাছাই করতে হবে।"}
                      onChange={(selectedOption) => {
                        setUniversity(selectedOption);
                        setUniversityWarning(false);
                      }}
                      onMenuOpen={() => {}}
                    />
                  )}
                </div>
                <h1 className="wr mb-4 mt-5">আপনার ছবি যাচাই করুন</h1>
                <PictureInput
                  image={image}
                  onPictureUpload={(fileData) => {
                    handleImageChange(fileData);
                  }}
                />
              </div>
            </div>

            {qna.length > 0 && (
              <div className="jd_section">
                <div className="company_details">
                  <div className="row">
                    <h1 className="wr mt-3">
                      সঠিক উত্তর দিয়ে ইন্টারভিউতে এগিয়ে থাকুন
                    </h1>

                    {qnaWarning && (
                      <small className="text-danger">
                        *সকল প্রশ্নের উত্তর দিতে হবে।
                      </small>
                    )}

                    {qna.map((item, index) => (
                      <div className="col-12">
                        <div className="company_details_wrapper">
                          <p className="input_field_p">
                            {etob(index + 1) + ". " + item.questions}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              {" "}
                              *
                            </span>
                          </p>

                          {item.options.map((op, opIndex) => (
                            <Form.Check
                              id={op.id}
                              label={op.option}
                              type="radio"
                              name={index}
                              onChange={() => {
                                const newSelectedAnswers = [...quAns];
                                newSelectedAnswers[index] = op;
                                setQuAns(newSelectedAnswers);
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {jobData.is_cv_mandatory && (
              <div className="jd_section">
                <h1 className="wr">সিভি নির্বাচন করুন</h1>
                <p>
                  নীচে থেকে একটি সিভি নির্বাচন করুন যা আপনি নিয়োগকর্তাকে দেখাতে
                  চান
                </p>
                <div>
                  <Form>
                    <Row>
                      <Col xs={6}>
                        <Form.Group className="cv_maker_form_group">
                          <Form.Check
                            type="radio"
                            label="আপলোড করা সিভি"
                            name="uploadOption"
                            value="upload"
                            checked={selectedOption === "upload"}
                            onChange={handleOptionChange}
                          />
                          <div className="upload-box">
                            <BsFileEarmarkPdf
                              size={94}
                              color="#7E7E7E"
                              onClick={() => handlePreviewPdf(uploadedCV)}
                            />
                            <Form.Group>
                              <Button
                                style={{
                                  color: "#344761",
                                  borderRadius: "33px",
                                  marginTop: "10px",
                                }}
                                as="label"
                                htmlFor="pdfInput"
                                variant="outline-secondary"
                                className="align-items-center"
                              >
                                <span className="me-2"></span> সিভি আপলোড করুন
                                <Form.Control
                                  id="pdfInput"
                                  type="file"
                                  accept="application/pdf"
                                  onChange={handlePdfChange}
                                  style={{
                                    display: "none",
                                  }}
                                />
                              </Button>
                            </Form.Group>
                          </div>
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group className="cv_maker_form_group">
                          <Form.Check
                            type="radio"
                            label="তৈরী করা সিভি"
                            name="uploadOption"
                            value="create"
                            checked={selectedOption === "create"}
                            onChange={handleOptionChange}
                          />
                          <div className="upload-box">
                            <BsFileEarmarkPdf
                              size={94}
                              color="#7E7E7E"
                              onClick={() => handlePreviewPdf(cvMakerCV)}
                            />
                            <Form.Group>
                              <Button
                                style={{
                                  color: "#344761",
                                  borderRadius: "33px",
                                  marginTop: "10px",
                                }}
                                variant="outline-secondary"
                                onClick={makeCv}
                                className="align-items-center"
                              >
                                <span className="me-2"></span> সিভি তৈরী করুন
                              </Button>
                            </Form.Group>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            )}
          </div>
          <div className="pt-3 pb-5 rounded mt-3">
            <div className="company_info_box white_box">
              <div>
                <Col
                  xs={12}
                  className="text-center"
                  style={{
                    marginTop: "60px",
                  }}
                >
                  <Button
                    style={{
                      maxWidth: "370px",
                    }}
                    onClick={uploadInfo}
                    variant="primary"
                  >
                    নিশ্চিত করুন
                  </Button>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessfulApplicationModal
        isModalShow={successfullyAppliedModalShow}
        visitAppliedJob={() => {
          navigate("/application_tracker", {
            state: { isJobApplyFeedback: isFeedback },
          });
        }}
        visitHome={() => {
           navigate("/", {
             state: { isJobApplyFeedback: isFeedback },
           });
        }}
      />

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}

export default JobApplication1;
