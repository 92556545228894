import config from "./config.json";
import axios from "axios";
import app from "./firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics(app);


export const handleSaveData = async (jobId, flag) => {

	const url =
		config.serverEndPoint +
		"saved_jobs?job_listing=eq." +
		jobId +
		"&user_id=eq." +
		localStorage.getItem("userId");
	try {
		const response = await axios.patch(
			url,
			{
				saved_job_status: flag==="saved"
					? "removed_from_saved"
					: "saved",
			},
			{
				headers: {
					"Content-Type": "application/json",
					apikey: config.apiKey,
					Authorization:
						"Bearer " +
						localStorage.getItem(
							"accessToken"
						),
				},
			}
		);

		if (response.status === 204) {
			logEvent(analytics, "job_saved", {
				FROM: "Home",
				platfrom: "web",
				USER_ID: localStorage.getItem("userId"),
				JOB_ID: jobId,
				status: flag==="saved" ? "unsaved" : "saved",
			});
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.log(error);
	}
};

export const handleSaveData2 = async (jobId) => {
	const url = config.serverEndPoint + "saved_jobs";
	try {
		const response = await axios.post(
			url,
			{
				user_id: localStorage.getItem("userId"),
				job_listing: jobId,
				saved_job_status: "saved",
			},
			{
				headers: {
					"Content-Type": "application/json",
					apikey: config.apiKey,
					Authorization:
						"Bearer " +
						localStorage.getItem(
							"accessToken"
						),
				},
			}
		);

		if (response.status === 204) {
			logEvent(analytics, "job_saved", {
				FROM: "Home",
				platfrom: "web",
				USER_ID: localStorage.getItem("userId"),
				JOB_ID: jobId,
				status: "saved",
			});
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.log(error);
	}
};

