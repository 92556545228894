import React, { useEffect, useState } from "react";
import { AppBannerSlider } from "../Components/AppBannerCarousel";
import SingleJobCard from "../Components/JobPosts/JobCards";
import app from "../firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation, useNavigate } from "react-router-dom";
import background_image from "../img/background_image.jpg";
import SideBar from "../Components/SideBar";
import { SearchInput, SearchSelect } from "../Components/Public/InputField";
import { getHomePageBanners, getLiveJobPosts } from "../ApiCalls/JobPosts";
import { Helmet } from "react-helmet-async";
import { FeatureFeedbackModal } from "../Modals/User/Feedback";

const Home = () => {
  const location = useLocation();
  const analytics = getAnalytics(app);
  const navigate = useNavigate();

  const [appBanners, setAppBanners] = useState([]);
  const [jobPosts, setJobPosts] = useState([]);
  const [backUpPosts, setBackUpPosts] = useState([]);
  const [divisionFilter, setDivisionFilter] = useState([]);
  const [areaFilter, setAreaFilter] = useState([]);
  const [educationFilter, setEducationFilter] = useState([]);
  const [jobTypeFilter, setJobTypeFilter] = useState([]);
  const [expFilter, setExpFilter] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [expOptions, setExpOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isAreaDisable, setIsAreaDisable] = useState([]);
  const [publicData1, setPublicData1] = useState({});
  const [isRpoShow, setIsRpoShow] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);

  const getJobPosts = async () => {
    const response = await getLiveJobPosts();
    const reversedData = response.data.reverse();
    setJobPosts(reversedData);
    setBackUpPosts(reversedData);
  };

  const getAppBanners = async () => {
    const response = await getHomePageBanners();
    if (response.status === 200) {
      setAppBanners(response.data);
    } else {
      console.error("Something went wrong.");
    }
  };

  const tj = (jobPost) => {
    const tempJobPost = {
      id: jobPost.id,
      title: jobPost.job_title,
      companyName: jobPost.company_name,
      companyLogo: jobPost.company_logo,
      companyProfileId: jobPost.company_profile_id,
      jobLocation: jobPost.job_locations,
      deadline: jobPost.application_deadline,
      salaryRange: jobPost.salary_range,
      isSave: localStorage.getItem("userId") ? jobPost.saved_job_status : null,
    };

    return tempJobPost;
  };

  useEffect(() => {
    getJobPosts();
    getAppBanners();
    extractFilterOptions();
    setIsFeedback(location?.state?.isJobApplyFeedback ?? false);

    logEvent(analytics, "Home Visit", {
      platfrom: "web",
      USER_ID: localStorage.getItem("userId") && localStorage.getItem("userId"),
    });
  }, [analytics]);
   useEffect(() => {
     window.addEventListener("beforeunload", handleBeforeUnload);
     return () => {
       window.removeEventListener("beforeunload", handleBeforeUnload);
     };
   }, []);

   const handleBeforeUnload = (event) => {
     navigate("/", {
       state: {
         ...location?.state,
         isJobApplyFeedback: false,
       },
     });
   };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobTypeId = searchParams.get("job_type");

    if (jobTypeOptions.length > 0 && jobTypeId) {
      setJobTypeFilter(
        jobTypeOptions.filter((item) => item.value === parseInt(jobTypeId, 10))
      );
    }
  }, [jobTypeOptions, location.search]);

  const extractFilterOptions = () => {
    if (localStorage.getItem("publicTableData")) {
      const publicStringData = localStorage.getItem("publicTableData");
      const publicData = JSON.parse(publicStringData);

      setPublicData1(publicData);

      setDivisionOptions(
        publicData.division
          .filter((item) => item.is_visible)
          .map((item) => ({
            value: item.division_id,
            label: item.division,
            division: item.division,
            division_en: item.division_en,
          }))
      );
      setEducationOptions(
        publicData.education
          .filter((item) => item.is_visible_in_filter)
          .map((item) => ({
            value: item.id,
            label: item.education,
            education: item.education,
            education_en: item.education_en,
          }))
      );
      setJobTypeOptions(
        publicData.job_type
          .filter((item) => item.is_visible)
          .map((item) => ({
            value: item.id,
            label: item.job_type,
            job_type: item.job_type,
            job_type_en: item.job_type_en,
          }))
      );

      setExpOptions(
        publicData.work_experience
          .filter((item) => item.is_visible_in_filter)
          .map((item) => ({
            value: item.id,
            label: item.work_experience,
            work_experience: item.work_experience,
            work_experience_en: item.work_experience_en,
          }))
      );
    }
  };

  const getAreaOptions = (selectedOptions) => {
    setAreaOptions(
      publicData1.district
        .filter((item) => item.is_visible)
        .map((item) => ({
          value: item.district_id,
          label: item.district,
          district: item.district,
          district_en: item.district_en,
          division_id: item.division_id,
        }))
        .filter((d) => {
          return selectedOptions.some(
            (option) => option.value === d.division_id
          );
        })
    );

    setIsAreaDisable(false);
  };

  useEffect(() => {
    let tempJobPost = backUpPosts;

    if (isRpoShow) {
      tempJobPost = tempJobPost.filter((item) => item.is_rpo);
    }

    if (searchText.length > 0) {
      tempJobPost = tempJobPost.filter(
        (item) =>
          item.job_title.toLowerCase().includes(searchText.toLowerCase()) ||
          item.company_name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (educationFilter.length > 0) {
      const educationFilterIds = educationFilter.map((item) => {
        return item.value;
      });

      tempJobPost = tempJobPost.filter((item) => {
        const educationFilterIdsInItem = item.education_filter.map(
          (filter) => filter.id
        );
        return educationFilterIds.some((id) =>
          educationFilterIdsInItem.includes(id)
        );
      });
    }

    if (divisionFilter.length > 0) {
      const divisionFilterIds = divisionFilter.map((item) => {
        return item.value;
      });

      tempJobPost = tempJobPost.filter((item) => {
        const divisionFilterIdsInItem = item.division_id.map(
          (filter) => filter.id
        );
        return divisionFilterIds.some((id) =>
          divisionFilterIdsInItem.includes(id)
        );
      });
    }

    if (areaFilter.length > 0) {
      const areaFilterIds = areaFilter.map((item) => {
        return item.value;
      });

      tempJobPost = tempJobPost.filter((item) => {
        const areaFilterIdsInItem = item.district_id.map((filter) => filter.id);
        return areaFilterIds.some((id) => areaFilterIdsInItem.includes(id));
      });
    }

    if (jobTypeFilter.length > 0) {
      const jobTypeFilterIds = jobTypeFilter.map((item) => {
        return item.value;
      });

      tempJobPost = tempJobPost.filter((item) => {
        return jobTypeFilterIds.includes(item.job_type);
      });
    }

    if (expFilter.length > 0) {
      const expFilterIds = expFilter.map((item) => {
        return item.value;
      });

      tempJobPost = tempJobPost.filter((item) => {
        const expFilterIdsInItem = item.work_experience_filter
          ? item.work_experience_filter.map((filter) => filter.id)
          : [];
        return expFilterIds.some((id) => expFilterIdsInItem.includes(id));
      });
    }

    setJobPosts(tempJobPost);
  }, [
    searchText,
    divisionFilter,
    areaFilter,
    educationFilter,
    jobTypeFilter,
    expFilter,
    backUpPosts,
    isRpoShow,
  ]);
  const tl = "Shomvob: Jobs & Trainings";
  const ds2 =
    "Shomvob: Your trusted job portal in Bangladesh, connecting job seekers with career opportunities and helping companies find top talent.";

  return (
    <>
      <Helmet>
        <title>{tl}</title>
        <meta name="description" content={ds2} />
        <link rel="canonical" href="/cvmaker"></link>
        {/* End standard metadata tags  */}

        {/*  Facebook tags  */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={tl} />
        <meta property="og:description" content={ds2} />
        {/* End Facebook tags */}

        {/* Twitter tags*/}
        <meta name="twitter:creator" content="Shomvob" />
        <meta name="twitter:card" content="article" />
        <meta name="twitter:title" content={tl} />
        <meta name="twitter:description" content={ds2} />
        {/*End Twitter tags*/}
      </Helmet>
      <React.Fragment>
        <div
          className="scroll-container"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundSize: "100% auto",
            backgroundRepeat: "repeat-y",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          <section className="py-0">
            <div
              className="pb-5 app-banner-big-screen"
              style={{ width: "100%" }}
            >
              <AppBannerSlider
                data={appBanners}
                slidesToShow={2}
                slidesToScroll={1}
              />
            </div>
            <div
              className="pb-5 app-banner-small-screen"
              style={{ width: "100%" }}
            >
              <AppBannerSlider
                data={appBanners}
                slidesToShow={1}
                slidesToScroll={1}
              />
            </div>

            <div className="job_filter container-fluid row">
              <SearchInput
                isFilterActive={filterStatus}
                onFilterClick={() => {
                  setFilterStatus(!filterStatus);
                  if (filterStatus) {
                    setAreaFilter({});
                    setDivisionFilter({});
                    setEducationFilter({});
                    setExpFilter({});
                    setJobTypeFilter({});
                  }
                }}
                type={"text"}
                data={searchText}
                onSearchTextChange={(e) => {
                  setSearchText(e);
                }}
                id={"search"}
                name={"search_field"}
                ph={"চাকরি ফিল্টার করুন"}
              />
              <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-12 d-flex mb-4">
                <button
                  className={
                    !isRpoShow ? "success_btn px-2" : "outline_btn px-2"
                  }
                  onClick={() => {
                    setIsRpoShow(false);
                  }}
                >
                  সকল চাকরি
                </button>
                <button
                  className={
                    isRpoShow ? "success_btn px-2" : "outline_btn px-2"
                  }
                  onClick={() => {
                    setIsRpoShow(true);
                  }}
                >
                  জরুরি নিয়োগ
                </button>
              </div>
            </div>

            {filterStatus && (
              <div className="row container-fluid ">
                <SearchSelect
                  options={divisionOptions}
                  ph={"বিভাগ দ্বারা করে ফিল্টার করুন"}
                  data={divisionFilter || {}}
                  name={"division"}
                  isLabel={false}
                  onMenuOpen={() => {
                    if (divisionOptions.length <= 0) {
                      extractFilterOptions();
                    }
                  }}
                  onChange={(selectedOptions) => {
                    setDivisionFilter(selectedOptions);
                    if (selectedOptions.length > 0) {
                      getAreaOptions(selectedOptions);
                    } else {
                      setIsAreaDisable(true);
                    }
                  }}
                  multiSelect={true}
                />
                <SearchSelect
                  options={areaOptions}
                  ph={"এলাকা দ্বারা করে ফিল্টার করুন"}
                  data={areaFilter || {}}
                  name={"district"}
                  isLabel={false}
                  onMenuOpen={() => {
                    if (areaOptions.length <= 0) {
                      extractFilterOptions();
                    }
                  }}
                  onChange={(selectedOptions) => {
                    setAreaFilter(selectedOptions);
                  }}
                  multiSelect={true}
                  isDisable={isAreaDisable}
                />

                <SearchSelect
                  options={educationOptions}
                  ph={"শিক্ষাগত যোগ্যতা দ্বারা ফিল্টার"}
                  data={educationFilter || {}}
                  name={"education"}
                  isLabel={false}
                  onMenuOpen={() => {
                    if (educationOptions.length <= 0) {
                      extractFilterOptions();
                    }
                  }}
                  onChange={(selectedOptions) => {
                    setEducationFilter(selectedOptions);
                  }}
                  multiSelect={true}
                />

                <SearchSelect
                  options={jobTypeOptions}
                  ph={"কাজের ধরন দ্বারা ফিল্টার"}
                  data={jobTypeFilter || {}}
                  name={"job_type"}
                  isLabel={false}
                  onMenuOpen={() => {
                    if (jobTypeOptions.length <= 0) {
                      extractFilterOptions();
                    }
                  }}
                  onChange={(selectedOptions) => {
                    setJobTypeFilter(selectedOptions);
                  }}
                  multiSelect={true}
                />

                <SearchSelect
                  options={expOptions}
                  ph={"কাজের অভিজ্ঞতা দ্বারা ফিল্টার"}
                  data={expFilter || {}}
                  name={"work_experience"}
                  isLabel={false}
                  onMenuOpen={() => {
                    if (expOptions.length <= 0) {
                      extractFilterOptions();
                    }
                  }}
                  onChange={(selectedOptions) => {
                    setExpFilter(selectedOptions);
                  }}
                  multiSelect={true}
                />
              </div>
            )}

            <div className="container-fluid">
              <div className="company_job_opening mt-3">
                <div className="pb-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h1 className="section_title">সম্ভবের লাইভ চাকরি সমূহ</h1>
                    </div>
                  </div>
                </div>

                <div className="row g-4">
                  {jobPosts.map((jobPost, index) => (
                    <>
                      <SingleJobCard jobPost={tj(jobPost)} mark={1} />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
        <FeatureFeedbackModal
          isVisible={isFeedback}
          featureName={"job_apply_web"}
          title={
            "সম্ভব অ্যাপের মাধ্যমে চাকরিতে আবেদন করার অভিজ্ঞতাকে আপনি কীভাবে মূল্যায়ন করবেন?"
          }
          hide={() => {
            setIsFeedback(false);
          }}
        />
      </React.Fragment>
    </>
  );
};

export default Home;
