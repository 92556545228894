import React, { useEffect, useState } from "react";
import config from "../../config.json";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiCamera } from "react-icons/fi";
import defultImage from "../../img/picture_upload_background.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import { NormalInput, SelectInput, DateInput } from "../../InputField";
import background_image from "../../img/background_image.jpg";
import {
  ModalDateInput,
  ModalSelect,
  NormalInput2,
  PictureInput,
} from "../../Components/Public/InputField";
import { Backdrop, CircularProgress } from "@mui/material";
import { createClient } from "@supabase/supabase-js";
const CreateProfile = () => {
  const location = useLocation();
  const [userId, setUserId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [profileData, setProfileData] = useState({});
  const [nameWarning, setNameWarning] = useState(false);
  const [dobWarning, setDobWarning] = useState(false);
  const [genderWarning, setGenderWarning] = useState(false);
  const [divWarning, setDivWarning] = useState(false);
  const [disWarning, setDisWarning] = useState(false);
  const [expWarning, setExpWarning] = useState(false);
  const [eduWarning, setEduWarning] = useState(false);
  const [uniWarning, setUniWarning] = useState(false);
  const [jobTypeWarning, setJobTypeWarning] = useState(false);
  const [divOptions, setDivOptons] = useState([]);
  const [disOptions, setDisOptions] = useState([]);
  const [initDisOptions, setInitDisOptions] = useState([]);
  const [expOptions, setExpOptions] = useState([]);
  const [workTypeOptions, setWorkTypeOptions] = useState([]);
  const [eduOptions, setEduOptions] = useState([]);
  const [uniOptions, setUniOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const genders = [
    {
      label: "পুরুষ",
      value: "পুরুষ",
    },
    {
      label: "মহিলা",
      value: "মহিলা",
    },
    {
      label: "অন্যান্য",
      value: "অন্যান্য",
    },
  ];

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    const handleUnload = () => {
      window.location.href = "/SignOut";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  useEffect(() => {
    setOpen(true);
    setAccessToken(location.state?.accessToken);
    setUserId(location.state?.userId);

    if (localStorage.getItem("publicTableData")) {
      const publicStringData = localStorage.getItem("publicTableData");
      const publicData = JSON.parse(publicStringData);

      setWorkTypeOptions(
        publicData.job_type
          .filter((a) => a.is_visible)
          .map((a) => {
            return {
              label: a.job_type,
              value: a.id,
            };
          })
      );
      setEduOptions(
        publicData.education
          .filter((a) => a.is_visible_user_profile)
          .map((b) => {
            return {
              label: b.education,
              value: b.id,
              isUniversity: b.is_university_required,
            };
          })
      );
      setExpOptions(
        publicData.work_experience
          .filter((a) => a.is_visible_user_profile)
          .map((b) => {
            return {
              label: b.work_experience,
              value: b.id,
            };
          })
      );
      setDivOptons(
        publicData.division
          .filter((a) => a.is_visible)
          .map((a) => {
            return {
              label: a.division,
              value: a.division_id,
            };
          })
      );
      setUniOptions(
        publicData.university_list
          .filter((item) => {
            return item.is_visible;
          })
          .map((item) => ({
            value: item.id,
            label: item.university_name_bn,
          }))
      );
      setInitDisOptions(
        publicData.district
          .filter((item) => {
            return item.is_visible;
          })
          .map((item) => ({
            value: item.district_id,
            label: item.district,
            division_id: item.division_id,
          }))
      );
    }
  }, []);

  useEffect(() => {
    if (initDisOptions.length > 0 && profileData.division) {
      const dis = initDisOptions.filter((item) => {
        return profileData.division.value === item.division_id;
      });

      setDisOptions(dis);
    }
  }, [profileData.division, initDisOptions]);

  useEffect(() => {
    if (userId && accessToken) {
      getProfileData();
    }
  }, [userId, accessToken]);

  const getProfileData = async () => {
    try {
      const response = await axios.get(
        config.serverEndPoint + "user_profile?user_id=eq." + userId,

        {
          headers: {
            "Content-Type": "application/json",
            apikey: config.apiKey,
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      if (response.status === 200 && response.data.length > 0) {
        setProfileData({
          ...profileData,
			full_name: response.data[0].full_name,
			phone: response.data[0].username,		  
          date_of_birth: moment(response.data[0].date_of_birth),
          profile_photo: response.data[0].profile_photo,
          gender:
            genders.find((item) => {
              return item.value === response.data[0].gender;
            }) || {},
          division:
            divOptions.find((item) => {
              return item.value === response.data[0].division;
            }) || {},
          district:
            initDisOptions.find((item) => {
              return item.value === response.data[0].district;
            }) || {},
          work_experience:
            expOptions.find((item) => {
              return item.value === response.data[0].work_experience;
            }) || {},
          education:
            eduOptions.find((item) => {
              return item.value === response.data[0].education;
            }) || {},
          university_id:
            uniOptions.find((item) => {
              return item.value === response.data[0].university_id;
            }) || {},
          job_type_list:
            workTypeOptions.filter((item) => {
              return response.data[0].job_type_list.includes(item.value);
            }) || {},
        });
      }
      setOpen(false);
    } catch (error) {
      console.error(error);
      setOpen(false);
    }
  };

  // useEffect(() => {
  // 	console.log(profileData);
  // }, [profileData]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0];
      const formData = new FormData();
      formData.append("logo.jpg", img);
      const path = config.storageUrl2 + userId + "/profile/profile_image.jpg";
      profileData.profile_photo && profileData.profile_photo.length > 5
        ? imageUpdate(path, formData)
        : imageUpload(path, formData);
      setProfileData({
        ...profileData,
        profile_photo: URL.createObjectURL(img),
      });
    }
  };

  const imageUpload = async (path, file) => {
    console.log("I am in image upload");
    try {
      const response = await axios.post(path, file, {
        headers: {
          "Content-Type": "multipart/form-data",
          apikey: config.apiKey,
          Authorization: "Bearer " + accessToken,
        },
      });
      if (response.status === 200) {
        // console.log(response.data.Key);

        const dt = {
          profile_photo:
            config.storageUrl3 +
            response.data.Key +
            "?resize=contain&width=400",
        };
        setProfileData({
          ...profileData,
          profile_photo:
            config.storageUrl3 +
            response.data.Key +
            "?resize=contain&width=400",
        });
        updateProfileData(dt, 1);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const imageUpdate = async (path, file) => {
    console.log("I am in image update");
    try {
      const response = await axios.put(path, file, {
        headers: {
          "Content-Type": "multipart/form-data",
          apikey: config.apiKey,
          Authorization: "Bearer " + accessToken,
        },
      });

      if (response.status === 200) {
        const dt = {
          profile_photo:
            config.storageUrl +
            response.data.Key +
            "?resize=contain&width=400",
        };
        setProfileData({
          ...profileData,
          profile_photo:
            config.storageUrl +
            response.data.Key +
            "?resize=contain&width=400",
        });
        updateProfileData(dt, 1);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateInfo = () => {
    let isOk = true;

    if (profileData.full_name === null || profileData.full_name.length <= 2) {
      setNameWarning(true);
      isOk = false;
    } else {
      setNameWarning(false);
    }

    if (profileData.date_of_birth === null) {
      setDobWarning(true);
      isOk = false;
    } else {
      setDobWarning(false);
    }

    if (Object.keys(profileData.gender || {}).length === 0) {
      setGenderWarning(true);

      isOk = false;
    } else {
      setGenderWarning(false);
    }

    if (Object.keys(profileData.division || {}).length === 0) {
      setDivWarning(true);
      isOk = false;
    } else {
      setDivWarning(false);
    }

    if (Object.keys(profileData.district || {}).length === 0) {
      setDisWarning(true);
      isOk = false;
    } else {
      setDisWarning(false);
    }

    if (Object.keys(profileData.work_experience || {}).length === 0) {
      setExpWarning(true);
      isOk = false;
    } else {
      setExpWarning(false);
    }

    if (Object.keys(profileData.education || {}).length === 0) {
      setEduWarning(true);
      isOk = false;
    } else {
      setEduWarning(false);
    }

    if (
      Object.keys(profileData.education || {}).length > 0 &&
      profileData.education.isUniversity &&
      Object.keys(profileData.university_id || {}).length === 0
    ) {
      setUniWarning(true);
      isOk = false;
    } else {
      setUniWarning(false);
    }

    if (profileData.job_type_list.length === 0) {
      setJobTypeWarning(true);
      isOk = false;
    } else {
      setJobTypeWarning(false);
    }

    if (isOk) {
      const dt = {
        full_name: profileData.full_name,
        date_of_birth: dayjs(profileData.date_of_birth, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
		  ),
		
        gender: profileData.gender.label,
        division: profileData.division.value,
        district: profileData.district.value,
        education: profileData.education.value,
        work_experience: profileData.work_experience.value,
        university_id: profileData.education.isUniversity
          ? profileData.university_id.value
          : null,
        job_type_list: profileData.job_type_list.map((item) => item.value),
      };
      updateProfileData(dt, 2);
    } else {
      toast.error("Please fill-up all requerd field.", {
        autoClose: 5000,
      });
    }
	};
	
	useEffect(() => {
    console.log(profileData);
  }, [profileData]);

  const updateProfileData = async (data, mark) => {
    // console.log(data);
    try {
      const response = await axios.patch(
        config.serverEndPoint + "user_profile?user_id=eq." + userId,
        data,

        {
          headers: {
            "Content-Type": "application/json",
            apikey: config.apiKey,
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (response.status === 204) {
        toast.success(
          mark === 1
            ? "Profile image upload successfully."
            : "Profile data update successfully",
          {
            autoClose: 5000,
          }
        );
        if (mark === 2) {
          localStorage.setItem("userId", userId);
          localStorage.setItem("accessToken", accessToken);
          window.location = "/";
        }
      } else {
        console.log(response);
        toast.error("Something went wrong, please try again later", {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <div
        className=" scroll-container container-fluid"
        style={{
          backgroundImage: `url(${background_image})`,
          backgroundSize: "100% auto",
          backgroundRepeat: "repeat-y",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <div className="jd_section">
          <div className="top">
            <PictureInput
              image={
                profileData.profile_photo &&
                profileData.profile_photo.length > 5
                  ? profileData.profile_photo + "?" + Date.now()
                  : defultImage
              }
              onPictureUpload={(fileData) => {
                handleImageChange(fileData);
              }}
            />
          </div>
        </div>

        <div className="jd_section">
          <div className="company_details">
            <div className="row">
              <NormalInput2
                label={"পুরো নাম"}
                isLabel={true}
                isWarning={nameWarning}
                warningText={"নামের ঘরটি পূরণ করতে হবে।"}
                ph={"আপনার নাম লিখুন"}
                id={"user_name"}
                name={"user_name"}
                data={profileData?.full_name || ""}
                type={"text"}
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    full_name: e,
                  });
                  setNameWarning(false);
                }}
              />
              <NormalInput2
                label={"মোবাইল নম্বর"}
                isLabel={true}
                warningText={"মোবাইল নম্বর ঘরটি পূরণ করতে হবে।"}
                ph={"আপনার মোবাইল নম্বর লিখুন"}
                id={"phone_number"}
                name={"phone_number"}
                data={"+" + profileData?.phone || ""}
                type={"text"}
                isDisable={true}
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    phone: {
                      ...profileData?.username,
                      value: e,
                      is_warning: false,
                    },
                  });
                }}
              />
              <ModalSelect
                label={"লিঙ্গ"}
                isLabel={true}
                data={profileData?.gender || {}}
                options={genders}
                ph={"লিঙ্গ বাছাই করুন।"}
                isWarning={genderWarning}
                warningText={"একটি লিঙ্গ বাছাই করতে হবে।"}
                onChange={(gen) => {
                  setProfileData({
                    ...profileData,
                    gender: gen,
                  });
                  setGenderWarning(false);
                }}
                onMenuOpen={() => {}}
              />

              <ModalDateInput
                label={"জন্ম তারিখ (দিন/মাস/বছর)"}
                warningText={"জন্ম তারিখ প্রদান করতে হবে"}
                isWarning={dobWarning}
                ph={"দিন/মাস/বছর"}
                date={moment(profileData.date_of_birth).format("DD/MM/YYYY")}
                onChange={(dt) => {
                  setProfileData({
                    ...profileData,
                    date_of_birth: dt,
                  });
                  setDobWarning(false);
                }}
              />
            </div>
          </div>
        </div>
        <div className="jd_section mb-4">
          <div className="company_details">
            <div className="row">
              <ModalSelect
                label={"বিভাগ"}
                isLabel={true}
                data={profileData.division || {}}
                options={divOptions}
                ph={"বিভাগ বাছাই করুন।"}
                isWarning={divWarning}
                warningText={"একটি বিভাগ বাছাই করতে হবে।"}
                onChange={(selectedOption) => {
                  setProfileData({
                    ...profileData,
                    division: selectedOption,
                  });
                  setDivWarning(false);
                }}
                onMenuOpen={() => {}}
              />
              <ModalSelect
                label={"এলাকা"}
                isLabel={true}
                data={profileData.district || {}}
                options={disOptions}
                ph={"এলাকা বাছাই করুন।"}
                isWarning={disWarning}
                warningText={"একটি এলাকা বাছাই করতে হবে।"}
                isDisable={Object.keys(profileData.division || {}).length === 0}
                onChange={(selectedOptions) => {
                  setProfileData({
                    ...profileData,
                    district: selectedOptions,
                  });
                  setDisWarning(false);
                }}
                onMenuOpen={() => {}}
              />
              <ModalSelect
                label={"কাজের অভিজ্ঞতা"}
                isLabel={true}
                data={profileData.work_experience || {}}
                options={expOptions}
                ph={"কাজের অভিজ্ঞতা বাছাই করুন।"}
                isWarning={expWarning}
                warningText={"একটি কাজের অভিজ্ঞতা বাছাই করতে হবে।"}
                onChange={(selectedOptions) => {
                  setProfileData({
                    ...profileData,
                    work_experience: selectedOptions,
                  });
                  setExpWarning(false);
                }}
                onMenuOpen={() => {}}
              />
              <ModalSelect
                label={"সর্বোচ্চ শিক্ষাগত যোগ্যতা"}
                isLabel={true}
                data={profileData.education || {}}
                options={eduOptions}
                ph={"সর্বোচ্চ শিক্ষাগত যোগ্যতা বাছাই করুন।"}
                isWarning={eduWarning}
                warningText={"একটি সর্বোচ্চ শিক্ষাগত যোগ্যতা বাছাই করতে হবে।"}
                onChange={(selectedOptions) => {
                  setProfileData({
                    ...profileData,
                    education: selectedOptions,
                    university_id: {},
                  });
                  setEduWarning(false);
                }}
                onMenuOpen={() => {}}
              />
              {profileData?.education?.isUniversity && (
                <ModalSelect
                  label={"শিক্ষা প্রতিষ্ঠান"}
                  isLabel={true}
                  data={profileData.university_id || {}}
                  options={uniOptions}
                  isVisible={
                    profileData.education
                      ? profileData.education.isUniversity
                        ? true
                        : false
                      : false
                  }
                  ph={"শিক্ষা প্রতিষ্ঠান বাছাই করুন।"}
                  isWarning={uniWarning}
                  warningText={"শিক্ষা প্রতিষ্ঠান বাছাই করতে হবে।"}
                  onChange={(selectedOptions) => {
                    setProfileData({
                      ...profileData,
                      university_id: selectedOptions,
                    });
                    setUniWarning(false);
                  }}
                  onMenuOpen={() => {}}
                />
              )}

              <ModalSelect
                label={"কাজের ধরন"}
                isLabel={true}
                data={profileData.job_type_list || []}
                options={workTypeOptions}
                ph={"কাজের ধরন বাছাই করুন।"}
                isWarning={jobTypeWarning}
                warningText={"একটি কাজের ধরন বাছাই করতে হবে।"}
                onChange={(selectedOptions) => {
                  if (selectedOptions.length <= 5) {
                    setProfileData({
                      ...profileData,
                      job_type_list: selectedOptions,
                    });
                    setJobTypeWarning(false);
                  } else {
                    toast.error("সর্বাধিক ৫টি দক্ষতা নির্বাচন করতে পারবেন।", {
                      autoClose: 5000,
                    });
                  }
                }}
                multiSelect={true}
                onMenuOpen={() => {}}
              />
            </div>
          </div>
          <hr />

          <div
            className="count_btn my-4 mx-3 d-flex justify-content-center"
            style={{
              display: "flex",
              gap: "0.5rem",
            }}
          >
            <Button
              style={{
                fontSize: "16px",
              }}
              onClick={handleUpdateInfo}
              variant="primary"
            >
              Update Information
            </Button>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default CreateProfile;
