import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { SingleCourseEdit } from "../../Components/User/SingleCourse";

const UserCourseEdit = ({
  data,
  setCourseInfo,
  isVisible = false,
  onHide,
  courseOptions,
  updateCourseData,
}) => {
  const validation = () => {
    let isValid = true;
    const updateData = data.map((item) => {
      let tempItem = { ...item };
      if (!item?.course?.value || item?.course?.value <= 0) {
        isValid = false;
        tempItem.course = { ...tempItem.course, is_warning: true };
      } else {
        tempItem.course = { ...tempItem.course, is_warning: false };
      }

      if (
        item?.course?.value &&
        item?.course?.value === 385 &&
        item?.other_course?.value.length < 2
      ) {
        isValid = false;
        tempItem.other_course = { ...tempItem.other_course, is_warning: true };
      } else {
        tempItem.other_course = { ...tempItem.other_course, is_warning: false };
      }

      if (!item?.organization?.value || item?.organization?.value.length < 2) {
        isValid = false;
        tempItem.organization = { ...tempItem.organization, is_warning: true };
      } else {
        tempItem.organization = { ...tempItem.organization, is_warning: false };
      }

      return tempItem;
    });
    setCourseInfo(updateData);
    return isValid;
  };

  const uploadInfo = () => {
    if (validation()) {
      const dt = data.map((item) => {
        return {
          course_id: item?.course?.value,
          course_name: item?.course?.label,
          organization: item?.organization?.value,
          other_course:
            item?.course?.value === 385 ? item?.other_course?.value : null,
        };
      });

      updateCourseData(dt, data);
    } else {
      toast.error("সকল প্রয়োজনীয় তথ্য পুরুন করুন", {
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="jd_section">
      <div className="company_details profile_section">
        <h1 className="mb-4">আপনার কোর্স</h1>

        {data.map((singleItem, index) => (
          <SingleCourseEdit
            key={index}
            data={singleItem}
            index={index}
            courseOptions={courseOptions}
            onRemoveClick={(removeIndex) => {
              const tt = data.filter((it, i) => i !== removeIndex);
              setCourseInfo(tt);
            }}
            onCourseChange={(selectedOption, index) => {
              const tt = [...data];
              tt[index].course = {
                value: selectedOption.id,
                label: selectedOption.label,
                is_warning: false,
              };
              setCourseInfo(tt);
            }}
            onOtherCourseChange={(text, index) => {
              const tt = [...data];
              tt[index].other_course = {
                value: text,
                is_warning: false,
              };
              setCourseInfo(tt);
            }}
            onOrganizationChange={(text, index) => {
              const tt = [...data];
              tt[index].organization = {
                value: text,
                is_warning: false,
              };
              setCourseInfo(tt);
            }}
          />
        ))}

        <div className="p-3">
          <Button
            variant="light"
            size="sm"
            onClick={() => {
              setCourseInfo([
                ...data,
                {
                  course: {
                    value: 0,
                    label: "",
                    is_warning: false,
                  },
                  other_course: {
                    value: "",
                    is_warning: false,
                  },
                  organization: {
                    value: "",
                    is_warning: false,
                  },
                },
              ]);
            }}
            style={{
              marginTop: "10px",
              color: "#007bff",
              fontSize: "14px",
            }}
          >
            আরও যোগ করুন +
          </Button>
        </div>
      </div>
    </div>
  );
};

export { UserCourseEdit };
