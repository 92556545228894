import { Button, Form, InputGroup } from "react-bootstrap";
import React, { useState } from "react";
import Select from "react-select";
import { addDays } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { LuFilter, LuFilterX } from "react-icons/lu";
import { FiCamera } from "react-icons/fi";
import defultImage from "../../img/picture_upload_background.png";

const NormalInput = ({
  label,
  data,
  onChange,
  isWarning,
  warningText,
  ph,
  id,
  name,
  type,
  isDisable = false,
  isLabel = true,
}) => {
  return (
    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <div className="company_details_wrapper ">
        {isLabel && (
          <p className="font_light">
            {label}
            <span
              style={{
                color: "red",
              }}
            >
              {" "}
              *
            </span>
          </p>
        )}

        <div className="form_wrapper">
          <Form.Control
            type={type}
            name={name}
            id={id}
            disabled={isDisable}
            className="noError"
            placeholder={ph}
            value={data || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
          {isWarning && <small className="text-danger">* {warningText}.</small>}
        </div>
      </div>
    </div>
  );
};

const GroupInput = ({
  label,
  startWith,
  data,
  onChange,
  isWarning,
  warningText,
  ph,
  id,
  name,
  type,
  isDisable = false,
}) => {
  const [value, setValue] = useState(data || "");
  return (
    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <div className="company_details_wrapper ">
        <p className="font_light">
          {label}
          <span
            style={{
              color: "red",
            }}
          >
            {" "}
            *
          </span>
        </p>

        <div className="form_wrapper">
          <InputGroup>
            <InputGroup.Text id="basic-addon1">{startWith}</InputGroup.Text>
            <Form.Control
              type={type}
              name={name}
              id={id}
              disabled={isDisable}
              className="noError"
              placeholder={ph}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e);
              }}
            />
          </InputGroup>

          {isWarning && <small className="text-danger">* {warningText}.</small>}
        </div>
      </div>
    </div>
  );
};

const SelectInput = ({
  label,
  options,
  data,
  onChange,
  isWarning,
  warningText,
  ph,
  id,
  name,
  multiSelect = false,
  isDisable = false,
  isVisible = true,
}) => {
  return (
    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12">
      {isVisible ? (
        <div className="company_details_wrapper ">
          <p className="font_light">
            {label}
            <span
              style={{
                color: "red",
              }}
            >
              {" "}
              *
            </span>
          </p>

          <div className="form_wrapper">
            <Select
              value={
                multiSelect ? data : Object.keys(data).length > 0 ? data : []
              }
              id={id}
              name={name}
              isMulti={multiSelect}
              isDisabled={isDisable}
              onChange={(selectedOptions) => {
                onChange(selectedOptions);
              }}
              options={options}
              placeholder={ph}
            />

            {isWarning && (
              <small className="text-danger mt-1">* {warningText}.</small>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const DateInput = ({
  label,
  date,
  onChange,
  isWarning,
  warningText,
  id,
  name,
  isDisable = false,
}) => {
  const currentDate = new Date();
  const maxDate = addDays(currentDate, -1 * 365 * 15);

  return (
    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12">
      <div className="company_details_wrapper ">
        <p className="font_light">
          {label}
          <span
            style={{
              color: "red",
            }}
          >
            {" "}
            *
          </span>
        </p>

        <div className="sign_form_wrapper ">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="form-control"
              value={dayjs(date, "DD/MM/YYYY")}
              id={id}
              name={name}
              slotProps={{
                textField: {
                  size: "small",
                },
              }}
              maxDate={dayjs(maxDate)}
              isDisable={isDisable}
              format="DD/MM/YYYY"
              onChange={(dt) => {
                const formattedDate = dt.format("DD/MM/YYYY");
                onChange(formattedDate);
              }}
            />
          </LocalizationProvider>
        </div>
        {isWarning && <small className="text-danger">* {warningText}.</small>}
      </div>
    </div>
  );
};

const SearchInput = ({
  data,
  onSearchTextChange,
  ph,
  id,
  name,
  isFilterActive = false,
  type,
  onFilterClick,
}) => {
  return (
    <div className="col-xxl-9 col-xl-8 col-lg-6 col-md-6 col-sm-12 px-3 mb-3">
      <InputGroup>
        <InputGroup.Text id="basic-addon1" style={{ background: "#FFF" }}>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="mx-2 input_field_icon"
          />
        </InputGroup.Text>
        <Form.Control
          type={type}
          name={name}
          id={id}
          className="noError"
          placeholder={ph}
          value={data || ""}
          onChange={(e) => {
            onSearchTextChange(e.target.value);
          }}
        />
        <InputGroup.Text
          id="basic-addon1"
          style={{ background: "#FFF", cursor: "pointer" }}
          onClick={() => {
            onFilterClick();
          }}
        >
          {isFilterActive ? (
            <LuFilterX className="mx-2 input_field_icon" />
          ) : (
            <LuFilter className="mx-2 input_field_icon" />
          )}
        </InputGroup.Text>
      </InputGroup>
    </div>
  );
};

const SearchSelect = ({
  isLabel = true,
  label,
  options,
  data,
  onChange,
  ph,
  id,
  name,
  multiSelect = false,
  isDisable = false,
  isVisible = true,
  isWarning = false,
  warningText,
  onMenuOpen,
}) => {
  return (
    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-3">
      {isVisible ? (
        <div>
          {isLabel && (
            <p className="input_field_p">
              {label}
              <span
                style={{
                  color: "red",
                }}
              >
                {" "}
                *
              </span>
            </p>
          )}

          <Select
            className="job_filter2"
            styles={{ control: (base) => ({ ...base, minHeight: "47px" }) }}
            value={
              multiSelect ? data : Object.keys(data).length > 0 ? data : []
            }
            id={id}
            name={name}
            isMulti={multiSelect}
            isDisabled={isDisable}
            onChange={(selectedOptions) => {
              onChange(selectedOptions);
            }}
            onMenuOpen={() => {
              onMenuOpen();
            }}
            options={options}
            placeholder={ph}
          />
          {isWarning && <small className="text-danger">* {warningText}</small>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const NormalInput2 = ({
  label,
  data,
  onChange,
  isWarning,
  warningText,
  ph,
  id,
  rowNo = 1,
  isMandatory = true,
  name,
  type,
  isDisable = false,
  isLabel = true,
}) => {
  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
      {isLabel && (
        <p className="input_field_p">
          {label}
          {isMandatory && (
            <span
              style={{
                color: "red",
              }}
            >
              {" "}
              *
            </span>
          )}
        </p>
      )}

      <div className="form_wrapper job_filter">
        <Form.Control
          type={type}
          name={name}
          id={id}
          disabled={isDisable}
          className="noError"
          placeholder={ph}
          value={data || ""}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        {isWarning && <small className="text-danger">* {warningText}</small>}
      </div>
    </div>
  );
};

const GroupInput2 = ({
  label,
  data,
  onChange,
  isWarning,
  warningText,
  ph,
  id,
  startWith,
  rowNo = 1,
  isMandatory = true,
  name,
  type,
  isDisable = false,
  isLabel = true,
  sizes = {
    xxl: 4,
    xl: 4,
    lg: 6,
    md: 6,
    sm: 12,
  },
}) => {
  return (
    <div
      className={`col-xxl-${sizes.xxl} col-xl-${sizes.xl} col-lg-${sizes.lg} col-md-${sizes.md} col-sm-${sizes.sm}`}
    >
      {isLabel && (
        <p className="input_field_p">
          {label}
          {isMandatory && (
            <span
              style={{
                color: "red",
              }}
            >
              {" "}
              *
            </span>
          )}
        </p>
      )}

      <div className="form_wrapper job_filter">
        <InputGroup>
          <InputGroup.Text id="basic-addon1">{startWith}</InputGroup.Text>
          <Form.Control
            type={type}
            name={name}
            id={id}
            disabled={isDisable}
            className="noError"
            placeholder={ph}
            value={data || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </InputGroup>
        {isWarning && <small className="text-danger">* {warningText}</small>}
      </div>
    </div>
  );
};


const NormalInput3 = ({
  label,
  data,
  onChange,
  isWarning,
  warningText,
  ph,
  id,
  rowNo = 1,
  name,
  type,
  isDisable = false,
  isLabel = true,
}) => {
  return (
    <div className="col-12 mb-3">
      {isLabel && (
        <p className="input_field_p">
          {label}
          <span
            style={{
              color: "red",
            }}
          >
            {" "}
            *
          </span>
        </p>
      )}

      <div className="form_wrapper job_filter">
        <Form.Control
          type={type}
          as={"textarea"}
          name={name}
          id={id}
          disabled={isDisable}
          className="noError"
          placeholder={ph}
          value={data || ""}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        {isWarning && <small className="text-danger">* {warningText}</small>}
      </div>
    </div>
  );
};

const PictureInput = ({ image, onPictureUpload }) => {
  return (
    <div>
      <div className="company_details">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center mb-3">
            <div className="company_logo">
              <img
                src={image ? image : defultImage}
                className="rounded-3"
                style={{
                  maxWidth: "200px",
                  maxHeight: "200px",
                  minWidth: "150px",
                  minHeight: "150px",

                  objectFit: "contain",
                }}
                alt="shomvob_jobs"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="count_btn my-3 mx-3 d-flex justify-content-center"
        style={{
          display: "flex",
          gap: "0.5rem",
        }}
      >
        <Form.Group>
          <Button
            as="label"
            htmlFor="fileInput"
            variant="outline-secondary"
            className="d-flex justify-content-center align-items-center outline_btn"
          >
            <span className="me-2">
              <FiCamera />
            </span>{" "}
            ছবি আপলোড করুন
            <Form.Control
              id="fileInput"
              type="file"
              accept="image/png, image/jpeg"
              onChange={(event) => {
                onPictureUpload(event.target.files);
              }}
              style={{
                display: "none",
              }}
            />
          </Button>
        </Form.Group>
      </div>
    </div>
  );
};


const ModalSelect2 = ({
  isLabel = true,
  label,
  options,
  data,
  onChange,
  ph,
  id,
  name,
  multiSelect = false,
  isDisable = false,
  isVisible = true,
  isWarning = false,
  warningText,
  onMenuOpen,
}) => {
  return (
    <div className="col-12 mb-3">
      {isVisible ? (
        <div>
          {isLabel && (
            <p className="input_field_p">
              {label}
              <span
                style={{
                  color: "red",
                }}
              >
                {" "}
                *
              </span>
            </p>
          )}

          <Select
            className="job_filter2"
            styles={{ control: (base) => ({ ...base, minHeight: "47px" }) }}
            value={
              multiSelect ? data : Object.keys(data).length > 0 ? data : []
            }
            id={id}
            name={name}
            isMulti={multiSelect}
            isDisabled={isDisable}
            onChange={(selectedOptions) => {
              onChange(selectedOptions);
            }}
            onMenuOpen={() => {
              onMenuOpen();
            }}
            options={options}
            placeholder={ph}
          />
          {isWarning && <small className="text-danger">* {warningText}</small>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};


const ModalSelect = ({
  isLabel = true,
  label,
  options,
  data,
  onChange,
  ph,
  id,
  name,
  multiSelect = false,
  isDisable = false,
  isVisible = true,
  isWarning = false,
  warningText,
  onMenuOpen,
}) => {
  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
      {isVisible ? (
        <div>
          {isLabel && (
            <p className="input_field_p">
              {label}
              <span
                style={{
                  color: "red",
                }}
              >
                {" "}
                *
              </span>
            </p>
          )}

          <Select
            className="job_filter2"
            styles={{ control: (base) => ({ ...base, minHeight: "47px" }) }}
            value={
              multiSelect ? data : Object.keys(data).length > 0 ? data : []
            }
            id={id}
            name={name}
            isMulti={multiSelect}
            isDisabled={isDisable}
            onChange={(selectedOptions) => {
              onChange(selectedOptions);
            }}
            onMenuOpen={() => {
              onMenuOpen();
            }}
            options={options}
            placeholder={ph}
          />
          {isWarning && <small className="text-danger">* {warningText}</small>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const ModalCheckButton = ({ isChecked = false, onCheckChange, text }) => {
  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3 d-flex align-items-end"></div>
  );
};

const ModalDateInput = ({
  isLabel = true,
  label,
  date,
  onChange,
  isWarning,
  warningText,
  id,
  name,
  isDisable = false,
  isVisible = true,
}) => {
  const currentDate = new Date();
  const maxDate = addDays(currentDate, -1 * 365 * 18);

  console.log(date);

  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
      {isVisible && (
        <div>
          {isLabel && (
            <p className="input_field_p">
              {label}
              <span
                style={{
                  color: "red",
                }}
              >
                {" "}
                *
              </span>
            </p>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="form-control job_filter2"
              value={dayjs(date, "DD/MM/YYYY")}
              id={id}
              name={name} 
              slotProps={{
                textField: {
                  size: "medium",
                }, 
              }}
              maxDate={dayjs(maxDate)}
              isDisable={isDisable}
              format="DD/MM/YYYY"
              onChange={(dt) => {
                const formattedDate = dt.format("DD/MM/YYYY");
                console.log(formattedDate);
                onChange(formattedDate);
              }}
            />
          </LocalizationProvider>

          {isWarning && <small className="text-danger">* {warningText}.</small>}
        </div>
      )}
    </div>
  );
};

const ModalDateInput2 = ({
  isLabel = true,
  label,
  date,
  onChange,
  isWarning,
  warningText,
  id,
  name,
  isMandatory = true,
  isDisable = false,
  isVisible = true,
  isChecked = false,
  onCheckChange,
  isCheckBox = true,
  text,
}) => {
  // const currentDate = new Date();
  // const maxDate = addDays(currentDate, -1 * 365 * 15);

  // console.log(date);

  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
      {isVisible && (
        <div>
          {isLabel && (
            <p className="input_field_p">
              {label}
              {isMandatory && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {" "}
                  *
                </span>
              )}
            </p>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="form-control job_filter2"
              value={dayjs(date, "DD/MM/YYYY")}
              id={id}
              name={name}
              slotProps={{
                textField: {
                  size: "medium",
                },
              }}
              // maxDate={dayjs(currentDate)}
              disabled={isDisable}
              format="DD/MM/YYYY"
              onChange={(dt) => {
                const formattedDate = dt.format("DD/MM/YYYY");
                console.log(formattedDate);
                onChange(formattedDate);
              }}
            />
          </LocalizationProvider>
          {isCheckBox && (
            <div>
              <Form.Check
                type="checkbox"
                className="input_field_p my-3"
                label={text}
                checked={isChecked}
                onChange={(event) => {
                  onCheckChange(event.target.checked);
                }}
                style={{
                  fontSize: "16px",
                }}
              />
            </div>
          )}

          {isWarning && <small className="text-danger">* {warningText}.</small>}
        </div>
      )}
    </div>
  );
};

export {
  NormalInput,
  GroupInput,
  SelectInput,
  DateInput,
  SearchInput,
  SearchSelect,
  NormalInput2,
  PictureInput,
  ModalSelect,
  ModalDateInput,
  ModalCheckButton,
  ModalDateInput2,
  NormalInput3,
  ModalSelect2,
  GroupInput2,

};
