import React from "react";
import { etob } from "../../NumberConvert";
import { JdBasicInfoItem } from "../JobPosts/JobDescription";
import { Button } from "react-bootstrap";
import { NormalInput2 } from "../Public/InputField";

const SingleReference = ({ data, index }) => {
  return (
    <div className="p-3">
      <h2>রেফারেন্স - {etob(index)}</h2>

      <div className="row">
        <JdBasicInfoItem title={"নাম"} value={data?.name?.value} />
        <JdBasicInfoItem title={"পদবী"} value={data?.designation?.value} />
        <JdBasicInfoItem
          title={"প্রতিষ্ঠানের নাম"}
          value={data?.company?.value}
        />
        <JdBasicInfoItem title={"ফোন নম্বর"} value={data?.phone?.value} />
        <JdBasicInfoItem title={"ই-মেইল"} value={data?.email?.value} />
      </div>
    </div>
  );
};

const SingleReferenceEdit = ({
  data,
  index,
  onNameChange,
  onDesignationChange,
  onCompanyChange,
  onPhoneChange,
  onEmailChange,
  onRemoveClick,
}) => {
  return (
    <div className="p-3">
      <div className="profile_section d-flex justify-content-between align-items-center mb-3">
        <h2>রেফারেন্স - {etob(index + 1)}</h2>
        <Button
          variant="light"
          size="sm"
          onClick={() => {
            onRemoveClick(index);
          }}
          style={{
            color: "#B50D0D",
          }}
        >
          রিমুভ করুন -
        </Button>
        
      </div>

      <div className="row">
        <NormalInput2
          label={"নাম"}
          isLabel={true}
          isWarning={data?.name?.is_warning}
          warningText={"রেফারেন্সের নাম পূরণ করতে হবে।"}
          ph={"রেফারেন্সের নাম লিখুন"}
          id={"institute_name"}
          name={"institute_name"}
          data={data?.name?.value || ""}
          type={"text"}
          onChange={(e) => {
            onNameChange(e, index);
          }}
        />
        <NormalInput2
          label={"পদবী"}
          isLabel={true}
          isWarning={data?.designation?.is_warning}
          warningText={"রেফারেন্সের পদবী পূরণ করতে হবে।"}
          ph={"রেফারেন্সের পদবী লিখুন"}
          id={"reference_designation"}
          name={"reference_designation"}
          data={data?.designation?.value || ""}
          type={"text"}
          onChange={(e) => {
            onDesignationChange(e, index);
          }}
        />
        <NormalInput2
          label={"প্রতিষ্ঠানের নাম"}
          isLabel={true}
          isWarning={data?.name?.is_warning}
          warningText={"রেফারেন্সের প্রতিষ্ঠানের নাম পূরণ করতে হবে।"}
          ph={"রেফারেন্সের প্রতিষ্ঠানের নাম লিখুন"}
          id={"reference_institute_name"}
          name={"reference_institute_name"}
          data={data?.company?.value || ""}
          type={"text"}
          onChange={(e) => {
            onCompanyChange(e, index);
          }}
        />
        <NormalInput2
          label={"ফোন নম্বর"}
          isLabel={true}
          isWarning={data?.phone?.is_warning}
          warningText={"রেফারেন্সের সঠিক ফোন নম্বর পূরণ করতে হবে।"}
          ph={"রেফারেন্সের ফোন নম্বর লিখুন"}
          id={"reference_phone"}
          name={"reference_phone"}
          data={data?.phone?.value || ""}
          type={"text"}
          onChange={(e) => {
            onPhoneChange(e, index);
          }}
        />
        <NormalInput2
          label={"ই-মেইল"}
          isLabel={true}
          isWarning={data?.email?.is_warning}
          warningText={"রেফারেন্সের ই-মেইল পূরণ করতে হবে।"}
          ph={"রেফারেন্সের ই-মেইল লিখুন"}
          id={"reference_email"}
          name={"reference_email"}
          data={data?.email?.value || ""}
          type={"email"}
          onChange={(e) => {
            onEmailChange(e, index);
          }}
        />
      </div>
    </div>
  );
};

export { SingleReference, SingleReferenceEdit };
