import React from "react";
import { etob } from "../../NumberConvert";
import { JdBasicInfoItem } from "../JobPosts/JobDescription";
import moment from "moment";
import { Button } from "react-bootstrap";
import {
  ModalDateInput2,
  ModalSelect,
  NormalInput2,
  NormalInput3,
} from "../Public/InputField";
import dayjs from "dayjs";

const SingleExperience = ({ data, index }) => {
  return (
    <div className="p-3">
      <h2>পূর্ববর্তী অভিজ্ঞতা - {etob(index)}</h2>

      <div className="row">
        <JdBasicInfoItem
          title={"পদের নাম"}
          value={data?.experience_name?.value}
        />
        <JdBasicInfoItem
          title={"প্রতিষ্ঠানের নাম"}
          value={data?.company_name?.value}
        />
        <JdBasicInfoItem
          title={"চাকরির সময়কাল"}
          value={
            dayjs(data?.start_date?.value, "DD/MM/YYYY").format("MMMM, YYYY") +
            " - " +
            (data.is_ongoing
              ? "Ongoing"
              : dayjs(data?.end_date?.value, "DD/MM/YYYY").format("MMMM, YYYY"))
          }
        />

        <JdBasicInfoItem
          title={"কাজের বিবরণ"}
          value={data?.job_responsibilities?.value}
        />
      </div>
    </div>
  );
};

const SingleExperienceEdit = ({
  data,
  index,
  on,
  onExperienceNameChange,
  onCompanyNameChange,
  onStartDateChange,
  onEndDateChange,
  onJobTypeChange,
  onCheckChange,
  onJobResponsibilities,
  jobTypeOptions,
  onRemoveClick,
}) => {
  return (
    <div className="p-3">
      <div className="profile_section d-flex justify-content-between align-items-center mb-3">
        <h2>পূর্ববর্তী অভিজ্ঞতা - {etob(index + 1)}</h2>

        <Button
          variant="light"
          size="sm"
          onClick={() => {
            onRemoveClick(index);
          }}
          style={{
            color: "#B50D0D",
          }}
        >
          রিমুভ করুন -
        </Button>
      </div>

      <div className="row">
        <NormalInput2
          label={"পদের নাম"}
          isLabel={true}
          isWarning={data?.experience_name?.is_warning}
          warningText={"পদের নাম পূরণ করতে হবে।"}
          ph={"পদের নাম লিখুন"}
          id={"experience_name"}
          name={"experience_name"}
          data={data?.experience_name?.value || ""}
          type={"text"}
          onChange={(e) => {
            onExperienceNameChange(e, index);
          }}
        />
        <NormalInput2
          label={"প্রতিষ্ঠানের নাম"}
          isLabel={true}
          isWarning={data?.company_name?.is_warning}
          warningText={"প্রতিষ্ঠানের নাম পূরণ করতে হবে।"}
          ph={"প্রতিষ্ঠানের নাম লিখুন"}
          id={"experience_name"}
          name={"experience_name"}
          data={data?.company_name?.value || ""}
          type={"text"}
          onChange={(e) => {
            onCompanyNameChange(e, index);
          }}
        />
        <ModalDateInput2
          label={"যোগদান তারিখ"}
          warningText={"যোগদান তারিখ প্রদান করতে হবে"}
          isWarning={data?.start_date?.is_warning}
          ph={"দিন/মাস/বছর"}
          date={data?.start_date?.value}
          onChange={(dt) => {
            console.log(dt);
            onStartDateChange(dt, index);
          }}
          text={"আমি এখনো এখানে চাকরি করছি"}
          isChecked={data?.is_ongoing}
          onCheckChange={(status) => {
            console.log(status);
            onCheckChange(status, index);
          }}
        />

        {!data?.is_ongoing && (
          <ModalDateInput2
            label={"ছাড়ার তারিখ বছর"}
            warningText={"ছাড়ার তারিখ প্রদান করতে হবে"}
            isWarning={data?.end_date?.is_warning}
            ph={"দিন/মাস/বছর"}
            isDisable={data?.is_ongoing}
            date={data?.end_date?.value}
            onChange={(dt) => {
              onEndDateChange(dt, index);
            }}
            isChecked={data?.is_ongoing}
            isCheckBox={false}
            onCheckChange={(status) => {
              onCheckChange(status, index);
            }}
          />
        )}

        <ModalSelect
          label={"কাজের ধরন"}
          isLabel={true}
          data={data?.job_type || {}}
          options={jobTypeOptions}
          ph={"কাজের ধরন বাছাই করুন"}
          isWarning={data?.course?.is_warning}
          warningText={"একটি কাজের ধরন বাছাই করতে হবে।"}
          onChange={(selectedOption) => {
            onJobTypeChange(selectedOption, index);
          }}
          onMenuOpen={() => {}}
        />

        <NormalInput3
          label={"কাজের বিবরণ"}
          isLabel={true}
          isWarning={data?.job_responsibilities?.is_warning}
          warningText={"কাজের বিবরণ প্রদান করতে হবে।"}
          ph={"কাজের বিবরণ লিখুন"}
          id={"job_responsibilities"}
          name={"job_responsibilities"}
          data={data?.job_responsibilities?.value || ""}
          type={"textarea"}
          onChange={(e) => {
            onJobResponsibilities(e, index);
          }}
        />
      </div>
    </div>
  );
};

export { SingleExperience, SingleExperienceEdit };
