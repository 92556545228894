import "./App.css";
import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Trainings from "./Pages/YoutubVideos";
import YoutubVideos from "./Pages/YoutubVideos";
import SuccessStorys from "./Pages/SuccessStorys";
import SavedJobs from "./Pages/SavedJobs";
import AppliedJobs from "./Pages/AppliedJobs";
import JobApplication1 from "./Pages/JobApplication1";
import ApplicationTracker from "./Pages/ApplicationTracker";
import AllCompany from "./Pages/Company/AllCompany";
import SingleCompanyProfile from "./Pages/Company/SingleCompanyDescription";
import AllGovtJobs from "./Pages/GovtJob/AllGovtJobs";
import SingleGovtJob from "./Pages/GovtJob/SingleGovtJob";
import Home from "./Pages/Home";
import NotFound from "./NotFound";
import JobDescription from "./Pages/JobDescription";
import SignUp from "./Pages/User/SignUp";
import SignOut from "./SignOut";
import Protected from "./Protected";
import NewUserInformation from "./NewUserInformation";
import SupabaseTest from "./SupabaseTest";
import CVMaker from "./Pages/User/CVMaker";
import Redirect from "./Redirect";
import CreateProfile from "./Pages/User/CreateProfile";
import Profile from "./Pages/User/Profile";
import Lms from "./Lms";
import axios from "axios";
import config from "./config.json";
import Protected2 from "./Protected2";

// import FacebookMessenger from "./Components/Messenger";
import { getPublicData } from "./ApiCalls/PublicData";
import { getUserProfileData } from "./ApiCalls/User";
import { SideBar2 } from "./Components/SideBar";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
    };
  }

  async componentDidMount() {
    const userId = localStorage.getItem("userId");
    if (userId) {
      this.setState({ userId });
    }

    if (!localStorage.getItem("publicTableData")) {
      const pbData = await getPublicData();
      if (pbData?.status === 200) {
        localStorage.setItem("publicTableData", JSON.stringify(pbData.data[0]));
      }
    }

    const currentTime = Date.now();

    // console.log(Date.now());

    if (
      localStorage.getItem("refreshToken") &&
      currentTime > localStorage.getItem("expireTime")
    ) {
      new axios({
        method: "post",
        url: config.supabseAuthEndPoint + "token?grant_type=refresh_token",
        data: {
          refresh_token: localStorage.getItem("refreshToken"),
        },
        headers: {
          apikey: config.apiKey,
        },
      })
        .then((response) => {
          localStorage.setItem("accessToken", response.data.access_token);
          localStorage.setItem("refreshToken", response.data.refresh_token);
          const currentTime = Date.now();
          localStorage.setItem(
            "expireTime",
            currentTime + response.data.expires_in * 1000
          );
        })
        .catch((error) => {
          console.error(error);
          localStorage.removeItem("userId");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expireTime");
          window.location = "/SignIn";
        });
    }

    if (localStorage.getItem("userId")) {
      try {
        const response = await getUserProfileData();
        if (Array.isArray(response.data) && response.data.length > 0) {
          localStorage.setItem("user_phone", "+" + response.data[0].username);
          localStorage.setItem("profile_photo", response.data[0].profile_photo);
          localStorage.setItem("user_name", response.data[0].full_name);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    return (
      <div className="App" style={{ overflowY: "hidden" }}>
        <Header userId={this.state.userId} />
        <div
          style={{
            display: "flex",
            height: "100vh",
            overflowY: "hidden",
            overflowX: "auto",
          }}
        >
          <SideBar2 />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Redirect" element={<Redirect />} />
            <Route path="/CreateProfile" element={<CreateProfile />} />
            <Route path="/job_list" element={<Home />} />
            <Route path="/Trainings" element={<Trainings />} />
            <Route path="/all_trainings" element={<Trainings />} />
            <Route path="/youtube_videos" element={<YoutubVideos />} />
            <Route path="/SuccessStorys" element={<SuccessStorys />} />
            <Route path="/success_stories_list" element={<SuccessStorys />} />
            <Route path="/govtjobs" element={<AllGovtJobs />} />
            
            <Route
              path="/govtjobs/single_govt_job"
              element={<SingleGovtJob />}
            />

            <Route
              path="/application_tracker"
              element={
                <Protected isLoggedIn={localStorage.getItem("userId")}>
                  <AppliedJobs />
                </Protected>
              }
            />
            <Route
              path="/single_application_tracker"
              element={
                <Protected isLoggedIn={localStorage.getItem("userId")}>
                  <ApplicationTracker />
                </Protected>
              }
            />
            <Route
              path="/single_job_description"
              element={<JobDescription />}
            />
            <Route
              path="/single_company_description"
              element={<SingleCompanyProfile />}
            />
            <Route
              path="/SignUp"
              element={
                <Protected2 isLoggedIn={localStorage.getItem("userId")}>
                  <SignUp />
                </Protected2>
              }
            />
            <Route path="/Lms/:id" element={<Lms />} />
            <Route path="/SignOut" element={<SignOut />} />
            <Route path="/SupabaseTest" element={<SupabaseTest />} />
            <Route
              path="/CVMaker"
              element={
                <Protected isLoggedIn={localStorage.getItem("userId")}>
                  <CVMaker />
                </Protected>
              }
            />
            <Route
              path="/saved_jobs_list"
              element={
                <Protected isLoggedIn={localStorage.getItem("userId")}>
                  <SavedJobs />
                </Protected>
              }
            />
            <Route
              path="/Profile"
              element={
                <Protected isLoggedIn={localStorage.getItem("userId")}>
                  <Profile />
                </Protected>
              }
            />
            <Route
              path="/JobApplication1"
              element={
                <Protected isLoggedIn={localStorage.getItem("userId")}>
                  <JobApplication1 />
                </Protected>
              }
            />
            <Route path="/company_profiles" element={<AllCompany />} />

            <Route
              path="/NewUserInformation"
              element={<NewUserInformation />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>

        {/* <FacebookMessenger /> */}
      </div>
    );
  }
}

export default App;
