import React from "react";
import { Link, Navigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import app from "../firebaseConfig";
import { getAnalytics, logEvent } from "firebase/analytics";
import { SuccessfullyGetJobCard } from "./SuccessStoryCard";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AppBannerSlider = ({ data, slidesToShow, slidesToScroll }) => {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
  };

  return (
    <div>
      <Slider {...settings}>
        {data.map((item) => (
          <img
            key={item.id}
            className="p-3 rounded-5"
            src={item.image_link}
            alt={item.title}
            loading="lazy"
            onClick={() => {
              item.link && window.open(item.link, "_blank");
            }}
          />
        ))}
      </Slider>
    </div>
  );
};

const AppBannerCarousel2 = ({ data, slidesToShow, slidesToScroll }) => {
  const settings = {
    dots: true,
    // infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    initialSlide: 0,
    
  };

  return (
  
      <Slider {...settings}>
        {data.map((item) => (
          <SuccessfullyGetJobCard story={item} />
        ))}
      </Slider>
    
  );
};

const AppBannerCarousel = ({ data, mark }) => {
  const analytics = getAnalytics(app);
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const items =
    mark === 1
      ? data.map((appBanner) => (
          <div
            className="col-sm-12 col-md-12 col-lg-12 rounded-3"
            style={{
              maxHeight: "250px",
              pading: "20px",
            }}
            name={appBanner.title}
          >
            <Link
              to={appBanner.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                logEvent(analytics, "app_banner_clicked", {
                  FROM: "Home",
                  platfrom: "web",
                  USER_ID: localStorage.getItem("userId"),
                  BANNER_ID: appBanner.id,
                });
              }}
            >
              <img
                src={appBanner.image_link}
                alt={appBanner.title}
                className="rounded-5"
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "250px",
                  maxWidth: "100%",
                  padding: "20px",
                }}
              />
            </Link>
          </div>
        ))
      : data.map((item) => <SuccessfullyGetJobCard story={item} />);

  return (
    <AliceCarousel
      mouseTracking
      infinite={true}
      items={items}
      responsive={responsive}
      stagePadding={"19px"}
      autoPlayInterval={3000}
      autoPlay={true}
      keyboardNavigation={true}
      disableButtonsControls={true}
    />
  );
};

export default AppBannerCarousel;
export { AppBannerSlider, AppBannerCarousel2 };
